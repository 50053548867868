import React, { Component } from "react";
import Typography from "../../../common/TypographyWrapper";
import { getValue } from "../../../../api/utils";
import { round, getDescription } from "../StaticData";

import Callout from "../Callout";
import { withTableStyles, TH, TD, Paragraph } from "../Table";
import Label from "../../../common/Label";

const PATHWAYS = {
    surfaceP: "Surface Phosphorus",
    subsurfaceP: "Subsurface Phosphorus",
    surfaceN: "Surface Nitrogen",
    subsurfaceN: "Subsurface Nitrogen"
};

function titleCase(value) {
    if (!value) {
        return "Unknown";
    }
    return value[0].toUpperCase() + value.slice(1).toLowerCase();
}

class PathwayRow extends Component {
    render() {
        const { pathway, wqsrCategory, fieldSensitivityScore, riskMitigationScore, ratio, ...rest } = this.props,
            mitigation = ratio >= 1 ? "Mitigated" : "Improve",
            mitigationColor = ratio >= 1 ? "darkgreen" : "orange";

        return (
            <tr>
                <TD first {...rest}>
                    {PATHWAYS[pathway]}
                </TD>
                <TD {...rest}>{titleCase(wqsrCategory)}</TD>
                <TD {...rest}>
                    {round(ratio, "waterQuality")}
                    <br />
                    <span style={{ opacity: 0.7 }}>
                        ({riskMitigationScore} / {fieldSensitivityScore})
                    </span>
                </TD>
                <TD {...rest}>
                    <Label color={mitigationColor} size={rest.printable ? 12 : 16}>
                        {mitigation}
                    </Label>
                </TD>
            </tr>
        );
    }
}

class WaterQuality extends Component {
    render() {
        const { indicator, classes, printable } = this.props,
            P = printable ? Paragraph : Typography,
            indexName = getValue(indicator, "fieldprintResult.index");

        const getPathway = pathway => {
            return getValue(indicator, `lossPathway.${pathway}`);
        };

        if (indexName !== "STEP") {
            return (
                <Typography>
                    Error generating table: Expected to find STEP results, but found {indexName || "no"} results
                    instead. You may need to recompute the metrics.
                </Typography>
            );
        }

        return (
            <Callout
                title={printable ? "" : "Breakdown of Water Quality Score Components"}
                split={printable ? 12 : 9}
                printable={printable}
                variant="triangle2">
                <table className={classes.dataTable}>
                    <thead>
                        <tr>
                            <TH printable={printable}>Loss Pathway</TH>
                            <TH printable={printable}>Field Sensitivity Category</TH>
                            <TH printable={printable}>
                                Pathway Ratio
                                <br />
                                <span style={{ opacity: 0.7, whiteSpace: "nowrap" }}>(RMS / FSS)</span>
                            </TH>
                            <TH printable={printable}>Pathway Mitigation</TH>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(PATHWAYS).map(pathway => (
                            <PathwayRow
                                key={pathway}
                                pathway={pathway}
                                printable={printable}
                                {...getPathway(pathway)}
                            />
                        ))}
                    </tbody>
                </table>
                <P>{getDescription(printable ? "waterQualityPartsPrint" : "waterQualityParts")}</P>
            </Callout>
        );
    }
}

export default withTableStyles()(WaterQuality);
