// Global Yes/No radio buttons
export const YES_NO_OPTIONS = [
    { label: "Yes", value: "true" },
    { label: "No", value: "false" }
];

// Crops
export const ALFALFA = "8";
export const BEANS = "200";
export const COTTON = "4";
export const RICE = "39";
export const SUGAR_BEETS = "51";

// Cover Grop Types
export const LEGUME_COVER_CROP = "4";

// Energy Sources
export const ELECTRICITY = ["1", "2", "3"];
export const FUEL = "4";

// Water Sources
export const GROUNDWATER = ["1", "4"];
export const SURFACEWATER = ["2", "3", "4"];

// Manure Type
export const GAL = ["1", "2"];
export const LBS = ["3", "4"];

// Drying Systems
export const NO_DRYING = [undefined, "9", "10"];
export const NO_DRYING_ALFALFA = [undefined, "1"];

// Fertilizers
export const CUSTOM_BLEND = "35";
export const CUSTOM_RATE = "36";

// Organic Fertilizer Type
export const FARM_YARD_MANURE = "1";

// Rice Yield Method
export const WET_GREEN = "2";

// HARDCODED VALIDATION
export const BARLEY = "48";
export const CORN_GRAIN = "1";
export const CORN_SILAGE = "46";
export const PEANUTS = "6";
export const POTATOES = "41";
export const SORGHUM = "5";
export const SOYBEANS = "2";
export const WHEAT_DURUM = "10";
export const WHEAT_SPRING = "9";
export const WHEAT_WINTER = "3";

export const NOT_DRIED = [CORN_SILAGE, POTATOES, SUGAR_BEETS],
    CUSTOM_DRIED = [ALFALFA, PEANUTS, COTTON],
    NOT_DRIED_OR_CUSTOM_DRIED = NOT_DRIED.concat(CUSTOM_DRIED);

// Convert API name/id into react label/value
export function MAKE_OPTIONS(table) {
    return table.map(row => ({
        label: row.name,
        value: row.id
    }));
}

// These are different than the ones in schema.Crop
export const CROP_HISTORY_CROPS = [
    "Alfalfa",
    "Almonds",
    "Apples",
    "Apricots",
    "Aquaculture",
    "Asparagus",
    "Avocados",
    "Barley",
    "Barren",
    "Barren",
    "Blueberries",
    "Broccoli",
    "Buckwheat",
    "Cabbage",
    "Camelina",
    "Caneberries",
    "Canola",
    "Cantaloupes",
    "Carrots",
    "Cauliflower",
    "Celery",
    "Cherries",
    "Chick Peas",
    "Christmas Trees",
    "Citrus",
    "Clouds/No Data",
    "Clover/Wildflowers",
    "Corn",
    "Cotton",
    "Cranberries",
    "Cucumbers",
    "Dbl Crop Barley/Corn",
    "Dbl Crop Barley/Sorghum",
    "Dbl Crop Barley/Soybeans",
    "Dbl Crop Corn/Soybeans",
    "Dbl Crop Durum Wht/Sorghum",
    "Dbl Crop Lettuce/Barley",
    "Dbl Crop Lettuce/Cantaloupe",
    "Dbl Crop Lettuce/Cotton",
    "Dbl Crop Lettuce/Durum Wht",
    "Dbl Crop Oats/Corn",
    "Dbl Crop Soybeans/Cotton",
    "Dbl Crop Soybeans/Oats",
    "Dbl Crop Triticale/Corn",
    "Dbl Crop WinWht/Corn",
    "Dbl Crop WinWht/Cotton",
    "Dbl Crop WinWht/Sorghum",
    "Dbl Crop WinWht/Soybeans",
    "Deciduous Forest",
    "Developed",
    "Developed/High Intensity",
    "Developed/Low Intensity",
    "Developed/Med Intensity",
    "Developed/Open Space",
    "Dry Beans",
    "Durum Wheat",
    "Eggplants",
    "Evergreen Forest",
    "Fallow/Idle Cropland",
    "Flaxseed",
    "Forest",
    "Garlic",
    "Gourds",
    "Grapes",
    "Grassland/Pasture",
    "Greens",
    "Herbaceous Wetlands",
    "Herbs",
    "Honeydew Melons",
    "Hops",
    "Lentils",
    "Lettuce",
    "Millet",
    "Mint",
    "Misc Vegs & Fruits",
    "Mixed Forest",
    "Mustard",
    "Nectarines",
    "Nonag/Undefined",
    "Oats",
    "Olives",
    "Onions",
    "Open Water",
    "Oranges",
    "Other Crops",
    "Other Hay/Non Alfalfa",
    "Other Small Grains",
    "Other Tree Crops",
    "Peaches",
    "Peanuts",
    "Pears",
    "Peas",
    "Pecans",
    "Peppers",
    "Perennial Ice/Snow",
    "Pistachios",
    "Plums",
    "Pomegranates",
    "Pop or Orn Corn",
    "Potatoes",
    "Prunes",
    "Pumpkins",
    "Radishes",
    "Rape Seed",
    "Rice",
    "Rye",
    "Safflower",
    "Shrubland",
    "Shrubland",
    "Sod/Grass Seed",
    "Sorghum",
    "Soybeans",
    "Speltz",
    "Spring Wheat",
    "Squash",
    "Strawberries",
    "Sugarbeets",
    "Sugarcane",
    "Sunflower",
    "Sweet Corn",
    "Sweet Potatoes",
    "Switchgrass",
    "Tobacco",
    "Tomatoes",
    "Triticale",
    "Turnips",
    "Unknown",
    "Vetch",
    "Walnuts",
    "Water",
    "Watermelons",
    "Wetlands",
    "Winter Wheat",
    "Woody Wetlands"
];
