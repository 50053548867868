import React, { useMemo } from "react";
import { createSelector } from "../common/orm";
import { useSelector } from "react-redux";

const pulseCrops = createSelector(schema => {
    return schema.Crops.filter(row => row.id >= 100)
        .orderBy("name")
        .toModelArray();
});
const allDryBeansVarieties = createSelector(schema => {
    return schema.DryBeansVarieties.all().orderBy("name").toModelArray();
});

export default function PulseCropTable() {
    const crops = useSelector(pulseCrops),
        dryBeansVarieties = useSelector(allDryBeansVarieties),
        rows = useMemo(() => {
            const rows = [];
            for (const crop of crops) {
                if (crop.id === "200") {
                    for (const variety of dryBeansVarieties) {
                        rows.push({
                            crcrop: crop.crcrops[variety.id === "203" ? 1 : 0].name,
                            ftmcrop: crop.name,
                            variety: variety.name
                        });
                    }
                } else {
                    for (const crCrop of crop.crcrops) {
                        rows.push({
                            crcrop: crCrop.name,
                            ftmcrop: crop.name,
                            variety: ""
                        });
                    }
                }
            }
            return rows.sort(
                (row1, row2) =>
                    cmp(row1.crcrop, row2.crcrop) || cmp(row1.ftmcrop, row2.ftmcrop) || cmp(row1.variety, row2.variety)
            );
        }, [crops, dryBeansVarieties]);
    return (
        <>
            Pulse crops do not have a one-to-one correspondence with CR-LMOD search terms. Refer to the table below to
            find the search term applicable to your crop.
            <table>
                <thead>
                    <tr>
                        <th width="34%">Search Term</th>
                        <th width="33%">Field to Market Crop</th>
                        <th width="33%">FTM Dry Bean Variety</th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map(row => (
                        <tr key={row.id}>
                            <td style={{ borderTop: "1px solid #eee" }}>{row.crcrop}</td>
                            <td style={{ borderTop: "1px solid #eee" }}>{row.ftmcrop}</td>
                            <td style={{ borderTop: "1px solid #eee" }}>{row.variety}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

function cmp(str1, str2) {
    if (str1 < str2) {
        return -1;
    } else if (str1 > str2) {
        return 1;
    } else {
        return 0;
    }
}
