/* global google */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import Button from "../common/ButtonWrapper";
import Typography from "../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";

import { GoogleMap, withGoogleMap, withScriptjs, Polygon, InfoWindow, Marker } from "react-google-maps";
import { MAP } from "react-google-maps/lib/constants";

import * as navActions from "../common/actions";
import { Field } from "../field/models";

const styles = theme => ({
    legend: {
        backgroundColor: "rgba(255, 255, 255, .70)",
        borderRadius: theme.spacing(1),
        margin: theme.spacing(1),
        padding: theme.spacing(1)
    },
    legendItem: {
        height: 12,
        width: theme.spacing(2),
        display: "inline-block"
    },
    legendText: {
        display: "inline-block",
        marginLeft: theme.spacing(1)
    },
    marginTop: {
        marginTop: theme.spacing(1)
    }
});

// Generate custom marker colors
// https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|800000
const BACKGROUND_COLORS = {
    Alfalfa: "e6194b",
    Barley: "3cb44b",
    "Corn (grain)": "ffe119",
    "Corn (silage)": "0082c8",
    Cotton: "f58231",
    Peanuts: "911eb4",
    Potatoes: "46f0f0",
    Rice: "f032e6",
    Sorghum: "d2f53c",
    Soybeans: "fabebe",
    "Sugar beets": "008080",
    "Wheat (durum)": "e6beff",
    "Wheat (spring)": "aa6e28",
    "Wheat (winter)": "fffac8",
    "None/Other": "000000" //Black
};

// common/MapContainer is too specific to use elsewhere
// Create generalized dashboard version (ideally)
class DashboardMapContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            labelVisible: true,
            infoOpen: false
        };
        this.bounds = {};
        this.zoomExtent = this.zoomExtent.bind(this);
        this.zoomLevel = this.zoomLevel.bind(this);
    }
    //FIXME: This probably isn't the right way to access the map object...
    static contextTypes = { [MAP]: PropTypes.object };

    ConvertCoordToLatLong = path => {
        var fixedPath = [];
        for (var i = 0; i < path.length; i++) {
            fixedPath.push({
                lat: path[i][1],
                lng: path[i][0]
            });
        }
        return fixedPath;
    };

    getBounds = (polygon, b) => {
        polygon.forEach(element => {
            b.extend(element);
        });
        return b;
    };

    // Zoom to the fields if any
    zoomExtent(fromMount) {
        if (this.props.fields.length > 0) {
            const map = this.context[MAP];
            map.initialZoom = true;
            map.fitBounds(this.bounds);
            map.fitBounds(this.bounds); // BUG - if zoomed in to 3D mode it doesnt fully fit back until 2nd time

            // If they have a single point make sure it isn't zoomed fully in
            google.maps.event.addListener(map, "zoom_changed", function () {
                var zoomChangeBoundsListener = google.maps.event.addListener(map, "bounds_changed", function (event) {
                    if (this.getZoom() > 15 && this.initialZoom === true) {
                        this.setZoom(15);
                        this.initialZoom = false;
                    }
                    google.maps.event.removeListener(zoomChangeBoundsListener);
                });
            });
        } else if (fromMount !== true) {
            // Don't show if first mounted
            alert("User has no fields");
        }
    }

    // Hide markers/labels based on the zoom level
    zoomLevel() {
        const map = this.context[MAP];
        if (map.getZoom() < 14) {
            this.setState({ labelVisible: false });
        } else {
            this.setState({ labelVisible: true });
        }
    }

    componentDidMount() {
        const map = this.context[MAP];
        if (this.props.fields.length > 0) {
            this.zoomExtent(true);
        } else {
            // FIXME: Fields aren't loaded yet when user first logs in, 1 second might not be enough
            // Maybe force table view on login?
            const mapContainer = this;
            setTimeout(function () {
                mapContainer.zoomExtent(true);
            }, 1000);
        }
        this.zoomLevel();
        google.maps.event.addListener(map, "zoom_changed", this.zoomLevel);
    }

    populateInfoWindow(event, field) {
        this.setState({
            infoPosition: event.latLng,
            infoOpen: true,
            infoFarm: field.farm_name,
            infoField: field.name,
            infoGeometry: field.geometry,
            infoAcres: field.size.split(" ")[0],
            infoId: field.id,
            synced: field.synced
        });
    }

    loadField(infoId, geom) {
        const { ormFieldLoadDetail } = this.props;
        const mapComponent = this;

        if (geom) {
            mapComponent.zoomField(geom);
        }
        ormFieldLoadDetail(infoId, function (field) {
            // Zoom to field after load
            if (field.geometry) {
                mapComponent.zoomField(field.geometry);
            }
        });
    }

    zoomField(geom) {
        const map = this.context[MAP];
        var paths;
        if (Array.isArray(geom.coordinates[0][0][0])) {
            // Multigeometry
            paths = this.ConvertCoordToLatLong(geom.coordinates[0][0]);
        } else {
            paths = this.ConvertCoordToLatLong(geom.coordinates[0]);
        }
        var b = new google.maps.LatLngBounds();
        b = this.getBounds(paths, b);
        map.fitBounds(b);
    }

    render() {
        const { classes, allCrops, fields, navSelectPage } = this.props;
        const { labelVisible, infoPosition, infoOpen, infoFarm, infoField, infoGeometry, infoAcres, infoId, synced } =
            this.state;
        const map = this.context[MAP];
        this.bounds = new google.maps.LatLngBounds();

        return (
            <GoogleMap
                defaultZoom={4}
                defaultCenter={{ lat: 39.4, lng: -98.35 }}
                mapTypeId={google.maps.MapTypeId.HYBRID}
                defaultOptions={{
                    streetViewControl: false
                }}>
                <div
                    className={classes.legend}
                    ref={el => {
                        if (!this.renderedOnce) {
                            map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(el);
                        }
                        this.renderedOnce = true;
                    }}>
                    <Typography>
                        <u>Last Grown Crop</u>
                    </Typography>
                    {allCrops.map(crop => {
                        return (
                            <div key={crop.id}>
                                <span
                                    className={classes.legendItem}
                                    style={{ backgroundColor: "#" + BACKGROUND_COLORS[crop.name] }}
                                />
                                <Typography variant="caption" className={classes.legendText}>
                                    {crop.name}
                                </Typography>
                            </div>
                        );
                    })}
                    <div>
                        <span
                            className={classes.legendItem}
                            style={{ backgroundColor: "#" + BACKGROUND_COLORS["None/Other"] }}
                        />
                        <Typography variant="caption" className={classes.legendText}>
                            None/Other
                        </Typography>
                    </div>
                </div>

                <div
                    className={classes.marginTop}
                    ref={el => {
                        if (!this.renderedOnce2) {
                            map.controls[google.maps.ControlPosition.TOP_CENTER].push(el);
                        }
                        this.renderedOnce2 = true;
                    }}>
                    <Button
                        size="small"
                        variant="raised"
                        onClick={this.zoomExtent}
                        style={{ backgroundColor: "white" }}>
                        <Typography>Zoom To All Fields</Typography>
                    </Button>
                </div>

                {fields.map(field => {
                    // Populate Map components
                    if (!field.center) return [];
                    var paths = false;

                    // If the detailed field has already been loaded use the geometry polygon
                    if (field.geometry && labelVisible) {
                        if (Array.isArray(field.geometry.coordinates[0][0][0])) {
                            // Multigeometry
                            paths = this.ConvertCoordToLatLong(field.geometry.coordinates[0][0]);
                        } else {
                            paths = this.ConvertCoordToLatLong(field.geometry.coordinates[0]);
                        }
                        this.bounds = this.getBounds(paths, this.bounds);

                        return (
                            <Polygon
                                key={field.id}
                                paths={paths}
                                editable={false}
                                visible={true}
                                options={{
                                    strokeColor: "#000000",
                                    fillColor: "#" + BACKGROUND_COLORS[field.last_crop],
                                    strokeWeight: 1,
                                    fillOpacity: 0.25
                                }}
                                onClick={event => this.populateInfoWindow(event, field)}
                            />
                        );
                    } else {
                        this.bounds = this.getBounds([field.center], this.bounds);
                        const color = BACKGROUND_COLORS[field.last_crop] || "000000";
                        return (
                            <Marker
                                visible={true}
                                key={field.id + "_marker"}
                                position={field.center}
                                onClick={event => this.populateInfoWindow(event, field)}
                                icon={`/static/icons/marker-${color}.png`}
                                options={{
                                    shadow: "https://maps.google.com/mapfiles/ms/icons/msmarker.shadow.png"
                                }}
                            />
                        );
                    }
                })}
                {infoOpen && (
                    <InfoWindow position={infoPosition} onCloseClick={() => this.setState({ infoOpen: false })}>
                        <Typography>
                            <b>Farm</b>: {infoFarm} <br />
                            <b>Field</b>: {infoField} <br />
                            <b>Acres</b>: {infoAcres} <br />
                            <br />
                            {(!synced || !labelVisible) && (
                                <>
                                    <Button
                                        size="small"
                                        variant="raised"
                                        color="primary"
                                        onClick={() => this.loadField(infoId, infoGeometry)}>
                                        Zoom To and Display Field Boundary
                                    </Button>{" "}
                                    <br />
                                    <br />
                                </>
                            )}
                            <Button
                                size="small"
                                variant="raised"
                                color="primary"
                                onClick={() => navSelectPage("field_" + infoId)}
                                component={Link}
                                to={"/field/" + infoId}>
                                Field Dashboard
                            </Button>
                        </Typography>
                    </InfoWindow>
                )}
            </GoogleMap>
        );
    }
}

DashboardMapContainer = connect(null, {
    ...Field.actions,
    ...navActions
})(DashboardMapContainer);

export default withStyles(styles)(withScriptjs(withGoogleMap(DashboardMapContainer)));
