import React, { Component } from "react";

import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import Typography from "../../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import FertilizingCommercial from "./activities/FertilizingCommercial";
import Harvest from "./activities/Harvest";
import Irrigation from "./activities/Irrigation";
import Manure from "./activities/Manure";
import Planting from "./activities/Planting";
import { ACTIVITY_TYPES } from "./models";
import { RICE, ALFALFA } from "../../../api/constants";
import { getValue } from "../../../api/utils";

import plantingCheck from "../../common/icons/operation/icon_check_01.png";
import irrigationCheck from "../../common/icons/operation/icon_check_02.png";
import harvestCheck from "../../common/icons/operation/icon_check_03.png";
import manureCheck from "../../common/icons/operation/icon_check_04.png";
import applicationCheck from "../../common/icons/operation/icon_check_05.png";
import alfalfaCheck from "../../common/icons/operation/icon_check_06.png";
import plantingAlert from "../../common/icons/operation/icon_alert_01.png";
import irrigationAlert from "../../common/icons/operation/icon_alert_02.png";
import harvestAlert from "../../common/icons/operation/icon_alert_03.png";
import manureAlert from "../../common/icons/operation/icon_alert_04.png";
import applicationAlert from "../../common/icons/operation/icon_alert_05.png";
import alfalfaAlert from "../../common/icons/operation/icon_alert_06.png";

const styles = theme => ({
    expansionDetails: {
        display: "block"
    },
    summaryRoot: {
        minHeight: theme.spacing(8),
        borderBottom: "1px solid rgba(0, 0, 0, 0.2)"
    },
    checkBoxIcon: {
        width: 70,
        height: 70,
        marginTop: -12,
        marginRight: 8,
        marginBottom: -12
    },
    title: {
        marginTop: 12
    },
    expandMargin: {
        margin: "12px 0 !important"
    }
});

class ActivityDetails extends Component {
    componentDidMount() {
        this.props.onRef(this.activityComponent);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }
    render() {
        const {
            classes,
            expanded,
            handleExpansionChange,
            fieldActivity,
            cropYear,
            ormFieldActivityUpdate,
            ormFieldActivityUpdateLocalOnly,
            ormFieldActivityDelete,
            handleUnsavedFields,
            ormCropYearUpdate,
            ormCropYearUpdateLocalOnly
        } = this.props;

        const completed = getValue(fieldActivity, "extrainfo.completed");

        return (
            <Accordion
                key={fieldActivity.id}
                expanded={expanded === this}
                onChange={handleExpansionChange(this, fieldActivity)}>
                <AccordionSummary
                    classes={{
                        root: classes.summaryRoot,
                        expanded: classes.expandMargin
                    }}
                    expandIcon={<ExpandMoreIcon />}>
                    {completed && fieldActivity.type === "planting" && (
                        <img src={plantingCheck} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {completed && fieldActivity.type === "irrigation" && (
                        <img src={irrigationCheck} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {completed && fieldActivity.type === "harvest" && cropYear.crop !== ALFALFA && (
                        <img src={harvestCheck} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {completed && fieldActivity.type === "manure" && (
                        <img src={manureCheck} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {completed && fieldActivity.type === "application" && (
                        <img src={applicationCheck} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {completed && fieldActivity.type === "harvest" && cropYear.crop === ALFALFA && (
                        <img src={alfalfaCheck} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {!completed && fieldActivity.type === "planting" && (
                        <img src={plantingAlert} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {!completed && fieldActivity.type === "irrigation" && (
                        <img src={irrigationAlert} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {!completed && fieldActivity.type === "harvest" && cropYear.crop !== ALFALFA && (
                        <img src={harvestAlert} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {!completed && fieldActivity.type === "manure" && (
                        <img src={manureAlert} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {!completed && fieldActivity.type === "application" && (
                        <img src={applicationAlert} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    {!completed && fieldActivity.type === "harvest" && cropYear.crop === ALFALFA && (
                        <img src={alfalfaAlert} className={classes.checkBoxIcon} alt={fieldActivity.type} />
                    )}
                    <Typography variant="title" className={classes.title}>
                        {(cropYear.crop === RICE && fieldActivity.type === "manure"
                            ? "Organic "
                            : fieldActivity.type === "manure"
                              ? "Manure "
                              : "") +
                            ACTIVITY_TYPES[fieldActivity.type] +
                            " " +
                            (fieldActivity.type === "planting" && cropYear.crop === ALFALFA ? "- Establishment " : "") +
                            (fieldActivity.type === "harvest" && cropYear.crop === ALFALFA ? "- Cutting " : "") +
                            ((fieldActivity.type === "harvest" && cropYear.crop === ALFALFA) ||
                            fieldActivity.type === "manure" ||
                            fieldActivity.type === "application"
                                ? fieldActivity.code.match(/\d+/)[0]
                                : "")}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails classes={{ root: classes.expansionDetails }}>
                    {fieldActivity.type === "planting" && (
                        <Planting
                            onRef={el => (this.activityComponent = el)}
                            fieldActivity={fieldActivity}
                            ormFieldActivityUpdate={ormFieldActivityUpdate}
                            ormCropYearUpdate={ormCropYearUpdate}
                            ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                            ormFieldActivityUpdateLocalOnly={ormFieldActivityUpdateLocalOnly}
                            cropYear={cropYear}
                            handleUnsavedFields={handleUnsavedFields}
                        />
                    )}
                    {fieldActivity.type === "application" && (
                        <FertilizingCommercial
                            onRef={el => (this.activityComponent = el)}
                            fieldActivity={fieldActivity}
                            ormFieldActivityUpdate={ormFieldActivityUpdate}
                            ormFieldActivityDelete={ormFieldActivityDelete}
                            ormCropYearUpdate={ormCropYearUpdate}
                            ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                            cropYear={cropYear}
                            handleUnsavedFields={handleUnsavedFields}
                            fertilizerCount={this.props.fertilizerCount}
                        />
                    )}
                    {fieldActivity.type === "irrigation" && (
                        <Irrigation
                            onRef={el => (this.activityComponent = el)}
                            ormFieldActivityUpdateLocalOnly={ormFieldActivityUpdateLocalOnly}
                            fieldActivity={fieldActivity}
                            ormFieldActivityUpdate={ormFieldActivityUpdate}
                            ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                            cropYear={cropYear}
                            handleUnsavedFields={handleUnsavedFields}
                        />
                    )}
                    {fieldActivity.type === "manure" && (
                        <Manure
                            onRef={el => (this.activityComponent = el)}
                            fieldActivity={fieldActivity}
                            ormFieldActivityUpdate={ormFieldActivityUpdate}
                            ormFieldActivityDelete={ormFieldActivityDelete}
                            ormFieldActivityUpdateLocalOnly={ormFieldActivityUpdateLocalOnly}
                            ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                            cropYear={cropYear}
                            handleUnsavedFields={handleUnsavedFields}
                        />
                    )}
                    {fieldActivity.type === "harvest" && (
                        <Harvest
                            onRef={el => (this.activityComponent = el)}
                            fieldActivity={fieldActivity}
                            ormFieldActivityUpdate={ormFieldActivityUpdate}
                            ormFieldActivityDelete={ormFieldActivityDelete}
                            ormFieldActivityUpdateLocalOnly={ormFieldActivityUpdateLocalOnly}
                            ormCropYearUpdateLocalOnly={ormCropYearUpdateLocalOnly}
                            cropYear={cropYear}
                            handleUnsavedFields={handleUnsavedFields}
                        />
                    )}
                </AccordionDetails>
            </Accordion>
        );
    }
}

export default withStyles(styles)(ActivityDetails);
