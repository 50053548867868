import orm, { Model } from "../common/orm";
import { fk } from "redux-orm";
import { dbFetch } from "../../api/fetch";

export class Project extends Model {
    static get fields() {
        return {
            //users : many("User")
        };
    }
    static get modelName() {
        return "Project";
    }
    static get codeWidth() {
        return 4;
    }
    static generateCode(payload, state) {
        const session = orm.session(state.orm);

        let template = "FPP",
            nextCode = `${template}`,
            suffix = 0;
        while (nextCode === "FPP" || session.Project.hasId(nextCode)) {
            suffix++;

            let tmpsuf = `${suffix}`;
            while (tmpsuf.length < this.codeWidth) {
                tmpsuf = `0${tmpsuf}`;
            }

            nextCode = `${template}-${tmpsuf}`;
        }
        return nextCode;
    }
    static get actions() {
        let actions = super.actions;
        actions["checkCode"] = function (values, callback) {
            return function (dispatch, getState) {
                function update(state) {
                    // Not Redux-y but setting up an entire new state/reducer is overkill?
                    callback(state);
                }

                update({ pending: true });

                dbFetch("/api/db/projects?format=json&code=" + values.code)
                    .then(result => result.json())
                    .then(data => {
                        if (data && data.error) {
                            throw new Error(data.error);
                        }
                        update({
                            success: true,
                            result: data
                        });
                    })
                    .catch(e => {
                        update({
                            success: false,
                            error: e.message || "Unknown Error"
                        });
                    });
            };
        };
        return actions;
    }
}
orm.register(Project);

export class ProjectUser extends Model {
    static get fields() {
        return {
            project: fk("Project", "projectusers")
        };
    }
    static get modelName() {
        return "ProjectUser";
    }
}
orm.register(ProjectUser);

export class ProjectContact extends Model {
    static get fields() {
        return {
            project: fk("Project", "contacts")
        };
    }
    static get modelName() {
        return "ProjectContact";
    }
}
orm.register(ProjectContact);
