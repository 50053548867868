import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "../../../common/ButtonWrapper";
import Grid from "../../../common/GridWrapper";
import withStyles from "@mui/styles/withStyles";

import { RiceCultivars, RicePlantingMethods } from "./models";
import { DryBeansVarieties } from "../models";
import RadioGroup from "../../../common/RadioGroup";
import Select from "../../../common/Select";
import Snackbar from "../../../common/Snackbar";
import TextField from "../../../common/TextField";
import { createSelector } from "../../../common/orm";
import { ALFALFA, BEANS, RICE, MAKE_OPTIONS, YES_NO_OPTIONS } from "../../../../api/constants";
import { setValue, getValue } from "../../../../api/utils";

const allDryBeansVarieties = DryBeansVarieties.selectAll();
const allRiceCultivars = RiceCultivars.selectAll();
const allRicePlantingMethods = RicePlantingMethods.selectAll();
const allSeedingRates = createSelector(
    (state, ownProps) => ownProps.cropYear,
    (session, cropYear) => {
        //FIXME: crop_ids is an array, not sure how to use the redux filter on an array so just use custom filter on the returned array
        return session.SeedingRates.all() /*.filter({crop_ids: cropYear.crop})*/
            .toRefArray()
            .filter(SR => (SR.crop_ids ? SR.crop_ids.includes(cropYear.crop) : true));
    }
);

const styles = theme => ({
    button: {
        marginRight: theme.spacing(1)
    },
    rightAlign: {
        textAlign: "right"
    },
    noButton: {
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            WebkitAppearance: "none"
        }
    }
});

class Planting extends Component {
    state = {
        snackbarOpen: false
    };

    updateFieldActivity(values) {
        const { id } = this.props.fieldActivity;
        var isCompleted = this.errorValidator(values, this.props.cropYear, false);

        if (values.bean_variety_id && values.bean_variety_id !== this.props.cropYear.bean_variety_id) {
            this.props.ormCropYearUpdate({
                id: this.props.cropYear.id,
                bean_variety_id: values.bean_variety_id
            });
        }
        this.props.ormFieldActivityUpdate({
            id: id,
            ...values,
            extrainfo: {
                completed: isCompleted[0] === 0,
                missing: isCompleted[0],
                required: isCompleted[1]
            }
        });
        this.props.ormCropYearUpdateLocalOnly({
            id: this.props.cropYear.id,
            metrics: null
        });
        this.props.handleUnsavedFields(false);

        // Reapply required label to any field not yet filled in by adding a nonexistant value
        this.setState({ submitClicked: false, snackbarOpen: true });
        this.form.addValue("foo", "bar");
    }

    errorValidator = (values, cY, hardRequire) => {
        var numberRequired = 0; // Keep track of the actual number of required fields
        const isRequired = path => {
            numberRequired++;
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };
        const validateYear = path => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            if (val.length !== 4) {
                setValue(valObj, path, "Invalid year");
                return;
            }
            const number = parseFloat(val);
            if (number < 1900 || number > 2100) {
                setValue(valObj, path, "Invalid year");
                return;
            }
            if (number % 1 !== 0) {
                setValue(valObj, path, "Invalid year");
                return;
            }
            setValue(valObj, path, null);
        };
        const validateStand = path => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            const number = parseFloat(val);
            if (number < 1 || number > 15) {
                setValue(valObj, path, "Must be between 1-15");
                return;
            }
            if (number % 1 !== 0) {
                setValue(valObj, path, "Round to the nearest whole number");
                return;
            }
            setValue(valObj, path, null);
        };
        var valObj = {};

        isRequired("planting.energyuse.seeding_rate");
        isRequired("planting.energyuse.has_seed_treatment");

        if (cY.crop === ALFALFA) {
            validateYear("planting.energyuse.establishment_year");
            validateStand("planting.energyuse.stand_life");
        } else if (cY.crop === BEANS) {
            isRequired("bean_variety_id");
        } else if (cY.crop === RICE) {
            isRequired("planting.greenhouse.seeding_method");

            // Temp get state
            var isCA = false;
            if (cY.fieldObject.location) {
                if (cY.fieldObject.location.slice(-2) === "CA") isCA = true;
            }
            if (!isCA) {
                isRequired("planting.greenhouse.rice_cultivar");
            }
        }

        if (hardRequire) {
            // hardRequire = actual validation
            // FIXME: v2.X of react-forms has a submitting attribute on the formapi, but doesn't appear to be functional
            // V3.X seems to be a lot of work to upgrade
            // We are simulating a custom state (submitClicked) to know if its actually submiting
            // If it is submitting ignore the validator, submit, and move on
            if (this.state.submitClicked) {
                Object.keys(valObj).forEach(function (key) {
                    if (valObj[key] !== null && typeof valObj[key] === "object") {
                        // Also check child objects
                        Object.keys(valObj[key]).forEach(function (childKey) {
                            if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                                Object.keys(valObj[key][childKey]).forEach(function (childKey2) {
                                    valObj[key][childKey][childKey2] = null;
                                });
                            }
                        });
                    }
                });
            }
            return valObj;
        }

        // If we are doing the final save of the form track how many fields are missing
        var missing = 0;
        Object.keys(valObj).forEach(function (key) {
            if (valObj[key] !== null && typeof valObj[key] === "object") {
                // Also check child objects
                Object.keys(valObj[key]).forEach(function (childKey) {
                    if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                        Object.keys(valObj[key][childKey]).forEach(function (childKey2) {
                            if (valObj[key][childKey][childKey2] !== null) missing++;
                        });
                    }
                });
            } else if (valObj[key] !== null) missing++;
        });

        return [missing, numberRequired];
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        this.props.onRef(this);
        setTimeout(function () {
            handleUnsavedFields(false);
        }, 100);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    render() {
        const {
            classes,
            fieldActivity,
            seedingRates,
            cropYear,
            beanVarieties,
            riceCultivars,
            ricePlantingMethods,
            handleUnsavedFields
        } = this.props;
        const { snackbarOpen } = this.state;

        // Temp get state
        var isCA = false;
        if (cropYear.fieldObject.location) {
            if (cropYear.fieldObject.location.slice(-2) === "CA") isCA = true;
        }

        // Only run validation if users have been here before (missing set)
        const miss = getValue(fieldActivity, "extrainfo.missing");
        const isMissing = miss > 0;

        const getFormValue = name => (this.form ? this.form.getValue(name) : getValue(fieldActivity, name));

        const sRate = getFormValue("planting.energyuse.seeding_rate");
        const sR = seedingRates.filter(sR => sR.id === sRate)[0];
        var seedingRateUnits;
        if (sR) seedingRateUnits = sR.units;

        return (
            <Form
                getApi={el => (this.form = el)}
                key={fieldActivity.id}
                dontValidateOnMount={isMissing ? false : true}
                validateOnSubmit={isMissing ? false : true}
                defaultValues={fieldActivity}
                formDidUpdate={() => handleUnsavedFields(true)}
                validateError={values => this.errorValidator(values, cropYear, true)}
                onSubmitFailure={(errors, formApi) => {
                    // This only occurs when switching steppers from step 3
                    // The errorValidator is indeed returning null for every valobj however it still has the old errors in finishSubmission
                    // https://github.com/react-tools/react-form/blob/v2.16.3/src/components/ReduxForm.js
                    // Something to do with calling submitform from two parent components above? (3_FieldActivites -> Save Operations)
                    // Skip the validation and go straight to the orm update
                    this.updateFieldActivity(formApi.values);
                }}
                onSubmit={values => this.updateFieldActivity(values)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <Grid container spacing={24}>
                            <Grid item xs={6} md={5} lg={4} xl={3}>
                                <Select
                                    field="planting.energyuse.seeding_rate"
                                    label="Seeding rate (range)"
                                    options={MAKE_OPTIONS(seedingRates)}
                                    unitsVisual={seedingRateUnits}
                                    fullWidth
                                    margin="normal"
                                />
                                {cropYear.crop === ALFALFA && (
                                    <TextField
                                        type="number"
                                        inputProps={{ step: 1 }}
                                        className={classes.noButton}
                                        field="planting.energyuse.establishment_year"
                                        help="Enter the year that this alfalfa stand was established."
                                        fullWidth
                                        label="Alfalfa establishment year"
                                        margin="normal"
                                    />
                                )}
                                {cropYear.crop === RICE && (
                                    <Select
                                        field="planting.greenhouse.seeding_method"
                                        label="Rice planting method"
                                        help="Select whether the rice was drill- seeded (dry seed bed) or water-seeded (planted into a flooded field)."
                                        options={MAKE_OPTIONS(ricePlantingMethods)}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={6} md={5} lg={4} xl={3}>
                                <RadioGroup
                                    fullWidth
                                    field="planting.energyuse.has_seed_treatment"
                                    name="seedingtreatment"
                                    options={YES_NO_OPTIONS}
                                    alignment={true}
                                    label="Seed treatment"
                                />
                                {cropYear.crop === ALFALFA && (
                                    <TextField
                                        type="number"
                                        inputProps={{ step: 1 }}
                                        field="planting.energyuse.stand_life"
                                        label="Expected stand life"
                                        units="yr"
                                        help="Enter the length of time, in years, that you expect to manage this established stand before replanting alfalfa or rotating the field to another crop."
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                                {cropYear.crop === BEANS && (
                                    <Select
                                        field="bean_variety_id"
                                        label="Dry bean variety"
                                        options={MAKE_OPTIONS(beanVarieties)}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                                {cropYear.crop === RICE && !isCA && (
                                    <Select
                                        field="planting.greenhouse.rice_cultivar"
                                        label="Rice cultivar"
                                        help="Select whether the seed planted is for a tall variety, semi-dwarf variety or with the specific hybrid CLXL745. Other specific hybrids should simply select whether they are tall or semi-dwarf. This variety selection will impact the methane emissions associated with the crop."
                                        options={MAKE_OPTIONS(riceCultivars)}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    type="submit"
                                    variant="raised"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => this.setState({ submitClicked: true })}>
                                    Save Planting
                                </Button>
                            </Grid>
                            <Snackbar
                                success
                                onClose={this.handleSnackbarClose}
                                open={snackbarOpen}
                                section="planting operation"
                            />
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

Planting = connect((state, ownProps) => ({
    seedingRates: allSeedingRates(state, ownProps),
    beanVarieties: allDryBeansVarieties(state, ownProps),
    riceCultivars: allRiceCultivars(state, ownProps),
    ricePlantingMethods: allRicePlantingMethods(state, ownProps)
}))(Planting);

export default withStyles(styles)(Planting);
