import React, { Component } from "react";
import { Form } from "react-form";
import withRouter from "../common/withRouter";
import { connect } from "react-redux";

import Button from "../common/ButtonWrapper";
import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import Grid from "../common/GridWrapper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

import { CropYear, FieldActivity } from "./fieldactivities/models";
import { FarmCropYear } from "./models";
import { Fertilizer, ActivityIrrigationWaterUseEvent } from "./fieldactivities/activities/models";
import CheckboxGroup from "../common/CheckboxGroup";
import Select from "../common/Select";
import HelpLabel from "../common/HelpLabel";
import TextField from "../common/TextField";
import Switch from "../common/Switch";
import { createSelector } from "../common/orm";
import { MAKE_OPTIONS, RICE, NOT_DRIED_OR_CUSTOM_DRIED } from "../../api/constants";
import { getValue, setValue } from "../../api/utils";

const DRYING_INPUTS = [
    "harvest.energyuse.drying_system",
    "harvest.energyuse.drying_use_actual",
    "harvest.energyuse.drying_energy_source",
    "harvest.energyuse.drying_electricity_amount",
    "harvest.energyuse.drying_fuel_id",
    "harvest.energyuse.drying_fuel_amount",
    "harvest.energyuse.moisture_removed"
];

const allFarms = createSelector(schema => {
    return schema.Farm.all().orderBy("name").toModelArray();
});

const allFields = createSelector(schema => {
    return schema.Field.all().orderBy("name").toModelArray();
});

const allCrops = createSelector(schema => {
    return schema.Crops.all().orderBy("name").toModelArray();
});
const allDryBeansVarieties = createSelector(schema => {
    return schema.DryBeansVarieties.all().orderBy("name").toModelArray();
});
const getFarmCropYear = createSelector(
    (state, ownProps) => ownProps.farm_id,
    (session, farm_id) => {
        return session.FarmCropYear.filter({ farm: farm_id }).toModelArray();
    }
);

const getCropYears = createSelector(
    (state, ownProps) => ownProps.match.params["id"],
    session => {
        return session.CropYear.orderBy("year", "desc")
            .toModelArray()
            .map(cropYear => ({
                crop_name: cropYear.crop.name,
                field_name: cropYear.field.name,
                farm_name: cropYear.field.farm.name,
                activitiesLoad: cropYear.activities.all().toModelArray(),
                _activities: cropYear.activities
                    .all()
                    .toModelArray()
                    .map(activity => {
                        const activityFert = activity.fertilizers.count();
                        const activityWaterUse = activity.wateruseevent.count();
                        return {
                            _fertilizers: activityFert > 0 && activity.fertilizers.all().toRefArray(),
                            _wateruseevent: activityWaterUse > 0 && activity.wateruseevent.all().toRefArray(),
                            ...activity.ref
                        };
                    }),
                ...cropYear.ref
            }));
    }
);

const styles = {
    dialogMin: {
        minWidth: "400px"
    },
    flex: {
        flex: 1
    },
    button: {
        float: "right"
    },
    gutterTop: {
        marginTop: ".6em"
    },
    minWidth: {
        minWidth: 600
    }
};

class AddCropYear extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            previousYear: "empty",
            previousFarm: props.farm_id,
            previousField: props.fieldId,
            showFarmCropYear: false,
            showCopyFields: false,
            crop: null,
            year: null,
            farmCropYearUpdate: null
        };
    }

    loadCropYear(cropYear) {
        const { ormCropYearLoadDetail, ormFieldActivityLoadDetail, ormFertilizerLoadDetail } = this.props;

        if (!getValue(cropYear, "synced")) {
            ormCropYearLoadDetail(cropYear.id);

            cropYear.activitiesLoad.forEach(function (activity) {
                ormFieldActivityLoadDetail(activity.id);

                activity.fertilizers.toModelArray().forEach(function (fertilizer) {
                    ormFertilizerLoadDetail(fertilizer.id);
                });
            });
        }
    }

    addCropYear(values) {
        const {
            cropYears,
            ormCropYearCreate,
            fieldId,
            ormFieldActivityCreate,
            returnDialog,
            farm_id,
            ormFarmCropYearCreate,
            ormFarmCropYearUpdate,
            ormFertilizerCreate,
            ormActivityIrrigationWaterUseEventCreateRemoteFirst
        } = this.props;
        var cropYearId;

        // Save the managed acre record if applicable
        const { showFarmCropYear, farmCropYearUpdate } = this.state;
        if (showFarmCropYear) {
            if (!farmCropYearUpdate) {
                ormFarmCropYearCreate({
                    farm: farm_id,
                    year: values.year,
                    crop: values.primary,
                    irrigated: values.irrigated,
                    nonirrigated: values.nonirrigated
                });
            } else {
                ormFarmCropYearUpdate({
                    id: farmCropYearUpdate,
                    farm: farm_id,
                    year: values.year,
                    crop: values.primary,
                    irrigated: values.irrigated,
                    nonirrigated: values.nonirrigated
                });
            }
            this.setState({ showFarmCropYear: false });
        }

        // Use values from a previous year
        if (values.defaultyear && values.defaultyear !== "empty") {
            const pY = cropYears.filter(cY => cY.id === values.defaultyear)[0];
            const pYInsert = {};

            //setValue(pYInsert, "extrainfo.completed", getValue(pY, "extrainfo.completed"));
            //setValue(pYInsert, "extrainfo.missing", getValue(pY, "extrainfo.missing"));
            //setValue(pYInsert, "extrainfo.required", getValue(pY, "extrainfo.required"));
            if (values.defaults && values.defaults.includes("rotation")) {
                // Copy the step 1 values
                setValue(pYInsert, "biodiversity.rotation_practice", getValue(pY, "biodiversity.rotation_practice"));
                setValue(pYInsert, "rotation", getValue(pY, "rotation"));
                setValue(pYInsert, "greenhouse.residue_burning", getValue(pY, "greenhouse.residue_burning"));
                setValue(pYInsert, "biodiversity.cover_crop", getValue(pY, "biodiversity.cover_crop"));
                setValue(pYInsert, "waterquality.cover_crop_species", getValue(pY, "waterquality.cover_crop_species"));
                setValue(
                    pYInsert,
                    "waterquality.cover_crop_establish_timing",
                    getValue(pY, "waterquality.cover_crop_establish_timing")
                );
                setValue(pYInsert, "biodiversity.tillage_class", getValue(pY, "biodiversity.tillage_class"));
                setValue(pYInsert, "greenhouse.previous_crop_id", getValue(pY, "greenhouse.previous_crop_id"));
                setValue(pYInsert, "greenhouse.yield_previous", getValue(pY, "greenhouse.yield_previous"));
            }

            if (values.defaults && values.defaults.includes("management")) {
                // Copy the step 2 values
                setValue(pYInsert, "is_irrigated", getValue(pY, "is_irrigated"));
                setValue(pYInsert, "soils.has_wind_barriers", getValue(pY, "soils.has_wind_barriers"));
                setValue(pYInsert, "waterquality.tile_drainage", getValue(pY, "waterquality.tile_drainage"));
                setValue(pYInsert, "greenhouse.water_regime", getValue(pY, "greenhouse.water_regime"));
                setValue(
                    pYInsert,
                    "biodiversity.nutrient_management_plan",
                    getValue(pY, "biodiversity.nutrient_management_plan")
                );
                setValue(pYInsert, "energyuse.lime_applied", getValue(pY, "energyuse.lime_applied"));
                setValue(pYInsert, "energyuse.lime_year_applied", getValue(pY, "energyuse.lime_year_applied"));
                setValue(pYInsert, "energyuse.lime_years", getValue(pY, "energyuse.lime_years"));
                setValue(pYInsert, "energyuse.lime_amount", getValue(pY, "energyuse.lime_amount"));
                setValue(pYInsert, "greenhouse.has_sulfur", getValue(pY, "greenhouse.has_sulfur"));
                setValue(pYInsert, "greenhouse.sulfur_rate", getValue(pY, "greenhouse.sulfur_rate"));
                setValue(pYInsert, "waterquality.nutrient_applied", getValue(pY, "waterquality.nutrient_applied"));
                setValue(pYInsert, "waterquality.nitrogen_applied", getValue(pY, "waterquality.nitrogen_applied"));
                setValue(pYInsert, "waterquality.n_carry_over", getValue(pY, "waterquality.n_carry_over"));
                setValue(pYInsert, "waterquality.phosphorus_applied", getValue(pY, "waterquality.phosphorus_applied"));
                setValue(pYInsert, "waterquality.p_soil_test_level", getValue(pY, "waterquality.p_soil_test_level"));
                setValue(pYInsert, "waterquality.p_multi_year_app", getValue(pY, "waterquality.p_multi_year_app"));
                setValue(
                    pYInsert,
                    "waterquality.management_techniques",
                    getValue(pY, "waterquality.management_techniques")
                );
                setValue(pYInsert, "waterquality.pest_management_id", getValue(pY, "waterquality.pest_management_id"));
                setValue(pYInsert, "biodiversity.land_cover_change", getValue(pY, "biodiversity.land_cover_change"));
                setValue(
                    pYInsert,
                    "biodiversity.previous_land_cover",
                    getValue(pY, "biodiversity.previous_land_cover")
                );
                setValue(
                    pYInsert,
                    "biodiversity.field_area_converted",
                    getValue(pY, "biodiversity.field_area_converted")
                );
                setValue(pYInsert, "biodiversity.nutrient_4_rs", getValue(pY, "biodiversity.nutrient_4_rs"));
                setValue(
                    pYInsert,
                    "biodiversity.wildlife_habitat_ids",
                    getValue(pY, "biodiversity.wildlife_habitat_ids")
                );
                setValue(pYInsert, "waterquality.conservation_codes", getValue(pY, "waterquality.conservation_codes"));
            }

            const nY = {
                field: fieldId,
                year: values.year,
                crop: values.primary,
                activeStep: 0
            };

            // Later properties overwrite earlier properties with the same name.
            const cropPayload = { ...pYInsert, ...nY };
            cropYearId = ormCropYearCreate(cropPayload);

            if (values.defaults && values.defaults.includes("field")) {
                var irrigation_present = false;
                // Copy all the step 3 activities
                pY._activities.forEach(function (activity) {
                    //activity = Object.assign({}, activity);
                    activity = JSON.parse(JSON.stringify(activity));
                    delete activity.id;
                    delete activity.cropyear_id;
                    delete activity.extrainfo;
                    activity.cropyear = cropYearId;

                    if (activity.type === "irrigation") {
                        irrigation_present = true;
                    }

                    // Clear out planting.energyuse.seeding_rate if different crop
                    if (activity.type === "planting" && pY.crop_id !== values.primary) {
                        setValue(activity, "planting.energyuse.seeding_rate", null);
                    }

                    // Clear out drying inputs if not applicable
                    if (activity.type === "harvest" && NOT_DRIED_OR_CUSTOM_DRIED.includes(values.primary)) {
                        DRYING_INPUTS.forEach(name => setValue(activity, name, null));
                    }

                    if (activity._fertilizers) {
                        var _ferts = activity._fertilizers;
                        delete activity._fertilizers;

                        var fieldActivityId = ormFieldActivityCreate(activity);

                        _ferts.forEach(function (f) {
                            f = Object.assign({}, f);
                            delete f.id;
                            f.activity = fieldActivityId;
                            f.activity_id = fieldActivityId;
                            ormFertilizerCreate(f);
                        });
                    } else if (activity._wateruseevent) {
                        var _water = activity._wateruseevent;
                        delete activity._wateruseevent;

                        var id = ormFieldActivityCreate(activity);

                        _water.forEach(function (f) {
                            f = Object.assign({}, f);
                            delete f.id;
                            f.activity = id;
                            f.activity_id = id;
                            setTimeout(function () {
                                ormActivityIrrigationWaterUseEventCreateRemoteFirst(f);
                            }, 5000);
                        });
                    } else {
                        ormFieldActivityCreate(activity);
                    }
                });

                // If the user is creating a rice crop year and the crop year they are copying from doesn't contain an irrigation activity then create one
                if (values.primary === RICE && !irrigation_present) {
                    this.props.ormFieldActivityCreate({
                        cropyear: cropYearId,
                        type: "irrigation",
                        order: 3
                    });
                }
            } else {
                // Otherwise add the defaults
                ormFieldActivityCreate({
                    cropyear: cropYearId,
                    type: "planting",
                    order: 1
                });
                ormFieldActivityCreate({
                    cropyear: cropYearId,
                    type: "harvest",
                    order: 2
                });
                ormFieldActivityCreate({
                    cropyear: cropYearId,
                    type: "application",
                    order: 4,
                    application: {
                        energyuse: {
                            herbicide_count: "0",
                            insecticide_count: "0",
                            fungicide_count: "0",
                            growthregulator_count: "0",
                            fumigant_count: "0",
                            harvestaid_count: "0"
                        }
                    }
                });
            }
        } else {
            // Create a new crop year (but set the previous primary crop and lime year from last years)
            const pY = cropYears.filter(cY => cY.year === (values.year - 1).toString())[0];

            const cropPayload = {
                field: fieldId,
                year: values.year,
                crop: values.primary,
                activeStep: 0,
                greenhouse: {
                    previous_crop_id: getValue(pY, "crop"),
                    yield_previous: getValue(pY, "landuse.yield")
                },
                energyuse: {
                    lime_applied: getValue(pY, "energyuse.lime_applied"),
                    lime_year_applied: getValue(pY, "energyuse.lime_year_applied"),
                    lime_years: getValue(pY, "energyuse.lime_years"),
                    lime_amount: getValue(pY, "energyuse.lime_amount")
                }
            };
            if (values.primary === RICE) {
                cropPayload["is_irrigated"] = true;
            }

            cropYearId = ormCropYearCreate(cropPayload);

            // Add planting, harvest, and application activities right away
            ormFieldActivityCreate({
                cropyear: cropYearId,
                type: "planting",
                order: 1
            });
            ormFieldActivityCreate({
                cropyear: cropYearId,
                type: "harvest",
                order: 2
            });
            ormFieldActivityCreate({
                cropyear: cropYearId,
                type: "application",
                order: 4,
                application: {
                    energyuse: {
                        herbicide_count: "0",
                        insecticide_count: "0",
                        fungicide_count: "0",
                        growthregulator_count: "0",
                        fumigant_count: "0",
                        harvestaid_count: "0"
                    }
                }
            });

            if (values.primary === RICE) {
                this.props.ormFieldActivityCreate({
                    cropyear: cropYearId,
                    type: "irrigation",
                    order: 3
                });
            }
        }
        returnDialog();

        this.props.history.push("/cropyear/" + cropYearId);
    }

    handlePrevYear = value => {
        const { cropYears } = this.props;
        const pY = cropYears.filter(cY => cY.id === value)[0];

        this.loadCropYear(pY);
        this.setState({ previousYear: value });
    };

    handlePrevFarm = value => {
        this.setState({ previousFarm: value, previousField: "empty", previousYear: "empty" });
    };

    handlePrevField = value => {
        this.setState({ previousField: value, previousYear: "empty" });
    };

    showCopyFields = value => {
        const { farm_id, fieldId } = this.props;
        this.formApi.setValue("defaultyear", "empty");
        this.setState({ showCopyFields: value, previousYear: "empty", previousFarm: farm_id, previousField: fieldId });
    };

    errorValidator = values => {
        const { fieldSize } = this.props;
        const validateYear = year => {
            var duplicated = false;
            this.props.cropYearsField.forEach(function (c) {
                if (c.year === year) duplicated = true;
            });
            if (!year) {
                return "Required";
            }
            if (year.length !== 4) {
                return "Invalid year";
            }
            const number = parseFloat(year);
            if (number < 1900 || number > 2100) {
                return "Invalid year";
            }
            if (number % 1 !== 0) {
                return "Invalid year";
            }
            if (duplicated) {
                return "Duplicate year";
            }
            return null;
        };
        const validateNumber = (num, sib) => {
            if (!num) {
                return "Required";
            }
            const number = parseFloat(num);
            if (number < 0 || number > 80000) {
                return "Must be between 0-80000";
            }
            const sibling = parseFloat(sib);
            if (number + sibling < parseFloat(fieldSize)) {
                return "The total number of non-irrigated and irrigated acres cannot be less than the plantable acres for this field";
            }
            return null;
        };
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        var valObj = {
            year: validateYear(values.year),
            primary: isRequired(values.primary)
        };

        const { showFarmCropYear } = this.state;

        if (showFarmCropYear) {
            valObj["irrigated"] = validateNumber(values.irrigated, values.nonirrigated);
            valObj["nonirrigated"] = validateNumber(values.nonirrigated, values.irrigated);
        }

        return valObj;
    };

    checkFarmCropYear = value => {
        const { farmCropYear, cropLookup } = this.props;

        const addComponent = this;
        setTimeout(function () {
            // The formapi values are not present right away
            const year = addComponent.formApi.getValue("year");
            const primary = addComponent.formApi.getValue("primary");
            if (year && primary) {
                // Check if there already is an existing managed acre record for this year and crop (already filtered by farm)
                // FIXME: New records have a null crop and instead have a crop_id?
                const mALength = farmCropYear.filter(
                    mA => mA.year === year && ((mA.crop && mA.crop.id === primary) || mA.crop_id === primary)
                );
                if (mALength.length > 0) {
                    addComponent.formApi.setValue("nonirrigated", mALength[0].nonirrigated);
                    addComponent.formApi.setValue("irrigated", mALength[0].irrigated);
                    addComponent.setState({
                        showFarmCropYear: true,
                        year: year,
                        crop: cropLookup[primary].name,
                        farmCropYearUpdate: mALength[0].id
                    });
                } else {
                    addComponent.formApi.setValue("nonirrigated", "");
                    addComponent.formApi.setValue("irrigated", "");
                    addComponent.setState({
                        showFarmCropYear: true,
                        year: year,
                        crop: cropLookup[primary].name,
                        farmCropYearUpdate: null
                    });
                }
            } else {
                addComponent.setState({ showFarmCropYear: false });
            }
        }, 100);
    };

    render() {
        const { classes, open, allCrops, allDryBeansVarieties, returnDialog, farm_name, cropLookup } = this.props;
        let { allFarms, allFields, cropYears } = this.props;

        const {
            previousYear,
            previousFarm,
            previousField,
            showFarmCropYear,
            crop,
            year,
            farmCropYearUpdate,
            showCopyFields
        } = this.state;

        function makeOptionsCropYear(table) {
            return table.map(row => ({
                label: row.year + " " + cropLookup[row.crop].name,
                value: row.id
            }));
        }
        const defaultOptions = [
            { label: "Rotation", value: "rotation" },
            { label: "Management", value: "management" },
            { label: "Operations", value: "field" }
        ];

        if (previousFarm !== "empty") {
            allFields = allFields.filter(f => f._fields.farm === previousFarm);
        }

        if (previousField !== "empty") {
            cropYears = cropYears.filter(f => f.field === previousField);
        }

        return (
            <Dialog open={open} classes={{ paper: classes.minWidth }}>
                <Toolbar>
                    <Typography variant="title" className={classes.flex}>
                        Add New Crop Year
                    </Typography>
                    <IconButton
                        onClick={() => {
                            this.setState({ showFarmCropYear: false });
                            returnDialog();
                        }}
                        size="large">
                        <Close />
                    </IconButton>
                </Toolbar>
                <DialogContent className={classes.dialogMin}>
                    <DialogContentText>
                        <Form
                            getApi={el => (this.formApi = el)}
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            validateError={this.errorValidator}
                            defaultValues={{
                                year: new Date().getFullYear().toString(),
                                defaults: ["rotation", "management", "field"],
                                fa: previousFarm,
                                fi: previousField,
                                defaultyear: previousYear
                            }}
                            onSubmit={values => this.addCropYear(values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={16}>
                                        <Grid item xs={12}>
                                            <TextField
                                                field="year"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Crop year"
                                                        helpText="This is the year the crop is harvested."
                                                    />
                                                }
                                                eventHandle={this.checkFarmCropYear}
                                                fullWidth
                                                type="number"
                                                margin="normal"
                                            />
                                            <Select
                                                field="primary"
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Enter the primary crop grown in the crop year"
                                                        helpText="When double- or relay-cropping, enter what you consider the ‘main’ crop."
                                                    />
                                                }
                                                options={MAKE_OPTIONS(allCrops)}
                                                eventHandle={this.checkFarmCropYear}
                                                fullWidth
                                                margin="normal"
                                            />
                                            <HelpLabel
                                                inputLabel={
                                                    <span style={{ color: "#999" }}>
                                                        *Important note on selecting Pulse or Dry Bean crops
                                                    </span>
                                                }
                                                helpText={
                                                    <Grid container>
                                                        <Grid xs={6}>
                                                            Please select <strong>Dry Beans</strong> as your primary
                                                            crop if planting one of these varieties:
                                                            <table
                                                                style={{
                                                                    margin: "auto",
                                                                    border: "1px solid #999",
                                                                    margin: 8
                                                                }}>
                                                                <thead>
                                                                    <th>Dry Bean Varieties</th>
                                                                </thead>
                                                                <tbody>
                                                                    {allDryBeansVarieties.map(row => (
                                                                        <tr key={row.id}>
                                                                            <td
                                                                                style={{
                                                                                    paddingLeft: 8,
                                                                                    paddingRight: 8
                                                                                }}>
                                                                                {row.name}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </Grid>
                                                        <Grid xs={6}>
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            <br />
                                                            Otherwise, select the corresponding primary crop:
                                                            <table
                                                                style={{
                                                                    margin: "auto",
                                                                    border: "1px solid #999",
                                                                    margin: 8
                                                                }}>
                                                                <thead>
                                                                    <th>Other Pulse Crops</th>
                                                                </thead>
                                                                <tbody>
                                                                    {allCrops
                                                                        .filter(row => row.id >= 100 && row.id < 200)
                                                                        .map(row => (
                                                                            <tr key={row.id}>
                                                                                <td
                                                                                    style={{
                                                                                        paddingLeft: 8,
                                                                                        paddingRight: 8
                                                                                    }}>
                                                                                    {row.name}
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                </tbody>
                                                            </table>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            />
                                            {((previousFarm === "empty" && cropYears.length > 0) ||
                                                previousFarm !== "empty") && (
                                                <div>
                                                    <Switch
                                                        field="switch"
                                                        name="switch"
                                                        eventHandle={value => this.showCopyFields(value)}
                                                        label="Would you like to copy inputs from another crop year?"
                                                    />

                                                    {showCopyFields && (
                                                        <div>
                                                            <Select
                                                                field="fa"
                                                                label="Farm"
                                                                eventHandle={value => this.handlePrevFarm(value)}
                                                                options={MAKE_OPTIONS(allFarms)}
                                                                fullWidth
                                                                margin="normal"
                                                                value={previousFarm}
                                                            />
                                                            {previousFarm !== "empty" && (
                                                                <Select
                                                                    field="fi"
                                                                    label="Select Field"
                                                                    eventHandle={value => this.handlePrevField(value)}
                                                                    options={MAKE_OPTIONS(allFields)}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    value={previousField}
                                                                />
                                                            )}
                                                            {previousField !== "empty" && (
                                                                <Select
                                                                    field="defaultyear"
                                                                    label="Select Crop Year"
                                                                    eventHandle={value => this.handlePrevYear(value)}
                                                                    options={makeOptionsCropYear(cropYears)}
                                                                    fullWidth
                                                                    margin="normal"
                                                                    value={previousYear}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            {previousYear !== "empty" && (
                                                <CheckboxGroup
                                                    field="defaults"
                                                    fullWidth
                                                    label="Which inputs should be copied?"
                                                    row={true}
                                                    options={defaultOptions}
                                                />
                                            )}
                                            {showFarmCropYear && (
                                                <div>
                                                    <Typography
                                                        variant="title"
                                                        gutterBottom
                                                        className={classes.gutterTop}>
                                                        {farmCropYearUpdate ? "Confirm" : "Enter"} Managed Acres
                                                        <HelpLabel
                                                            question="Managed Acres"
                                                            style={{ marginLeft: 4, display: "inline" }}
                                                            helpText={
                                                                <div>
                                                                    <Typography gutterBottom>
                                                                        Managed acres represent the total number of
                                                                        acres, for both irrigated and non-irrigated
                                                                        acres, you grow for the selected crop on this
                                                                        farm in the selected year. You can edit this
                                                                        information at any time on the Farm page.
                                                                    </Typography>
                                                                    <Typography gutterBottom>
                                                                        For example, you enter two corn fields for a
                                                                        total of 100 acres in the Platform for 2017,
                                                                        that represent a total of 850 acres of corn
                                                                        across your entire farm. If these fields are
                                                                        associated with a Project, the total managed
                                                                        acres (850 acres) may be considered enrolled if
                                                                        a minimum of 10% of the total managed acres are
                                                                        entered in Fieldprint Platform.
                                                                    </Typography>
                                                                </div>
                                                            }
                                                        />
                                                    </Typography>
                                                    <TextField
                                                        field="nonirrigated"
                                                        label={
                                                            <div>
                                                                Enter the total <b>non-irrigated</b> acres of {crop}{" "}
                                                                grown in {year} on {farm_name}.
                                                            </div>
                                                        }
                                                        fullWidth
                                                        type="number"
                                                        units="acres"
                                                        margin="normal"
                                                    />
                                                    <TextField
                                                        field="irrigated"
                                                        label={
                                                            <div>
                                                                Enter the total <b>irrigated</b> acres of {crop} grown
                                                                in {year} on {farm_name}.
                                                            </div>
                                                        }
                                                        fullWidth
                                                        type="number"
                                                        units="acres"
                                                        margin="normal"
                                                    />
                                                </div>
                                            )}
                                            <Button
                                                type="submit"
                                                variant="raised"
                                                color="primary"
                                                className={classes.button}>
                                                Add and Proceed to Edit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        );
    }
}

AddCropYear = connect(
    (state, ownProps) => ({
        allCrops: allCrops(state),
        allDryBeansVarieties: allDryBeansVarieties(state),
        allFarms: allFarms(state),
        allFields: allFields(state),
        farmCropYear: getFarmCropYear(state, ownProps),
        cropLookup: state.orm.Crops.itemsById,
        cropYears: getCropYears(state, ownProps)
    }),
    {
        ...FarmCropYear.actions,
        ...Fertilizer.actions,
        ...CropYear.actions,
        ...FieldActivity.actions,
        ...ActivityIrrigationWaterUseEvent.actions
    }
)(AddCropYear);

export default withStyles(styles)(withRouter(AddCropYear));
