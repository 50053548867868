import React, { useState } from "react";
import { Form } from "react-form";
import { Link, useParams } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import ProjectFacts from "./components/ProjectFacts";
import AppContainer from "../common/AppContainer";
import Checkbox from "../common/Checkbox";
import HelpLabel from "../common/HelpLabel";
import Snackbar from "../common/Snackbar";
import DataWizardDialog from "../field/DataWizardDialog";
import { Project } from "./models";
import { useSelector, useDispatch } from "react-redux";

const getProject = Project.selectByUrlId(),
    { ormProjectUpdate } = Project.actions;

const sx = {
    linkColor: { color: "#808080" },
    crumbColor: { color: "primary.main" },
    progress: {
        color: "#4CAF50",
        position: "absolute",
        marginTop: 10,
        marginLeft: -80
    }
};

export default function ProjectAdminManage() {
    const match = { params: useParams() },
        dispatch = useDispatch(),
        project = useSelector(state => getProject(state, { match })),
        [loading, setLoading] = useState(false),
        [snackbarOpen, setSnackbarOpen] = useState(false),
        [wizardOpen, setWizardOpen] = useState(false);

    async function handleSave({ has_sai, has_n_balance }) {
        setLoading(true);
        await dispatch(ormProjectUpdate({ id: project.id, has_sai, has_n_balance }));
        setLoading(false);
        setSnackbarOpen(true);
    }

    return (
        <AppContainer
            authenticated
            color="project"
            title={project.name}
            pageTitle={`Reports for ${project.name}`}
            crumbs={
                <div>
                    <Link style={sx.linkColor} to={"/projectadmin"}>
                        Project Admin Dashboard
                    </Link>
                    &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                    <Link style={sx.linkColor} to={"/projectadmin/" + project.id}>
                        {project.name}
                    </Link>
                    &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp;{" "}
                    <Box component="span" sx={sx.crumbColor}>
                        Manage
                    </Box>
                </div>
            }>
            <Snackbar
                success
                onClose={() => setSnackbarOpen(false)}
                open={snackbarOpen}
                section="project configuration"
                action="updated"
            />
            <DataWizardDialog
                path={`projectusersources/new?project_id=${project.id}`}
                title="Import Project Users"
                open={wizardOpen}
                handleClose={() => setWizardOpen(false)}
            />
            <Grid container spacing={24}>
                <ProjectFacts project={project} />
                <Grid item xs={12}>
                    <Typography variant="display3" gutterBottom>
                        Project Settings
                    </Typography>
                    <Form
                        dontValidateOnMount="true"
                        validateOnSubmit="true"
                        onSubmit={handleSave}
                        defaultValues={{ has_sai: project.has_sai, has_n_balance: project.has_n_balance }}>
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Checkbox
                                    field="has_sai"
                                    label={
                                        <HelpLabel
                                            inputLabel="Will this project utilize SAI FSA?"
                                            helpText={
                                                <>
                                                    Checking this box will set an alert for users in this project. The
                                                    alert will remind them to complete at least one FSA Survey.
                                                </>
                                            }
                                        />
                                    }
                                />
                                <Checkbox
                                    field="has_n_balance"
                                    label={
                                        <HelpLabel
                                            inputLabel="Will this project report on N Balance?"
                                            helpText={
                                                <>
                                                    Checking this box will:
                                                    <ul>
                                                        <li>
                                                            Alert users enrolled in this project that they are required
                                                            to provide additional input data to calculate an N balance
                                                            number for their crop years
                                                        </li>
                                                        <li>
                                                            Add additional inputs for cover crops and post harvest
                                                            needed to calculate an N balance number
                                                        </li>
                                                        <li>
                                                            Add a worksheet to the comprehensive data output file that
                                                            will include N-Balance data.
                                                        </li>
                                                    </ul>
                                                </>
                                            }
                                        />
                                    }
                                />
                                <Button type="submit" variant="raised" color="primary" disabled={loading}>
                                    Save Changes
                                </Button>

                                {loading && <CircularProgress size={24} style={sx.progress} />}
                            </form>
                        )}
                    </Form>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="display3" gutterBottom>
                        Tools
                    </Typography>
                    <Button
                        variant="raised"
                        type="submit"
                        color="primary"
                        style={{ marginRight: 32 }}
                        onClick={() => setWizardOpen(true)}>
                        New Grower Bulk Import Tool
                    </Button>
                </Grid>
            </Grid>
        </AppContainer>
    );
}
