import React, { Component } from "react";
import { Form } from "react-form";
import withRouter from "../../common/withRouter";
import { connect } from "react-redux";

import Button from "../../common/ButtonWrapper";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";

import withStyles from "@mui/styles/withStyles";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import {
    TillageClasses,
    PredominantRotationPractices,
    ResidueCovers,
    CROP_YEAR_COMPLETED,
    PreviousCrops,
    CoverCropSpecies,
    CoverCropEstablishTimings,
    CoverCropTypes,
    CoverCropLegumeTypes,
    CoverCropGrowth
} from "./models";
import RotationTable from "./RotationTable";
import CropRotationIcon from "../../common/icons/CropRotationIcon";
import SubquestionIcon from "../../common/icons/Subquestion";
import HelpLabel from "../../common/HelpLabel";
import RadioGroup from "../../common/RadioGroup";
import Select from "../../common/Select";
import Snackbar from "../../common/Snackbar";
import TextField from "../../common/TextField";
import { createSelector } from "../../common/orm";
import { RotationSystem } from "../../rotation/models";
import { YES_NO_OPTIONS, RICE, ALFALFA, MAKE_OPTIONS, LEGUME_COVER_CROP } from "../../../api/constants";
import { setValue, getValue } from "../../../api/utils";

const allRotationSystems = RotationSystem.selectAll();
const allTillageClasses = TillageClasses.selectAll();
const allResidueCovers = ResidueCovers.selectAll();
const allPreviousCrops = PreviousCrops.selectAll();
const allPredominantRotationPractices = PredominantRotationPractices.selectAll();
const allCoverCropSpecies = CoverCropSpecies.selectAll();
const allCoverCropEstablishTimings = CoverCropEstablishTimings.selectAll();
const allCoverCropTypes = CoverCropTypes.selectAll();
const allCoverCropLegumeTypes = CoverCropLegumeTypes.selectAll();
const allCoverCropGrowth = CoverCropGrowth.selectAll();

const getSelectedRotation = createSelector(
    (state, ownProps) => ownProps.cropYear.rotation,
    (session, systemId) => {
        if (session.RotationSystem.hasId(systemId)) {
            const rotation = session.RotationSystem.withId(systemId);
            return {
                ...rotation.ref,
                events: rotation.events.toRefArray()
            };
        } else {
            return { name: "No Template Selected" };
        }
    }
);

const styles = theme => ({
    button: {
        marginRight: theme.spacing(1)
    },
    rightAlign: {
        textAlign: "right"
    },
    iconSize: {
        flexShrink: 0,
        height: "1em",
        width: "1em"
    },
    cropColor: {
        color: "#7ac143"
    },
    subQuestion: {
        marginRight: theme.spacing(1)
    },
    invalidText: {
        color: "#f44336"
    },
    errorList: {
        backgroundColor: "#efe8e5",
        color: "#f44336"
    }
});

class ApplyRotationSystem extends Component {
    state = {
        snackbarOpen: true
    };

    constructor(props, context) {
        super(props, context);
        this.applyRotation = this.applyRotation.bind(this);
        this.isCompleted = this.isCompleted.bind(this);
    }

    updateCropYear(values, fromStepper) {
        const { id } = this.props.cropYear;

        if (Number.isInteger(fromStepper)) {
            values.activeStep = fromStepper;
        }

        var isCompleted = this.isCompleted(values, this.props.cropYear, false);

        const c = getValue(values, "extrainfo.completed");
        const m = getValue(values, "extrainfo.missing");
        const r = getValue(values, "extrainfo.required");
        setValue(values, "metrics", null);

        //#666 Set crop year to provisional if missing
        if ((c && c[1] === false) || (c && c[2] === false) || isCompleted[0] !== 0) setValue(values, "is_final", false);

        this.props.ormCropYearUpdate({
            id: id,
            ...values,
            extrainfo: {
                completed: {
                    0: isCompleted[0] === 0,
                    1: c ? c[1] : undefined,
                    2: c ? c[2] : undefined
                },
                missing: {
                    0: isCompleted[0],
                    1: m ? m[1] : undefined,
                    2: m ? m[2] : undefined
                },
                required: {
                    0: isCompleted[1],
                    1: r ? r[1] : undefined,
                    2: r ? r[2] : undefined
                }
            }
        });

        this.props.handleUnsavedFields(false);

        if (!Number.isInteger(fromStepper)) {
            if (this.state.draftClick) {
                const { fieldId } = this.props;
                this.props.history.push("/field/" + fieldId);
            } else {
                this.props.handleNext();
            }
        }
    }

    // Form Error Validator
    isCompleted = (values, cY, hardRequire) => {
        const { cropYear, selectedRotation } = this.props;
        var numberRequired = 0; // Keep track of the actual number of required fields
        const isRequired = path => {
            numberRequired++;
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };
        const validateNumber = path => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            const number = parseFloat(val);
            if (number < 0) {
                setValue(valObj, path, "Invalid");
                return;
            }
            setValue(valObj, path, null);
        };
        var valObj = {};

        isRequired("biodiversity.rotation_practice");
        isRequired("rotation");
        if (values.rotation) {
            if (!selectedRotation.is_valid) {
                valObj.rotation = "Selected rotation is invalid.";
            } else {
                const expected_crops = new Set(),
                    actual_crops = new Set();
                let foundCrop = false;
                cropYear.crop_crcrops.forEach(crinfo => {
                    expected_crops.add(crinfo.name);
                });
                selectedRotation.events.forEach(e => {
                    if (e.crop && e.crop.name) {
                        actual_crops.add(e.crop.name);
                        if (expected_crops.has(e.crop.name)) {
                            foundCrop = true;
                        }
                    }
                });
                if (!foundCrop) {
                    const quote = val => `"${val}"`;
                    const actual = Array.from(actual_crops).map(quote).join(" and "),
                        expected = Array.from(expected_crops).map(quote).join(" or ");
                    valObj.rotation = `Selected rotation does not include the current crop.  (Rotation contains ${actual} but not ${expected})`;
                }
            }
        }
        isRequired("greenhouse.residue_burning");
        isRequired("biodiversity.cover_crop");
        isRequired("biodiversity.tillage_class");

        const residueburned = getValue(values, "greenhouse.residue_burning");
        if (residueburned === "true" || residueburned === true) {
            isRequired("greenhouse.previous_crop_id");
            const previous_crop = getValue(values, "greenhouse.previous_crop_id");
            if (previous_crop !== "999") validateNumber("greenhouse.yield_previous");
        }

        const covercrop = getValue(values, "biodiversity.cover_crop");
        if (covercrop === "true" || covercrop === true) {
            isRequired("waterquality.cover_crop_species");
            isRequired("waterquality.cover_crop_establish_timing");
            if (values.project && values.project.project_has_n_balance) {
                isRequired("waterquality.cover_crop_type");
                if (getValue(values, "waterquality.cover_crop_type") === LEGUME_COVER_CROP) {
                    isRequired("waterquality.cover_crop_legume_type");
                }
                isRequired("waterquality.cover_crop_incorporated");
                isRequired("waterquality.cover_crop_growth");
            }
        }

        if (cY.crop === RICE || cY.crop === ALFALFA) {
            isRequired("energyuse.previous_residue");
        }

        if (hardRequire) {
            // hardRequire = actual validation
            // FIXME: v2.X of react-forms has a submitting attribute on the formapi, but doesn't appear to be functional
            // V3.X seems to be a lot of work to upgrade
            // We are simulating a custom state (submitClicked) to know if its actually submiting
            // If it is submitting ignore the validator, submit, and move on
            if (this.state.submitClicked) {
                Object.keys(valObj).forEach(k => (valObj[k] = null)); // Set all values to null to clear validation
            }
            return valObj;
        }

        // If we are doing the final save of the form track how many fields are missing
        var missing = 0;
        Object.keys(valObj).forEach(function (key) {
            if (valObj[key] !== null && typeof valObj[key] === "object") {
                // Also check child objects
                Object.keys(valObj[key]).forEach(function (childKey) {
                    if (valObj[key][childKey] !== null) missing++;
                });
            } else if (valObj[key] !== null) missing++;
        });

        return [missing, numberRequired];
    };

    applyRotation(rotationId) {
        const { id } = this.props.cropYear;
        this.props.ormCropYearUpdateLocalOnly({
            id: id,
            rotation: rotationId
        });
        this.form.setValue("rotation", rotationId);
    }

    residueChange = () => {
        this.form.setValue("greenhouse.previous_crop_id", null);
        this.form.setValue("greenhouse.yield_previous", null);
    };

    coverChange = () => {
        this.form.setValue("waterquality.cover_crop_species", null);
        this.form.setValue("waterquality.cover_crop_establish_timing", null);
        this.form.setValue("waterquality.cover_crop_type", null);
        this.form.setValue("waterquality.cover_crop_legume_type", null);
        this.form.setValue("waterquality.cover_crop_incorporated", null);
        this.form.setValue("waterquality.cover_crop_growth", null);
    };

    rotationSubmit = () => {
        const { handleUnsavedFields } = this.props;
        const { id } = this.props.cropYear;
        this.setState({ submitClicked: true });
        const f = this;
        setTimeout(function () {
            f.form.submitForm(0);
            handleUnsavedFields(false);
            f.props.history.push("/rotation/library/cropyear/" + id);
        }, 50);
    };

    componentDidMount() {
        const { handleUnsavedFields } = this.props;
        this.props.onRef(this);
        window.scrollTo(0, 0);
        // FIXME: react-forms calls formDidUpdate when (if) validation is ran right away
        // making it appear there is a edited field even if there isn't
        // Reset fields to false to not require validation when mounted
        setTimeout(function () {
            handleUnsavedFields(false);
        }, 100);
    }
    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    render() {
        const {
            classes,
            rotationSystems,
            selectedRotation,
            cropYear,
            fieldName,
            tillageClasses,
            predominantRotationPractices,
            residueCovers,
            handleUnsavedFields,
            previousCrops,
            coverCropSpecies,
            coverCropEstablishTimings,
            coverCropTypes,
            coverCropLegumeTypes,
            coverCropGrowth
        } = this.props;

        var predominantRotationPracticesOpt = predominantRotationPractices.filter(wH => wH.crop_id === cropYear.crop);
        if (predominantRotationPracticesOpt.length === 0)
            predominantRotationPracticesOpt = predominantRotationPractices.filter(wH => !wH.crop_id);

        // Only run validation if users have been here before
        const miss = getValue(cropYear, "extrainfo.missing");
        const isMissing = CROP_YEAR_COMPLETED(cropYear, 0, true);

        const getFormValue = name => (this.form ? this.form.getValue(name) : getValue(cropYear, name)),
            isTrue = name => {
                const value = getFormValue(name);
                return value === true || value === "true";
            };

        const residueburned = isTrue("greenhouse.residue_burning"),
            covercrop = isTrue("biodiversity.cover_crop"),
            nbalance = cropYear.project && cropYear.project.project_has_n_balance,
            legume = getFormValue("waterquality.cover_crop_type") === LEGUME_COVER_CROP;

        var previous_unit = "";
        const previous_crop = getFormValue("greenhouse.previous_crop_id");
        if (previous_crop && previous_crop !== "999") {
            previous_unit = previousCrops.filter(c => c.id === previous_crop)[0].yield_unit;
        }

        return (
            <Form
                getApi={el => (this.form = el)}
                key={cropYear.id}
                dontValidateOnMount={!isMissing ? false : true}
                validateOnSubmit={!isMissing ? false : true}
                formDidUpdate={() => handleUnsavedFields(true)}
                defaultValues={cropYear.formData}
                validateError={values => this.isCompleted(values, cropYear, true)}
                onSubmit={(values, fromStepper) => this.updateCropYear(values, fromStepper)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <input type="hidden" field="rotation" />
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="display3">Rotation and Residue Practices</Typography>
                            </Grid>
                            <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
                                <RadioGroup
                                    field="greenhouse.residue_burning"
                                    name="residueburned"
                                    options={YES_NO_OPTIONS}
                                    alignment={true}
                                    eventHandle={this.residueChange}
                                    label={
                                        <HelpLabel
                                            inputLabel="Was the previous crop residue burned?"
                                            helpText="Burning refers to the deliberate and controlled use of prescribed fire to remove >50% of post-harvest crop residue."
                                        />
                                    }
                                />
                                {residueburned && (
                                    <Grid container wrap="nowrap">
                                        <Grid xs={1} className={classes.subQuestion}>
                                            <SubquestionIcon />
                                        </Grid>
                                        <Grid xs={11}>
                                            <Select
                                                field="greenhouse.previous_crop_id"
                                                label="What was the previous crop that you burned?"
                                                options={MAKE_OPTIONS(previousCrops)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {residueburned && previous_crop !== "999" && (
                                    <Grid container wrap="nowrap">
                                        <Grid xs={1} className={classes.subQuestion}>
                                            <SubquestionIcon />
                                        </Grid>
                                        <Grid xs={11}>
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="greenhouse.yield_previous"
                                                label="What was the yield in the previous year?"
                                                units={previous_unit}
                                                fullWidth
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {cropYear.crop === RICE && (
                                    <Select
                                        field="energyuse.previous_residue"
                                        label={
                                            <HelpLabel
                                                inputLabel="How much residue remained on the field from the previous year when you planted your rice crop?"
                                                helpText="Residue refers to the living or dead crop residue remaining on a field at planting of a crop to be evaluated. Low residue ≤ 30% High residue > 30%. This impacts the methane emissions."
                                            />
                                        }
                                        options={MAKE_OPTIONS(residueCovers)}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                                {cropYear.crop === ALFALFA && (
                                    <Select
                                        field="energyuse.previous_residue"
                                        label={
                                            <HelpLabel
                                                inputLabel="How much residue remained on the field from the previous year when you planted your alfalfa crop?"
                                                helpText="Residue refers to the living or dead crop residue remaining on a field at planting of a crop to be evaluated. Low residue ≤ 30% High residue > 30%."
                                            />
                                        }
                                        options={MAKE_OPTIONS(residueCovers)}
                                        fullWidth
                                        margin="normal"
                                    />
                                )}
                                <Select
                                    field="biodiversity.rotation_practice"
                                    label="How would you classify the crop rotation in which this primary crop is grown?"
                                    help={
                                        <div>
                                            Crop to fallow: commodity crop followed by a year where the land is left
                                            unplanted
                                            <br />
                                            <br />
                                            Continuous single crop: planting the same commodity crop for at least three
                                            consecutive years
                                            <br />
                                            <br />
                                            Crop rotation: this crop is grown in rotation with at least one other
                                            commodity crop
                                        </div>
                                    }
                                    options={MAKE_OPTIONS(predominantRotationPracticesOpt)}
                                    fullWidth
                                    margin="normal"
                                />
                                <RadioGroup
                                    field="biodiversity.cover_crop"
                                    name="covercrop"
                                    options={YES_NO_OPTIONS}
                                    alignment={true}
                                    eventHandle={this.coverChange}
                                    label={
                                        <HelpLabel
                                            inputLabel="Do you use a cover crop (planted or naturally established)?"
                                            helpText="In this case a cover crop is a crop grown for the protection and enrichment of the soil and/or to control pests/weeds. The cover crop can be grazed or harvested as hay or silage; however, if it is harvested as grain or seed it is not considered a cover crop."
                                        />
                                    }
                                />
                                {covercrop && (
                                    <>
                                        <Grid container wrap="nowrap">
                                            <Grid xs={1} className={classes.subQuestion}>
                                                <SubquestionIcon />
                                            </Grid>
                                            <Grid xs={11}>
                                                <Select
                                                    field="waterquality.cover_crop_species"
                                                    label="How would you classify the dominant species of your cover crop?"
                                                    help="Will established cover crops be frost-killed (i.e. winter kill) or overwinter and continue growing after a frost (i.e. winter hardy)?"
                                                    options={MAKE_OPTIONS(coverCropSpecies)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container wrap="nowrap">
                                            <Grid xs={1} className={classes.subQuestion}>
                                                <SubquestionIcon />
                                            </Grid>
                                            <Grid xs={11}>
                                                <Select
                                                    field="waterquality.cover_crop_establish_timing"
                                                    label="Approximately how many days before the frost date was the cover crop established?"
                                                    help="This is an indicator used to estimate cover crop growth."
                                                    options={MAKE_OPTIONS(coverCropEstablishTimings)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                {covercrop && nbalance && (
                                    <>
                                        <Grid container wrap="nowrap">
                                            <Grid xs={1} className={classes.subQuestion}>
                                                <SubquestionIcon />
                                            </Grid>
                                            <Grid xs={11}>
                                                <Select
                                                    field="waterquality.cover_crop_type"
                                                    label="What is the dominant cover crop type?"
                                                    help={undefined}
                                                    options={MAKE_OPTIONS(coverCropTypes)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>
                                        {legume && (
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} className={classes.subQuestion}></Grid>
                                                <Grid xs={1} className={classes.subQuestion}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={10}>
                                                    <Select
                                                        field="waterquality.cover_crop_legume_type"
                                                        label="What is the dominant legume species?"
                                                        help={undefined}
                                                        options={MAKE_OPTIONS(coverCropLegumeTypes)}
                                                        fullWidth
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        <Grid container wrap="nowrap">
                                            <Grid xs={1} className={classes.subQuestion}>
                                                <SubquestionIcon />
                                            </Grid>
                                            <Grid xs={11}>
                                                <RadioGroup
                                                    field="waterquality.cover_crop_incorporated"
                                                    name="covercrop_incorporated"
                                                    options={YES_NO_OPTIONS}
                                                    alignment={true}
                                                    label="Did you incorporate the cover crop into the soil with a tillage operation?"
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container wrap="nowrap">
                                            <Grid xs={1} className={classes.subQuestion}>
                                                <SubquestionIcon />
                                            </Grid>
                                            <Grid xs={11}>
                                                <Select
                                                    field="waterquality.cover_crop_growth"
                                                    label="What was the approximate top growth of the cover crop before its termination?"
                                                    help={undefined}
                                                    options={MAKE_OPTIONS(coverCropGrowth)}
                                                    fullWidth
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                                <Select
                                    field="biodiversity.tillage_class"
                                    label="How would you classify the predominant tillage regime used across the entire crop rotation?"
                                    help={
                                        <div>
                                            Conventional or intensive tillage: Less than 30% of the surface residue
                                            remains on the soil surface each year of the rotation.
                                            <br />
                                            <br />
                                            Reduced tillage: At least one year of the rotation leaves more than 30% of
                                            the crop residue remaining on the soil surface.
                                            <br />
                                            <br />
                                            No-till, strip-till, or conservation tillage: More than 30% of the surface
                                            residue remains on the soil surface every year of the rotation.
                                        </div>
                                    }
                                    options={MAKE_OPTIONS(tillageClasses)}
                                    fullWidth
                                    margin="normal"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="display3" gutterBottom>
                                    Select a Crop Rotation System
                                </Typography>
                                <Typography gutterBottom>
                                    Please select or build a representative crop rotation system for your crop year. To
                                    apply a system, either select a template from the 'Crop Rotation Library' OR click
                                    on 'Add New System' below.
                                </Typography>
                                <Typography>
                                    This rotation is used to run the models that estimates soil erosion, the soil
                                    conditioning index, and the chosen activities are used to estimate energy and GHG
                                    emissions and some aspects of the water quality metric - please make it as accurate
                                    as possible.
                                </Typography>
                            </Grid>
                            {formApi.errors.rotation && (
                                <Grid item xs={12} className={classes.errorList}>
                                    <Typography>
                                        <strong>Validation Errors</strong>
                                    </Typography>
                                    <ul>
                                        <li>{formApi.errors.rotation}</li>
                                    </ul>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography variant="title" gutterBottom className={classes.cropColor}>
                                    <CropRotationIcon className={classes.iconSize} />
                                    &nbsp;&nbsp;&nbsp;Crop Rotation Library
                                </Typography>
                                <RotationTable
                                    applyRotation={this.applyRotation}
                                    selectedCropRotation={selectedRotation.id}
                                    rotationSystems={rotationSystems}
                                    rotationSubmit={this.rotationSubmit}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.rightAlign}>
                                <Button
                                    variant="raised"
                                    type="submit"
                                    onClick={() => this.setState({ draftClick: true, submitClicked: true })}
                                    className={classes.button}>
                                    Save Draft and Return to {fieldName} Dashboard
                                </Button>
                                <Button
                                    variant="raised"
                                    type="submit"
                                    color="primary"
                                    onClick={() => this.setState({ draftClick: false, submitClicked: true })}
                                    className={classes.button}>
                                    Save and Proceed to Management Data <ChevronRightIcon />
                                </Button>
                            </Grid>
                            {!isMissing && (
                                <Snackbar
                                    error
                                    count={miss[0]}
                                    onClose={this.handleSnackbarClose}
                                    open={this.state.snackbarOpen}
                                />
                            )}
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

ApplyRotationSystem = connect(
    (state, ownProps) => ({
        rotationSystems: allRotationSystems(state),
        selectedRotation: getSelectedRotation(state, ownProps),
        tillageClasses: allTillageClasses(state),
        predominantRotationPractices: allPredominantRotationPractices(state),
        residueCovers: allResidueCovers(state),
        previousCrops: allPreviousCrops(state),
        coverCropSpecies: allCoverCropSpecies(state),
        coverCropEstablishTimings: allCoverCropEstablishTimings(state),
        coverCropTypes: allCoverCropTypes(state),
        coverCropLegumeTypes: allCoverCropLegumeTypes(state),
        coverCropGrowth: allCoverCropGrowth(state)
    }),
    {
        ...RotationSystem.actions
    }
)(ApplyRotationSystem);

export default withStyles(styles)(withRouter(ApplyRotationSystem));
