import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "../../../common/ButtonWrapper";
import Grid from "../../../common/GridWrapper";
import Typography from "../../../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";

import {
    DryingSystems,
    FuelTypes,
    AbandonmentReasons,
    EnergySources,
    AlfalfaHarvestTypes,
    CottonMoisture,
    AlfalfaDryingSystems,
    RiceYieldMethods
} from "./models";
import HelpLabel from "../../../common/HelpLabel";
import RadioGroup from "../../../common/RadioGroup";
import Select from "../../../common/Select";
import Snackbar from "../../../common/Snackbar";
import Switch from "../../../common/Switch";
import TextField from "../../../common/TextField";
import WarningDialog from "../../../common/WarningDialog";
import SubquestionIcon from "../../../common/icons/Subquestion";
import {
    YES_NO_OPTIONS,
    RICE,
    SUGAR_BEETS,
    ALFALFA,
    COTTON,
    FUEL,
    ELECTRICITY,
    NO_DRYING,
    NO_DRYING_ALFALFA,
    WET_GREEN,
    MAKE_OPTIONS,
    BARLEY,
    CORN_GRAIN,
    CORN_SILAGE,
    PEANUTS,
    POTATOES,
    SORGHUM,
    SOYBEANS,
    WHEAT_DURUM,
    WHEAT_SPRING,
    WHEAT_WINTER
} from "../../../../api/constants";
import { setValue, getValue } from "../../../../api/utils";

const allDryingSystems = DryingSystems.selectAll();
const allFuelTypes = FuelTypes.selectAll();
const allAbandonmentReasons = AbandonmentReasons.selectAll();
const allEnergySources = EnergySources.selectAll();
const allAlfalfaHarvestTypes = AlfalfaHarvestTypes.selectAll();
const allCottonMoisture = CottonMoisture.selectAll();
const allAlfalfaDryingSystems = AlfalfaDryingSystems.selectAll();
const allRiceYieldMethods = RiceYieldMethods.selectAll();

const styles = theme => ({
    button: {
        marginRight: theme.spacing(1),
        float: "right"
    },
    divider: {
        margin: "8px 0 8px 0"
    },
    subQuestion: {
        marginRight: theme.spacing(2),
        marginTop: theme.spacing(1)
    },
    gutterTop: {
        marginTop: ".6em"
    },
    linkColor: {
        color: "#808080"
    }
});

class Harvest extends Component {
    state = {
        diffAcres: "",
        deleteDialogOpen: false,
        deleteDialogText: null,
        deleteDialogConfirmAction: null,
        snackbarOpen: false,
        fuelUnits: ""
    };

    updateFieldActivity(values) {
        const { id } = this.props.fieldActivity;
        var isCompleted = this.errorValidator(values, this.props.cropYear, false);

        this.props.ormFieldActivityUpdate({
            id: id,
            ...values,
            extrainfo: {
                completed: isCompleted[0] === 0,
                missing: isCompleted[0],
                required: isCompleted[1]
            }
        });
        this.props.ormCropYearUpdateLocalOnly({
            id: this.props.cropYear.id,
            metrics: null
        });
        this.props.handleUnsavedFields(false);

        // Reapply required label to any field not yet filled in by adding a nonexistant value
        this.setState({ submitClicked: false, snackbarOpen: true });
        this.form.addValue("foo", "bar");
    }

    update_harvest = (value, input) => {
        if (input === "harvest.landuse.abandonment") {
            this.form.setValue("harvest.landuse.harvested_area", null);
            this.form.setValue("harvest.landuse.abandonment_reason", null);
        } else if (input === "harvest.landuse.yield_method") {
            this.form.setValue("harvest.landuse.harvest_moisture", null);
        } else if (
            (input === "harvest.energyuse.alfalfa_drying_system" && NO_DRYING_ALFALFA.includes(value)) ||
            (input === "harvest.energyuse.drying_system" && NO_DRYING.includes(value))
        ) {
            this.form.setValue("harvest.energyuse.moisture_removed", null);
            this.form.setValue("harvest.energyuse.drying_fuel_amount", null);
            this.form.setValue("harvest.energyuse.drying_fuel_id", null);
            this.form.setValue("harvest.energyuse.drying_electricity_amount", null);
            this.form.setValue("harvest.energyuse.drying_energy_source", null);
            this.form.setValue("harvest.energyuse.drying_use_actual", null);
        } else if (input === "harvest.energyuse.drying_use_actual") {
            this.form.setValue("harvest.energyuse.moisture_removed", null);
            this.form.setValue("harvest.energyuse.drying_fuel_amount", null);
            this.form.setValue("harvest.energyuse.drying_electricity_amount", null);
        } else if (input === "harvest.energyuse.drying_energy_source") {
            this.form.setValue("harvest.energyuse.drying_fuel_amount", null);
            this.form.setValue("harvest.energyuse.drying_fuel_id", null);
            this.form.setValue("harvest.energyuse.drying_electricity_amount", null);
        } else if (input === "harvest.energyuse.transportation_distance") {
            this.form.setValue("harvest.energyuse.backhauling", false);
            this.form.setValue("harvest.energyuse.transportation_fuel_id", null);
        }
    };

    handleAcresChange = value => {
        const { fieldObject } = this.props.cropYear;
        if (parseFloat(value) > 0) {
            let addAcres = (parseFloat(value) - parseFloat(fieldObject.size)).toFixed(2);
            this.setState({
                diffAcres: addAcres
            });
        } else {
            this.setState({
                diffAcres: ""
            });
        }
    };

    getFuelUnits = value => {
        const { fuelTypes } = this.props;
        const app = this;
        const formValues = this.form.values;

        var units = null;
        fuelTypes.forEach(function (f) {
            if (f.id === value) {
                units = f.default_units;
                app.setState({ fuelUnits: f.default_units });
            }
        });

        var current_value = getValue(formValues, "harvest.energyuse.drying_fuel_amount");

        if (current_value) {
            //  need to update form value with units manually
            var new_value = current_value.split(" ")[0] + " " + units;
            setValue(formValues, "harvest.energyuse.drying_fuel_amount", new_value);
            this.form.setValue("harvest.energyuse.drying_fuel_amount", new_value);
        }
    };

    setDryingFuelAmount = value => {
        const formValues = this.form.values;
        if (value) {
            setValue(formValues, "harvest.energyuse.drying_fuel_amount", value);
            this.form.setValue("harvest.energyuse.drying_fuel_amount", value);
        } else {
            setValue(formValues, "harvest.energyuse.drying_fuel_amount", null);
            this.form.setValue("harvest.energyuse.drying_fuel_amount", null);
        }
    };

    componentDidMount() {
        const { fieldActivity, handleUnsavedFields } = this.props;
        this.props.onRef(this);
        const harvested_area = getValue(fieldActivity, "harvest.landuse.harvested_area");
        if (harvested_area) {
            this.handleAcresChange(harvested_area);
        }
        setTimeout(function () {
            handleUnsavedFields(false);
        }, 100);

        const fuelId = getValue(fieldActivity, "harvest.energyuse.drying_fuel_id");
        this.getFuelUnits(fuelId);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    deleteFieldActivity = field => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: field,
            deleteDialogText: "Are you sure you wish to permanently delete this harvest?"
        });
    };

    warningValidator = (values, cY) => {
        const validateYield = (path, path2) => {
            var flag = 10000;
            var max = 10000;
            switch (cY.crop) {
                case ALFALFA:
                    flag = 10;
                    max = 20;
                    break;
                case BARLEY:
                    flag = 150;
                    max = 300;
                    break;
                case CORN_GRAIN:
                    flag = 325;
                    max = 500;
                    break;
                case CORN_SILAGE:
                    flag = 35;
                    max = 50;
                    break;
                case COTTON:
                    flag = 2200;
                    max = 5000;
                    break;
                case PEANUTS:
                    flag = 7000;
                    max = 10000;
                    break;
                case POTATOES:
                    flag = 800;
                    max = 1600;
                    break;
                case RICE:
                    flag = 100;
                    max = 200;
                    break;
                case SORGHUM:
                    flag = 175;
                    max = 300;
                    break;
                case SOYBEANS:
                    flag = 90;
                    max = 180;
                    break;
                case SUGAR_BEETS:
                    flag = 50;
                    max = 100;
                    break;
                case WHEAT_DURUM:
                case WHEAT_SPRING:
                case WHEAT_WINTER:
                    flag = 140;
                    max = 300;
                    break;
                default:
                    break;
            }

            let val = getValue(values, path);
            const number = parseFloat(val);

            if (number > flag && number <= max) {
                setValue(
                    valObj,
                    path,
                    "Typically the yield does not exceed " +
                        flag.toString() +
                        ". Please validate your amount is correct."
                );
                return;
            }
            if (path2) {
                let val2 = getValue(values, path2);
                const number2 = parseFloat(val2);
                if (number > number2) {
                    setValue(
                        valObj,
                        path,
                        "Note: Typically, the Estimated Non-Irrigated Yield does not exceed the Irrigated Yield. If this is correct, you may proceed. If you are unsure about what is being asked, click on the Question Mark for additional help."
                    );
                    return;
                }
            }
            setValue(valObj, path, null);
        };
        const validatePeanutMoisture = path => {
            var min = 9.3;
            var flagmin = 10;
            var flagmax = 27;
            var max = 31;

            const warning = `Peanut moisture values typically range from ${flagmin} to ${flagmax}%.`;

            let val = getValue(values, path);
            const number = parseFloat(val);

            if (number < flagmin && number >= min) {
                setValue(valObj, path, warning);
                return;
            }

            if (number > flagmax && number <= max) {
                setValue(valObj, path, warning);
                return;
            }
            setValue(valObj, path, null);
        };
        var valObj = {};

        validateYield("harvest.landuse.yield");

        const is_irrigated = getValue(cY, "is_irrigated");
        if (cY.crop !== RICE && (is_irrigated === "true" || is_irrigated === true)) {
            validateYield("harvest.wateruse.est_non_irrigated_yield", "harvest.landuse.yield");
        }
        if (cY.crop === PEANUTS) {
            validatePeanutMoisture("harvest.energyuse.peanuts_initial_moisture");
        }

        return valObj;
    };

    errorValidator = (values, cY, hardRequire) => {
        var numberRequired = 0; // Keep track of the actual number of required fields
        const isRequired = path => {
            numberRequired++;
            let val = getValue(values, path);
            setValue(valObj, path, val || val === false ? null : "Required");
        };
        const validateNumber = path => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            const number = parseFloat(val);
            if (number < 0) {
                setValue(valObj, path, "Invalid");
                return;
            }
            setValue(valObj, path, null);
        };
        const validateHarvest = path => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            const number = parseFloat(val);
            if (number < 0) {
                setValue(valObj, path, "Invalid");
                return;
            }
            let planted = parseFloat(cY.fieldObject.size.split(" ")[0]);
            if (number > planted) {
                setValue(valObj, path, "Must be less than planted acres");
                return;
            }

            setValue(valObj, path, null);
        };
        const validateYield = (path, checkMin) => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            var min = 0;
            var max = 100;
            switch (cY.crop) {
                case ALFALFA:
                    min = 0.1;
                    max = 20;
                    break;
                case BARLEY:
                    min = 15;
                    max = 300;
                    break;
                case CORN_GRAIN:
                    min = 10;
                    max = 500;
                    break;
                case CORN_SILAGE:
                    min = 2;
                    max = 50;
                    break;
                case COTTON:
                    min = 90;
                    max = 5000;
                    break;
                case PEANUTS:
                    min = 250;
                    max = 10000;
                    break;
                case POTATOES:
                    min = 50;
                    max = 1600;
                    break;
                case RICE:
                    min = 20;
                    max = 200;
                    break;
                case SORGHUM:
                    min = 20;
                    max = 300;
                    break;
                case SOYBEANS:
                    min = 10;
                    max = 180;
                    break;
                case SUGAR_BEETS:
                    min = 10;
                    max = 100;
                    break;
                case WHEAT_DURUM:
                case WHEAT_SPRING:
                case WHEAT_WINTER:
                    min = 10;
                    max = 300;
                    break;
                default:
                    break;
            }
            const number = parseFloat(val);
            if (checkMin) {
                if (number < min || number > max) {
                    setValue(valObj, path, "Must be between " + min.toString() + "-" + max.toString());
                    return;
                }
            } else {
                if (number > max) {
                    setValue(valObj, path, "Must be under " + max.toString());
                    return;
                }
            }
            setValue(valObj, path, null);
        };
        const validatePercent = (path, min = 0, max = 100) => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            const number = parseFloat(val);
            if (number < min || number > max) {
                setValue(valObj, path, "Must be between " + min.toString() + "-" + max.toString());
                return;
            }
            setValue(valObj, path, null);
        };
        const validateMoisture = path => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            const number = parseFloat(val);
            if (number < 0.5 || number > 20) {
                setValue(valObj, path, "Must be between 0.5-20");
                return;
            }
            if (number % 0.5 !== 0) {
                setValue(valObj, path, "Round to the nearest half percent");
                return;
            }
            setValue(valObj, path, null);
        };
        const validatePeanutMoisture = path => {
            numberRequired++;
            let val = getValue(values, path);
            if (!val) {
                setValue(valObj, path, "Required");
                return;
            }
            const number = parseFloat(val);
            if (number < 9.3 || number > 31) {
                setValue(valObj, path, "Peanut moisture values cannot be lower than 9.3 or higher than 31%");
                return;
            }
            if ((number * 10) % 1 !== 0) {
                setValue(valObj, path, "Round to the nearest 1/10 percent");
                return;
            }
            setValue(valObj, path, null);
        };
        const validateNumberNotRequired = path => {
            let val = getValue(values, path);
            const number = parseFloat(val);
            if (number < 0) {
                setValue(valObj, path, "Invalid");
                return;
            }
            setValue(valObj, path, null);
        };
        var valObj = {};

        // Temp get state
        var isCA = false;
        if (cY.fieldObject.location) {
            if (cY.fieldObject.location.slice(-2) === "CA") isCA = true;
        }

        isRequired("harvest.landuse.abandonment");

        const unharvested = getValue(values, "harvest.landuse.abandonment");
        if (unharvested === "true" || unharvested === true) {
            validateHarvest("harvest.landuse.harvested_area");
            isRequired("harvest.landuse.abandonment_reason");
        }

        validateNumber("harvest.energyuse.transportation_distance");

        const transportation_distance = getValue(values, "harvest.energyuse.transportation_distance");
        if (parseFloat(transportation_distance) > 0) {
            isRequired("harvest.energyuse.backhauling");
            isRequired("harvest.energyuse.transportation_fuel_id");
        }
        validateYield("harvest.landuse.yield", true);

        const is_irrigated = getValue(cY, "is_irrigated");
        if (cY.crop !== RICE && (is_irrigated === "true" || is_irrigated === true)) {
            validateYield("harvest.wateruse.est_non_irrigated_yield");
        }

        if (cY.crop === RICE) {
            isRequired("harvest.landuse.yield_method");
            const drywet = getValue(values, "harvest.landuse.yield_method");
            if (drywet === WET_GREEN) {
                validatePercent("harvest.landuse.harvest_moisture");
            }
        }
        if (cY.crop === ALFALFA) {
            validatePercent("harvest.landuse.harvest_moisture");
        }

        if (cY.crop === SUGAR_BEETS) {
            validatePercent("harvest.landuse.percent_sugar", 9, 30);
        }

        if (cY.crop === RICE && !isCA) {
            isRequired("harvest.greenhouse.ratoon_cropping");
        }

        if (cY.crop === ALFALFA) {
            isRequired("harvest.energyuse.harvest_type");
            isRequired("harvest.energyuse.alfalfa_drying_system");
        } else if (
            cY.crop !== COTTON &&
            cY.crop !== PEANUTS &&
            cY.crop !== POTATOES &&
            cY.crop !== SUGAR_BEETS &&
            cY.crop !== CORN_SILAGE
        ) {
            isRequired("harvest.energyuse.drying_system");
        }

        const dryingsystem = getValue(values, "harvest.energyuse.drying_system");
        const alfalfadryingsystem = getValue(values, "harvest.energyuse.alfalfa_drying_system");
        const drying_use_actual = getValue(values, "harvest.energyuse.drying_use_actual");
        if (
            (cY.crop === ALFALFA && !NO_DRYING_ALFALFA.includes(alfalfadryingsystem)) ||
            (cY.crop !== ALFALFA && !NO_DRYING.includes(dryingsystem))
        ) {
            if (
                cY.crop !== COTTON &&
                cY.crop !== ALFALFA &&
                cY.crop !== PEANUTS &&
                cY.crop !== POTATOES &&
                cY.crop !== SUGAR_BEETS &&
                cY.crop !== CORN_SILAGE
            ) {
                isRequired("harvest.energyuse.drying_energy_source");
            }

            const energysource = getValue(values, "harvest.energyuse.drying_energy_source");
            if (energysource === FUEL) {
                isRequired("harvest.energyuse.drying_fuel_id");
                if (drying_use_actual === true) {
                    validateNumberNotRequired("harvest.energyuse.drying_fuel_amount");
                }
            }
            if (ELECTRICITY.includes(energysource) && drying_use_actual === true) {
                validateNumberNotRequired("harvest.energyuse.drying_electricity_amount");
            }

            if (
                cY.crop !== COTTON &&
                cY.crop !== PEANUTS &&
                cY.crop !== POTATOES &&
                cY.crop !== SUGAR_BEETS &&
                cY.crop !== CORN_SILAGE &&
                drying_use_actual !== true
            ) {
                validateMoisture("harvest.energyuse.moisture_removed");
            }
        }

        if (cY.crop === COTTON) {
            isRequired("harvest.energyuse.cotton_target_moisture");
        }

        if (cY.crop === PEANUTS) {
            validatePeanutMoisture("harvest.energyuse.peanuts_initial_moisture");
        }

        if (cY.project && cY.project.project_has_n_balance) {
            if (cY.crop === CORN_GRAIN || cY.crop === CORN_SILAGE) {
                isRequired("harvest.waterquality.corn_stover_baled");
            }
        }

        if (hardRequire) {
            // hardRequire = actual validation
            // FIXME: v2.X of react-forms has a submitting attribute on the formapi, but doesn't appear to be functional
            // V3.X seems to be a lot of work to upgrade
            // We are simulating a custom state (submitClicked) to know if its actually submiting
            // If it is submitting ignore the validator, submit, and move on
            if (this.state.submitClicked) {
                Object.keys(valObj).forEach(function (key) {
                    if (valObj[key] !== null && typeof valObj[key] === "object") {
                        // Also check child objects
                        Object.keys(valObj[key]).forEach(function (childKey) {
                            if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                                Object.keys(valObj[key][childKey]).forEach(function (childKey2) {
                                    valObj[key][childKey][childKey2] = null;
                                });
                            }
                        });
                    }
                });
            }
            return valObj;
        }

        // If we are doing the final save of the form track how many fields are missing
        var missing = 0;
        Object.keys(valObj).forEach(function (key) {
            if (valObj[key] !== null && typeof valObj[key] === "object") {
                // Also check child objects
                Object.keys(valObj[key]).forEach(function (childKey) {
                    if (valObj[key][childKey] !== null && typeof valObj[key][childKey] === "object") {
                        Object.keys(valObj[key][childKey]).forEach(function (childKey2) {
                            if (valObj[key][childKey][childKey2] !== null) missing++;
                        });
                    }
                });
            } else if (valObj[key] !== null) missing++;
        });

        return [missing, numberRequired];
    };

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    };

    render() {
        const {
            classes,
            fieldActivity,
            cropYear,
            dryingSystems,
            fuelTypes,
            abandonmentReasons,
            energySources,
            alfalfaHarvestTypes,
            cottonMoisture,
            alfalfaDryingSystems,
            ormFieldActivityDelete,
            handleUnsavedFields,
            riceYieldMethods
        } = this.props;
        const { deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction, snackbarOpen, fuelUnits } = this.state;

        const yieldUnit = cropYear.crop_yield_unit || "bu/ac";

        // Temp get state
        var isCA = false;
        if (cropYear.fieldObject.location) {
            if (cropYear.fieldObject.location.slice(-2) === "CA") isCA = true;
        }

        const getFormValue = name => (this.form ? this.form.getValue(name) : getValue(fieldActivity, name));

        const unharvested = getFormValue("harvest.landuse.abandonment");
        const is_irrigated = getValue(cropYear, "is_irrigated");
        const drywet = getFormValue("harvest.landuse.yield_method");
        const drying_energy_source = getFormValue("harvest.energyuse.drying_energy_source");
        const drying_use_actual = getFormValue("harvest.energyuse.drying_use_actual");

        const alfalfa_drying_system = getFormValue("harvest.energyuse.alfalfa_drying_system");
        const drying_system = getFormValue("harvest.energyuse.drying_system");
        const noDry =
            (cropYear.crop === ALFALFA && !NO_DRYING_ALFALFA.includes(alfalfa_drying_system)) ||
            (cropYear.crop !== ALFALFA && !NO_DRYING.includes(drying_system));

        const nbalance = cropYear.project && cropYear.project.project_has_n_balance;
        // Only run validation if users have been here before (missing set)
        const miss = getValue(fieldActivity, "extrainfo.missing");
        const isMissing = miss > 0;

        const transportation_distance = getFormValue("harvest.energyuse.transportation_distance");

        return (
            <Form
                getApi={el => (this.form = el)}
                key={fieldActivity.id}
                dontValidateOnMount={isMissing ? false : true}
                validateOnSubmit={isMissing ? false : true}
                defaultValues={fieldActivity}
                formDidUpdate={() => handleUnsavedFields(true)}
                validateError={values => this.errorValidator(values, cropYear, true)}
                validateWarning={values => this.warningValidator(values, cropYear)}
                onSubmitFailure={(errors, formApi) => {
                    // This only occurs when switching steppers from step 3
                    // The errorValidator is indeed returning null for every valobj however it still has the old errors in finishSubmission
                    // https://github.com/react-tools/react-form/blob/v2.16.3/src/components/ReduxForm.js
                    // Something to do with calling submitform from two parent components above? (3_FieldActivites -> Save Operations)
                    // Skip the validation and go straight to the orm update
                    this.updateFieldActivity(formApi.values);
                }}
                onSubmit={values => this.updateFieldActivity(values)}>
                {formApi => (
                    <form onSubmit={formApi.submitForm}>
                        <WarningDialog
                            confirmAction={() => {
                                ormFieldActivityDelete(deleteDialogConfirmAction);
                                this.setState({ deleteDialogOpen: false });
                            }}
                            cancelAction={() => this.setState({ deleteDialogOpen: false })}
                            open={deleteDialogOpen}
                            title="Delete Harvest"
                            text={deleteDialogText}
                        />
                        <Grid container spacing={24}>
                            <Grid item xs={6} lg={5} xl={4} style={{ paddingRight: 22 }}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12}>
                                        <Typography variant="display3">Yield</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="number"
                                            inputProps={{ step: 0.1 }}
                                            field="harvest.landuse.yield"
                                            units={yieldUnit}
                                            help="Please note that yield is based on a per planted acre basis. The planted acreage may differ from the harvested acreage."
                                            fullWidth
                                            label="Yield (on a per planted acre basis)"
                                            margin="normal"
                                        />
                                        {cropYear.crop !== RICE &&
                                            (is_irrigated === "true" || is_irrigated === true) && (
                                                <TextField
                                                    type="number"
                                                    inputProps={{ step: 0.1 }}
                                                    field="harvest.wateruse.est_non_irrigated_yield"
                                                    units={yieldUnit}
                                                    help="This is the yield (on a 'per planted acre' basis) that would have been achieved in the absence of irrigation on a field currently under an irrigation system. An example of this estimate is the yield from the corners of a field using a center pivot irrigation system."
                                                    fullWidth
                                                    label="Estimated non-irrigated yield (on a per planted acre basis)"
                                                    margin="normal"
                                                />
                                            )}
                                        {cropYear.crop === RICE && (
                                            <RadioGroup
                                                field="harvest.landuse.yield_method"
                                                name="drywet"
                                                options={MAKE_OPTIONS(riceYieldMethods)}
                                                eventHandle={value =>
                                                    this.update_harvest(value, "harvest.landuse.yield_method")
                                                }
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="How is your rice yield calculated?"
                                                        helpText="Was the rice yield calculated before or after drying?"
                                                    />
                                                }
                                            />
                                        )}
                                        {cropYear.crop === RICE && drywet === WET_GREEN && (
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 1 }}
                                                field="harvest.landuse.harvest_moisture"
                                                units="%"
                                                help="Estimated moisture at which the rice was harvested."
                                                fullWidth
                                                label="Average rice harvest moisture (%)"
                                                margin="normal"
                                            />
                                        )}
                                        {cropYear.crop === ALFALFA && (
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 1 }}
                                                field="harvest.landuse.harvest_moisture"
                                                units="%"
                                                help="Estimated moisture at which the product is harvested."
                                                fullWidth
                                                label="Average alfalfa harvest moisture (%)"
                                                margin="normal"
                                            />
                                        )}
                                        {cropYear.crop === SUGAR_BEETS && (
                                            <TextField
                                                units="%"
                                                type="number"
                                                inputProps={{ step: 1 }}
                                                field="harvest.landuse.percent_sugar"
                                                help="What is the percent sugar contained with the root of the sugar beet (cultivar and growing condition dependent)?"
                                                fullWidth
                                                label="Percent sugar"
                                                margin="normal"
                                            />
                                        )}
                                        {cropYear.crop === RICE && !isCA && (
                                            <RadioGroup
                                                field="harvest.greenhouse.ratoon_cropping"
                                                name="ratooncrop"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Did you harvest a ratoon rice crop?"
                                                        helpText="Ratoon cropping refers to growing a fresh crop from the stubbles or suckers of a previously harvested crop without replanting."
                                                    />
                                                }
                                            />
                                        )}
                                        {cropYear.crop === ALFALFA && (
                                            <Select
                                                field="harvest.energyuse.harvest_type"
                                                options={MAKE_OPTIONS(alfalfaHarvestTypes)}
                                                help={
                                                    <ul>
                                                        <li>
                                                            Dry hay: grasses that were cut, dried and stored to be used
                                                            as fodder with moisture content between 12 and 20%.
                                                        </li>
                                                        <li>
                                                            Greenchopping: fresh forage is then delivered directly to
                                                            the animals. Silage: feed is preserved at high moisture
                                                            (50-75%).
                                                        </li>
                                                    </ul>
                                                }
                                                fullWidth
                                                label="Alfalfa harvest type"
                                                margin="normal"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioGroup
                                            field="harvest.landuse.abandonment"
                                            name="unharvested"
                                            options={YES_NO_OPTIONS}
                                            eventHandle={value =>
                                                this.update_harvest(value, "harvest.landuse.abandonment")
                                            }
                                            alignment={true}
                                            label={
                                                <HelpLabel
                                                    inputLabel="Did you abandon or not harvest any acres you planted for this year?"
                                                    helpText="How many acres did you abandon or not harvest this year?"
                                                />
                                            }
                                        />
                                        {(unharvested === "true" || unharvested === true) && (
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} className={classes.subQuestion}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="plantedarea"
                                                        units="ac"
                                                        disabled
                                                        value={cropYear.fieldObject.size.split(" ")[0]}
                                                        fullWidth
                                                        label="Planted acres"
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {(unharvested === "true" || unharvested === true) && (
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} className={classes.subQuestion}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        type="number"
                                                        inputProps={{ step: 0.1 }}
                                                        field="harvest.landuse.harvested_area"
                                                        units="ac"
                                                        eventHandle={this.handleAcresChange}
                                                        fullWidth
                                                        label="Given you abandoned some acres, how many did you actually harvest?"
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {(unharvested === "true" || unharvested === true) && (
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} className={classes.subQuestion}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        field="harvesteddifference"
                                                        units="ac"
                                                        disabled
                                                        value={this.state.diffAcres}
                                                        fullWidth
                                                        label="Difference"
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {(unharvested === "true" || unharvested === true) && (
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} className={classes.subQuestion}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <Select
                                                        field="harvest.landuse.abandonment_reason"
                                                        options={MAKE_OPTIONS(abandonmentReasons)}
                                                        help="Select the reason for not harvesting some or all of the planted acres."
                                                        fullWidth
                                                        label="Reason for abandoning acres"
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                    {nbalance && (cropYear.crop === CORN_GRAIN || cropYear.crop === CORN_SILAGE) && (
                                        <Grid container spacing={8}>
                                            <Grid item xs={12}>
                                                <Typography variant="display3" className={classes.gutterTop}>
                                                    Post Harvest Operations
                                                </Typography>
                                                <RadioGroup
                                                    field="harvest.waterquality.corn_stover_baled"
                                                    name="cornstoverbaled"
                                                    options={YES_NO_OPTIONS}
                                                    alignment={true}
                                                    label="Did you bale the corn stover?"
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={6} lg={5} xl={4} style={{ paddingLeft: 22 }}>
                                <Grid container spacing={8}>
                                    <Grid item xs={12}>
                                        <Typography variant="display3" className={classes.gutterTop}>
                                            Product Transportation/Hauling
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="number"
                                            inputProps={{ step: 0.1 }}
                                            eventHandle={value =>
                                                this.update_harvest(value, "harvest.energyuse.transportation_distance")
                                            }
                                            field="harvest.energyuse.transportation_distance"
                                            help={
                                                <div>
                                                    Distance the product travels in a vehicle from the field to the
                                                    point of sale (in miles).
                                                    <br />
                                                    <br />
                                                    This will include the distance from the field to on farm grain bin
                                                    storage (as applicable) prior to final sale.
                                                </div>
                                            }
                                            units="miles"
                                            fullWidth
                                            label="Distance from field to point of sale (miles)"
                                            margin="normal"
                                        />
                                        {parseFloat(transportation_distance) > 0 && (
                                            <RadioGroup
                                                field="harvest.energyuse.backhauling"
                                                name="returnload"
                                                options={YES_NO_OPTIONS}
                                                alignment={true}
                                                label={
                                                    <HelpLabel
                                                        inputLabel="Did you haul back a load?"
                                                        helpText={
                                                            <div>
                                                                Was a part of the product not sold and, instead, brought
                                                                back to the farm in a truck or other large vehicle?
                                                                <br />
                                                                <br />
                                                                This can include byproducts, remaining grain, or other
                                                                resources, such as livestock feed.
                                                            </div>
                                                        }
                                                    />
                                                }
                                            />
                                        )}
                                        {parseFloat(transportation_distance) > 0 && (
                                            <Select
                                                field="harvest.energyuse.transportation_fuel_id"
                                                label="Transportation fuel type"
                                                help="Select the type of fuel used during transportation."
                                                options={MAKE_OPTIONS(fuelTypes)}
                                                fullWidth
                                                margin="normal"
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {cropYear.crop !== POTATOES &&
                                            cropYear.crop !== SUGAR_BEETS &&
                                            cropYear.crop !== CORN_SILAGE && (
                                                <Typography variant="display3" className={classes.gutterTop}>
                                                    Drying
                                                </Typography>
                                            )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        {cropYear.crop === ALFALFA ? (
                                            <Select
                                                eventHandle={value =>
                                                    this.update_harvest(
                                                        value,
                                                        "harvest.energyuse.alfalfa_drying_system"
                                                    )
                                                }
                                                field="harvest.energyuse.alfalfa_drying_system"
                                                options={MAKE_OPTIONS(alfalfaDryingSystems)}
                                                fullWidth
                                                label="Alfalfa drying system"
                                                margin="normal"
                                            />
                                        ) : (
                                            cropYear.crop !== POTATOES &&
                                            cropYear.crop !== SUGAR_BEETS &&
                                            cropYear.crop !== CORN_SILAGE &&
                                            cropYear.crop !== COTTON &&
                                            cropYear.crop !== PEANUTS && (
                                                <Select
                                                    eventHandle={value =>
                                                        this.update_harvest(value, "harvest.energyuse.drying_system")
                                                    }
                                                    field="harvest.energyuse.drying_system"
                                                    options={MAKE_OPTIONS(dryingSystems)}
                                                    help={
                                                        <ul>
                                                            <li>
                                                                In-bin drying: air is pushed through the grain from the
                                                                bottom of the bin and is exhausted out the top of the
                                                                binral air drying uses unheated air to dry grain.
                                                            </li>
                                                            <li>
                                                                Low temperature drying: uses unheated air, or air heated
                                                                up to 15ºF (8ºC).
                                                            </li>
                                                            <li>
                                                                High temperature drying: uses heated air that keeps
                                                                kernel temperatures below 180ºF (82ºC) for animal feed.
                                                            </li>
                                                            <li>
                                                                Combination drying: uses both low and high temperature
                                                                air to reduce the grain moisture content to a safe
                                                                storage level.
                                                            </li>
                                                            <li>
                                                                Continuous/mixed air flow: column dryers that have air
                                                                flow in both counter and concurrent directions.
                                                            </li>
                                                            <li>
                                                                Batch drying: completely drying a portion of the
                                                                harvest, moving out of the bin and into storage, then
                                                                repeating the process with another portion of the
                                                                harvest.
                                                            </li>
                                                            <li>Stirring: Grain is mixed during drying.</li>
                                                            <li>
                                                                No air-circulating, continuous flow: the grain is
                                                                constantly moving through the drying bin from top to
                                                                bottom, passing first the tempering section and then the
                                                                drying section.
                                                            </li>
                                                            <li>
                                                                PTO type: using a tractor PTO shaft (power take off
                                                                shaft) to power the dryer.
                                                            </li>
                                                            <li>
                                                                No drying system: harvest is not purposefully dried.
                                                            </li>
                                                            <li>
                                                                Commercially dried: dried by a third party off-site.
                                                            </li>
                                                        </ul>
                                                    }
                                                    fullWidth
                                                    label="Drying system"
                                                    margin="normal"
                                                />
                                            )
                                        )}
                                        {noDry &&
                                            cropYear.crop !== COTTON &&
                                            cropYear.crop !== POTATOES &&
                                            cropYear.crop !== SUGAR_BEETS &&
                                            cropYear.crop !== CORN_SILAGE &&
                                            cropYear.crop !== ALFALFA &&
                                            cropYear.crop !== PEANUTS && (
                                                <Switch
                                                    field="harvest.energyuse.drying_use_actual"
                                                    name="calcordirect"
                                                    labelNo="Calculate energy"
                                                    labelYes="Enter energy"
                                                    eventHandle={value =>
                                                        this.update_harvest(
                                                            value,
                                                            "harvest.energyuse.drying_use_actual"
                                                        )
                                                    }
                                                    label={
                                                        <HelpLabel
                                                            inputLabel="By default, the system calculates drying energy based on the points of moisture removed during drying.  If you want to enter actual energy usage, click the Enter Energy toggle and select your energy source and enter the amount of usage."
                                                            helpText="Choose if you would like to manually enter the amount of energy used for drying or if you would like to enter additional information and have the tool calculate it for you. If you choose to enter a value, the unit should be in kWh/yr (kilowatt-hours per year) for electricity or gallons per year for a liquid fuel."
                                                        />
                                                    }
                                                />
                                            )}
                                        {noDry &&
                                            cropYear.crop !== COTTON &&
                                            cropYear.crop !== POTATOES &&
                                            cropYear.crop !== SUGAR_BEETS &&
                                            cropYear.crop !== CORN_SILAGE &&
                                            cropYear.crop !== ALFALFA &&
                                            cropYear.crop !== PEANUTS && (
                                                <Select
                                                    field="harvest.energyuse.drying_energy_source"
                                                    options={MAKE_OPTIONS(energySources)}
                                                    eventHandle={value =>
                                                        this.update_harvest(
                                                            value,
                                                            "harvest.energyuse.drying_energy_source"
                                                        )
                                                    }
                                                    help={
                                                        <div>
                                                            Select the option that best represents the energy source for
                                                            your drying system:
                                                            <br />
                                                            <br />
                                                            Electricity - Grid: Electricity purchased from your utility
                                                            with no renewable specification;
                                                            <br />
                                                            <br />
                                                            Electricity - Solar: Electricity sourced from solar energy
                                                            production on farm, or explicitly purchased renewable energy
                                                            from the utility;
                                                            <br />
                                                            <br />
                                                            Electricity - Wind: Electricity sourced from wind energy
                                                            production on site or explicitly purchased renewable energy
                                                            from the utility;
                                                            <br />
                                                            <br />
                                                            Fuel: Energy for the pump is provided by a liquid fuel, such
                                                            as diesel.
                                                        </div>
                                                    }
                                                    fullWidth
                                                    label="Energy source"
                                                    margin="normal"
                                                />
                                            )}
                                        {noDry &&
                                            drying_use_actual === true &&
                                            ELECTRICITY.includes(drying_energy_source) && (
                                                <Grid container wrap="nowrap">
                                                    <Grid xs={1} className={classes.subQuestion}>
                                                        <SubquestionIcon />
                                                    </Grid>
                                                    <Grid xs={11}>
                                                        <TextField
                                                            type="number"
                                                            inputProps={{ step: 0.1 }}
                                                            field="harvest.energyuse.drying_electricity_amount"
                                                            label="Electricity amount (kWh/yr)"
                                                            units="kwh"
                                                            help="Amount of electricity used in drying in kilowatt-hours per year."
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        {noDry && drying_energy_source === FUEL && (
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} className={classes.subQuestion}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <Select
                                                        field="harvest.energyuse.drying_fuel_id"
                                                        label="Drying fuel type"
                                                        help="Select the type of fuel used for your drying system."
                                                        options={MAKE_OPTIONS(fuelTypes)}
                                                        fullWidth
                                                        margin="normal"
                                                        eventHandle={this.getFuelUnits}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {noDry && drying_use_actual === true && drying_energy_source === FUEL && (
                                            <Grid container wrap="nowrap">
                                                <Grid xs={1} className={classes.subQuestion}>
                                                    <SubquestionIcon />
                                                </Grid>
                                                <Grid xs={11}>
                                                    <TextField
                                                        type="number"
                                                        inputProps={{ step: 0.1 }}
                                                        field="harvest.energyuse.drying_fuel_amount"
                                                        label="Drying fuel amount (gal/yr)"
                                                        units={fuelUnits}
                                                        help="Enter the volume of fuel used for this drying system."
                                                        fullWidth
                                                        margin="normal"
                                                        eventHandle={this.setDryingFuelAmount}
                                                    />
                                                </Grid>
                                            </Grid>
                                        )}
                                        {noDry &&
                                            drying_use_actual !== true &&
                                            cropYear.crop !== POTATOES &&
                                            cropYear.crop !== SUGAR_BEETS &&
                                            cropYear.crop !== CORN_SILAGE &&
                                            cropYear.crop !== COTTON &&
                                            cropYear.crop !== PEANUTS && (
                                                <TextField
                                                    type="number"
                                                    inputProps={{ step: 0.5 }}
                                                    field="harvest.energyuse.moisture_removed"
                                                    help="The amount of moisture removed from drying the product in percent."
                                                    units="%"
                                                    fullWidth
                                                    label="Percentage points of moisture removed through drying (%)"
                                                    margin="normal"
                                                />
                                            )}
                                        {cropYear.crop === COTTON && (
                                            <Select
                                                field="harvest.energyuse.cotton_target_moisture"
                                                options={MAKE_OPTIONS(cottonMoisture)}
                                                help="After moisture has been restored just prior to ginning: very dry (4-6%), normal (6-7%), wet (7-8%), very wet (>8%)"
                                                fullWidth
                                                label="Cotton moisture at ginning"
                                                margin="normal"
                                            />
                                        )}
                                        {cropYear.crop === PEANUTS && (
                                            <TextField
                                                type="number"
                                                inputProps={{ step: 0.1 }}
                                                field="harvest.energyuse.peanuts_initial_moisture"
                                                units="%"
                                                fullWidth
                                                label="What is the initial peanut moisture content when delivered to the buying point?"
                                                margin="normal"
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {cropYear.crop === ALFALFA && (
                                    <Button color="primary" onClick={() => this.deleteFieldActivity(fieldActivity.id)}>
                                        Delete Harvest
                                    </Button>
                                )}
                                <Button
                                    type="submit"
                                    variant="raised"
                                    color="primary"
                                    className={classes.button}
                                    onClick={() => this.setState({ submitClicked: true })}>
                                    Save Harvesting
                                </Button>
                            </Grid>
                            <Snackbar
                                success
                                onClose={this.handleSnackbarClose}
                                open={snackbarOpen}
                                section="harvest operation"
                            />
                        </Grid>
                    </form>
                )}
            </Form>
        );
    }
}

Harvest = connect(state => ({
    dryingSystems: allDryingSystems(state),
    fuelTypes: allFuelTypes(state),
    abandonmentReasons: allAbandonmentReasons(state),
    energySources: allEnergySources(state),
    alfalfaHarvestTypes: allAlfalfaHarvestTypes(state),
    cottonMoisture: allCottonMoisture(state),
    alfalfaDryingSystems: allAlfalfaDryingSystems(state),
    riceYieldMethods: allRiceYieldMethods(state)
}))(Harvest);

export default withStyles(styles)(Harvest);
