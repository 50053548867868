import React from "react";
import classNames from "classnames";
import * as PropTypes from "prop-types";
import TextField from "../common/TextField";
import withStyles from "@mui/styles/withStyles";
import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Form } from "react-form";

import Select from "../common/Select";
import HelpLabel from "../common/HelpLabel";

import * as utils from "./CropManagementUtils";
import RotationAutosuggest from "./autosuggest";

import "./autosuggest.css";
// import themeAutosuggestOperationSearching from "./autosuggestOperationSearching.css";
import * as util_options from "./options";

import PulseCropTable from "./PulseCropTable";

const styles = theme => ({
    container: {
        flexGrow: 1,
        position: "relative"
    },
    optionContainer: {
        display: "flex"
    },
    suggestion: {
        display: "block"
    },
    option: {
        paddingTop: "0px",
        paddingBottom: "0px"
    },
    optionLabel: {
        marginRight: "10px",
        marginTop: "8px"
    },
    optionLabelError: {
        marginRight: "10px",
        marginTop: "8px",
        color: "red"
    },
    root: {
        backgroundColor: theme.palette.primary.main
    },
    rootText: {
        color: "#fff"
    },
    cancelBorder: {
        border: "1px solid"
    },
    buttonMargin: {
        marginRight: 24,
        marginBottom: 16
    },
    gutterTop: {
        marginTop: ".6em"
    },
    noPadding: {
        paddingBottom: 0 + " !important"
    },
    minHeight: {
        minHeight: "80vh",
        minWidth: 600
    },
    dialogHeight: {
        height: 600
    }
});

function isRelease(op) {
    return op && op.name && op.name.match(/, release/);
}

class RotationEventDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            operation: "",
            crop: "",
            residue: "",
            residueAmount: "",

            operationInput: "",
            cropInput: "",
            residueInput: "",

            showCrop: false,
            showResidue: false,
            showResidueAmount: false,

            operationError: false,
            cropError: false,
            residueError: false,
            residueAmountError: false,

            year: "0001",
            month: "01",
            day: "01",

            dayOptions: []
        };
    }

    handleYearChange = value => {
        console.log("--> handleYearChange");
        this.setState({
            year: value
        });
    };

    handleMonthChange = month => {
        console.log("--> handleMonthChange");

        let dayOptions = this.make_crop_day_options(month);
        let day = this.state.day;

        if (
            dayOptions.filter(option => {
                return option["value"] === day;
            }).length === 0
        ) {
            day = "01";
        }

        this.setState({
            month: month,
            day: day,
            dayOptions: dayOptions
        });
    };

    handleCropDayChange = value => {
        console.log("--> handleCropDayChange");
        this.setState({
            day: value
        });
    };

    handleOperationSelected = (event, { suggestion }) => {
        console.log("-->  handleOperationSelected");

        this.setState({
            operation: JSON.parse(JSON.stringify(suggestion)),
            residueAmount: suggestion.add_residue ? suggestion.resAdded : this.state.residueAmount,
            showCrop: suggestion.begin_growth,
            showResidue: suggestion.add_residue,
            showResidueAmount: suggestion.add_residue,
            operationError: false
        });
    };

    handleCropSelected = (event, { suggestion }) => {
        console.log("-->  handleCropSelected");

        this.setState({
            crop: JSON.parse(JSON.stringify(suggestion)),
            cropInput: suggestion.name,
            cropError: false
        });
    };

    handleResidueSelected = (event, { suggestion }) => {
        console.log("-->  handleResidueSelected");

        this.setState({
            residue: JSON.parse(JSON.stringify(suggestion)),
            residueInput: suggestion.name,
            residueError: false
        });
    };

    handleResidueAmountChange = residueAmount => {
        console.log("-->  handleResidueAmountChange");

        if (parseFloat(residueAmount) >= 0) {
            this.setState({
                residueAmount: parseFloat(residueAmount),
                residueAmountError: false
            });
        } else if (parseFloat(residueAmount) < 0) {
            this.setState({
                residueAmount: parseFloat(residueAmount),
                residueAmountError: true
            });
        } else {
            this.setState({
                residueAmount: 0,
                residueAmountError: false
            });
        }
    };

    validateSelections() {
        let valid = true;

        const { operation, crop, residue } = this.state;

        if (operation !== "" && !this.state.operationError) {
            if (operation.begin_growth) {
                if (crop !== "" && !this.state.cropError) {
                    valid = true;
                } else {
                    valid = false;
                }
            }
            if (operation.add_residue) {
                if (residue !== "" && !this.state.residueError && !this.state.residueAmountError) {
                    valid = true;
                } else {
                    valid = false;
                }
            }
        } else {
            valid = false;
        }

        return valid;
    }

    handleSaveClick = () => {
        let date = [this.state.year, this.state.month, this.state.day].join("-");
        let event = { date: date, id: this.state.id, operation: this.state.operation };

        if (this.state.operation.begin_growth) {
            event.crop = this.state.crop;
        } else {
            event.crop = {};
        }

        if (this.state.operation.add_residue) {
            event.residue = this.state.residue;
            event.operation.resAdded = parseFloat(this.state.residueAmount);
        } else {
            event.residue = {};
            event.operation.resAdded = 0;
        }

        this.props.onSaveClick(event);
        this.resetState();
    };

    handleCancelClick = () => {
        console.log("-->  handleCancelClick");
        this.resetState();
        this.props.onCancelClick();
    };

    resetState() {
        this.setState({
            // date: "",
            operation: "",
            crop: "",
            residue: "",
            residueAmount: "",

            operationError: false,
            cropError: false,
            residueError: false,
            residueAmountError: false,

            year: "0001",
            month: "01",
            day: "01"
        });
    }

    onOperationChange = (event, { newValue }) => {
        var allOperations = [];

        this.props.groupedOperations.forEach(function (group) {
            group.options.forEach(function (option) {
                allOperations.push(option);
            });
        });

        let operations = allOperations.filter(operation => {
            return operation.name.toLowerCase() === newValue.toLowerCase();
        });

        if (operations.length === 1) {
            const op = JSON.parse(JSON.stringify(operations[0]));
            this.setState({
                operation: op,
                showCrop: op.begin_growth,
                showResidue: op.add_residue,
                showResidueAmount: op.add_residue ? op.resAdded : this.state.residueAmount,

                operationInput: op.name,
                operationError: false
            });
        } else {
            this.setState({
                operation: "",
                operationInput: newValue,
                operationError: true
            });
        }
    };

    onCropChange = (event, { newValue }) => {
        let crops = this.props.crops.filter(crop => {
            return crop.name.toLowerCase() === newValue.toLowerCase();
        });

        if (crops.length === 1) {
            const crop = JSON.parse(JSON.stringify(crops[0]));
            this.setState({
                crop: crop,
                cropInput: crop.name,
                cropError: false
            });
        } else {
            this.setState({
                crop: "",
                cropInput: newValue,
                cropError: true
            });
        }
    };

    onResidueChange = (event, { newValue }) => {
        let residues = this.props.residues.filter(residue => {
            return residue.name.toLowerCase() === newValue.toLowerCase();
        });

        if (residues.length === 1) {
            const residue = JSON.parse(JSON.stringify(residues[0]));
            this.setState({
                residue: residues,
                residueInput: residue.name,
                residueError: false
            });
        } else {
            this.setState({
                residue: "",
                residueInput: newValue,
                residueError: true
            });
        }
    };

    componentDidMount() {
        const { event } = this.props;
        if (event) {
            let todaysDate = utils.GetTodaysDate();
            let todaysDateParts = todaysDate.split("-");
            event.date = event.date
                ? event.date
                : ["0001", utils.pad(todaysDateParts[1], 2), utils.pad(todaysDateParts[2], 2)].join("-");

            let initialYear = event.date ? event.date.split("-")[0] : "0001";
            let initialMonth = event.date ? event.date.split("-")[1] : "01";
            let initialDay = event.date ? event.date.split("-")[2] : "01";

            let dayOptions = this.make_crop_day_options(initialMonth);

            this.setState({
                id: event.id ? event.id : "",
                // date: event.date ? event.date : utils.GetTodaysDate(),
                operation: event.operation ? event.operation : "",
                crop: event.crop ? event.crop : "",
                residue: event.residue ? event.residue : "",
                residueAmount: event.operation ? event.operation.resAdded : "",

                operationInput: event.operation ? event.operation.name : "",
                cropInput: event.crop ? event.crop.name : "",
                residueInput: event.residue ? event.residue.name : "",

                showCrop: !!(event.operation && event.operation.begin_growth),
                showResidue: !!(event.operation && event.operation.add_residue),
                showResidueAmount: !!(event.operation && event.operation.add_residue),

                year: initialYear,
                month: initialMonth,
                day: initialDay,

                dayOptions: dayOptions
            });
        }
    }

    make_crop_year_options() {
        let options = [];
        for (let i = 1; i <= 10; i++) {
            options.push({ value: utils.pad(i, 4), label: "Year " + i.toString() });
        }
        return options;
    }

    make_crop_day_options(month) {
        let month_length_obj = util_options.monthLength.filter(m => {
            return m.month === month;
        })[0];

        let options = [];
        if (month_length_obj) {
            for (let i = 1; i <= month_length_obj.days; i++) {
                options.push({ value: utils.pad(i, 2), label: i });
            }
        }
        return options;
    }

    render() {
        console.log("-->  Render RotationEventDialog");
        const { classes } = this.props;
        const { operation } = this.state;
        const { operationInput, cropInput, residueInput } = this.state;
        const { operationError, cropError, residueError, residueAmountError } = this.state;
        const { year, month, day, dayOptions } = this.state;

        const residueAmount = this.state.residueAmount ? this.state.residueAmount : 0;
        const saveDisabled = !this.validateSelections();
        const cropLabel = isRelease(operation) ? "Released Cover Crop" : "Crop";

        return (
            <Dialog open={this.props.open} onClose={this.handleClose} classes={{ paper: classes.minHeight }}>
                <DialogTitle className={classes.root}>
                    <Typography variant="title" className={classes.rootText}>
                        Operations
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogHeight}>
                    <Form dontValidateOnMount="true" validateOnSubmit="true">
                        {formApi => (
                            <form onSubmit={formApi.submitForm}>
                                <Grid container spacing={16}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.gutterTop}>
                                            Select a date and operation for the event. Dates are meant to be
                                            representative of the operation timing in a typical year. They do not have
                                            to match exactly to your actual dates in the field. The year is based on a
                                            Year 1, Year 2, Year 3, etc. concept. The sequence of crops in the Crop
                                            Rotation System, meaning, which crop starts in Year 1 vs Year 2, is not
                                            important.
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} className={classes.noPadding}>
                                        <Typography>
                                            <b>Representative Date of Operation</b>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <div className={classes.optionContainer}>
                                            <div className={classes.optionLabel}>
                                                <Typography>Year:</Typography>
                                            </div>
                                            <Select
                                                field="year"
                                                options={this.make_crop_year_options()}
                                                value={year}
                                                fullWidth
                                                eventHandle={value => this.handleYearChange(value)}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <div className={classes.optionContainer}>
                                            <div className={classes.optionLabel}>
                                                <Typography>Month:</Typography>
                                            </div>
                                            <Select
                                                field="month"
                                                options={util_options.monthOptions}
                                                value={month}
                                                fullWidth
                                                eventHandle={value => this.handleMonthChange(value)}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <div className={classes.optionContainer}>
                                            <div className={classes.optionLabel}>
                                                <Typography>Day:</Typography>
                                            </div>
                                            <Select
                                                field="day"
                                                options={dayOptions}
                                                fullWidth
                                                value={day}
                                                eventHandle={value => this.handleCropDayChange(value)}
                                            />
                                        </div>
                                    </Grid>

                                    <Grid item xs={12} className={classes.noPadding}>
                                        <Typography className={operationError && classes.optionLabelError}>
                                            <b>Operation</b>
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <div
                                            id={operationInput ? "operationSelectorSearching" : "operationSelector"}
                                            className={classes.optionContainer}>
                                            <RotationAutosuggest
                                                placeholder={"Search operations"}
                                                multiSection={true}
                                                suggestions={this.props.groupedOperations}
                                                alwaysRenderSuggestions={true}
                                                onSuggestionSelected={(a, b) => this.handleOperationSelected(a, b)}
                                                onChange={this.onOperationChange}
                                                value={operationInput}
                                                toolTip={"opNotes"}
                                                type={"search"}
                                                style={{ width: "542px", height: "52px", "-webkit-appearance": "none" }}
                                            />
                                        </div>
                                    </Grid>

                                    {operation.begin_growth && (
                                        <>
                                            <Grid item xs={12} className={classes.noPadding}>
                                                <Typography
                                                    className={
                                                        cropError ? classes.optionLabelError : classes.optionLabel
                                                    }>
                                                    <b>{cropLabel}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <HelpLabel
                                                    inputLabel={
                                                        <span style={{ color: "#999" }}>
                                                            *Important note on searching for Pulse or Dry Bean crops
                                                        </span>
                                                    }
                                                    helpText={<PulseCropTable />}
                                                />
                                                <div className={classes.optionContainer}>
                                                    <RotationAutosuggest
                                                        placeholder={"Search crops"}
                                                        multiSection={false}
                                                        suggestions={this.props.crops}
                                                        alwaysRenderSuggestions={true}
                                                        onSuggestionSelected={(a, b) => this.handleCropSelected(a, b)}
                                                        onChange={this.onCropChange}
                                                        value={cropInput ? cropInput : ""}
                                                        toolTip={"cropNotes"}
                                                        type={"search"}
                                                        style={{
                                                            width: "542px",
                                                            height: "52px",
                                                            "-webkit-appearance": "none"
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                        </>
                                    )}

                                    {operation.add_residue && (
                                        <>
                                            <Grid item xs={12} className={classes.noPadding}>
                                                <Typography
                                                    className={
                                                        residueError ? classes.optionLabelError : classes.optionLabel
                                                    }>
                                                    <b>Residue</b>
                                                    <HelpLabel
                                                        question="Residue"
                                                        style={{ marginLeft: 4, display: "inline" }}
                                                        helpText={
                                                            <div>
                                                                Compost
                                                                <br />
                                                                Composted material applied to field, associated with
                                                                mulch operation. Some or all of the plant material has
                                                                been shredded into small and very small pieces. When
                                                                applied to field, material has slow decompostion rate.
                                                                Mass applied to field must represent oven-dry weight.
                                                                Decomposition coefficient 0.008 and half-life 87 days.
                                                                <br />
                                                                <br />
                                                                Corn Stalks
                                                                <br />
                                                                Corn stalks detached from standing stubble, associated
                                                                with a manage residue operation, e.g. chopping,
                                                                flailing. Decomposition coefficient 0.016 and half-life
                                                                43 days.
                                                                <br />
                                                                <br />
                                                                Manure, Liquid
                                                                <br />
                                                                Manure applied as a liquid from a holding pond or
                                                                lagoon. Associate to a manure application operation.
                                                                Multiply the oven dry weight times an effectiveness
                                                                factor of 0.5 to calculate the rate of application in
                                                                model. Mostly liquid with 1 to 4% solids. Rapid
                                                                decomposition rate. Based on beef, swine or dairy wastes
                                                                with dilution. Decomposition coefficient 0.060 and
                                                                half-life 12 days.
                                                                <br />
                                                                <br />
                                                                Manure, Moderate Bedding
                                                                <br />
                                                                Manure from loafing sheds or farrowing stalls or other
                                                                facilities containing moderate bedding; 20 to 40%
                                                                solids. Associated to manure application operation.
                                                                Moderate decomposition rate. Use oven dry weight for
                                                                rate of application in model. For faster decaying
                                                                poultry manure with moderate bedding and having high
                                                                percent N content, use "Manure, Semi-Solid”.
                                                                Decomposition coefficient 0.034 and half-life 20 days.
                                                                <br />
                                                                <br />
                                                                Manure, Poultry
                                                                <br />
                                                                Manure from poultry. Contains no bedding. Very rapid
                                                                decompostion rate due to high N content. Multiply the
                                                                oven dry weight times an effectiveness factor of 0.5 to
                                                                calculate the rate of application in model.
                                                                Decomposition coefficient 0.096 and half-life 7 days.
                                                                <br />
                                                                <br />
                                                                Manure, Semi-Solid
                                                                <br />
                                                                Manure semi-solid, associated to manure application
                                                                operation. Multiply the oven dry weight times an
                                                                effectiveness factor of 0.5 to calculate the rate of
                                                                application in model. May contain some bedding; 12-20%
                                                                solids; "As voided” manure from open lots or buildings.
                                                                For faster decaying poultry manure with little or no
                                                                bedding and having high percent N content, use "Manure,
                                                                Poultry”. Decomposition coefficient 0.060 and half-life
                                                                12 days.
                                                                <br />
                                                                <br />
                                                                Manure, Slurry
                                                                <br />
                                                                Manure applied as a slurry from a settling basin; mostly
                                                                liquid with 4 to 12% solids. Associated to manure
                                                                application operation. Based on beef, swine or dairy
                                                                wastes with less dilution than liquid lagoon wastes.
                                                                Multiply the oven dry weight times an effectiveness
                                                                factor of 0.5 to calculate the rate of application.
                                                                Decomposition coefficient 0.060 and half-life 12 days.
                                                                <br />
                                                                <br />
                                                                Manure, Solid, Significant Bedding
                                                                <br />
                                                                Manure from loafing sheds or farrowing stalls containing
                                                                significant bedding; 40 to 60%; solids. Associated to
                                                                manure application operation. Use oven dry weight for
                                                                rate of application in model. For faster decaying
                                                                poultry manure with significant bedding and having high
                                                                percent N content, use "Manure, solid, moderate
                                                                bedding”. Decomposition coefficient 0.017 and half-life
                                                                41 days.
                                                                <br />
                                                                <br />
                                                                Plastic or Permeable Mulch
                                                                <br />
                                                                Plastic or permeable material laid down to control weeds
                                                                and conserve moisture associated with a mulch operation.
                                                                Associate only to operations have plastic or permeable
                                                                barrier in the name. Default “residue" amount is 2000
                                                                pounds per acre, which is sufficient for this material
                                                                for calculating metrics. Decomposition coefficient 0.002
                                                                and half-life 347 days.
                                                                <br />
                                                                <br />
                                                                Small Grain Straw
                                                                <br />
                                                                Small grain straw associated with mulch or manage
                                                                residue operation. Default amount associates to the
                                                                operation leaving the residue, and should be edited if
                                                                necessary to reflect the amount applied. Decomposition
                                                                coefficient 0.008 and half-life 87 days.
                                                                <br />
                                                                <br />
                                                                Water
                                                                <br />
                                                                Only use if trying to simulate a recurring flooded
                                                                condition. Only associate to the Application, "Add
                                                                Mulch” operation. The default amount for this operation
                                                                is 2000 pounds per acre, but set amount to 0. For rice
                                                                or other flooded crops, better to select a water mulch
                                                                operation, rather than a water residue. Has no
                                                                decomposition coefficient or half-life.
                                                                <br />
                                                                <br />
                                                                Weed Residue, 0-3 Months
                                                                <br />
                                                                Weeds, or native vegetation killed by a herbicide
                                                                application. Mixed grasses and broadleaf. Age of plants,
                                                                time since regrowth, or time since last mowed/cut is 0-3
                                                                months. Default amount associates to the operation
                                                                leaving the residue, and should be edited if necessary
                                                                to reflect the actual amount on the ground.
                                                                Decomposition coefficient 0.025 and half-life 28 days.
                                                                <br />
                                                                <br />
                                                                Weed Residue, 3-6 Months
                                                                <br />
                                                                Weeds, or native vegetation killed by a herbicide
                                                                application. Mixed grasses and broadleaf. Age of plants,
                                                                time since re-growth, or time since last mowed/cut is 3-
                                                                6 months. Default amount associates to the operation
                                                                leaving the residue, and should be edited if necessary
                                                                to reflect the actual amount on the ground.
                                                                Decomposition coefficient 0.200 and half-life 35 days.
                                                                <br />
                                                                <br />
                                                                Weed Residue, 6-12 Months
                                                                <br />
                                                                Weeds, or native vegetation killed by a herbicide
                                                                application. Mixed grasses and broadleaf. Age of plants,
                                                                time since re-growth, or time since last mowed/cut is
                                                                6-12 months. Default amount associates to the operation
                                                                leaving the residue, and should be edited if necessary
                                                                to reflect the actual amount on the ground.
                                                                Decomposition coefficient 0.017 and half-life 41 days.
                                                                <br />
                                                                <br />
                                                                Weed Residue, 12+ Months
                                                                <br />
                                                                Weeds, or native vegetation killed by a herbicide
                                                                application. Mixed grasses and broadleaf. Age of plants,
                                                                time since re-growth, or time since last mowed/cut is
                                                                more than 12 months. Default amount associates to the
                                                                operation leaving the residue, and should be edited if
                                                                necessary to reflect the actual amount on the ground.
                                                                Decomposition coefficient 0.016 and half-life 43 days.
                                                                <br />
                                                                <br />
                                                                Wood Chips
                                                                <br />
                                                                Wood chips associated with mulch application. Default
                                                                amount associates to the operation leaving the residue,
                                                                and should be edited if necessary to reflect the amount
                                                                applied. Decomposition coefficient 0.001 and half-life
                                                                693 days.
                                                            </div>
                                                        }
                                                    />
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className={classes.optionContainer}>
                                                    <RotationAutosuggest
                                                        placeholder={"Search residues"}
                                                        multiSection={false}
                                                        suggestions={this.props.residues}
                                                        alwaysRenderSuggestions={true}
                                                        onSuggestionSelected={(a, b) =>
                                                            this.handleResidueSelected(a, b)
                                                        }
                                                        onChange={this.onResidueChange}
                                                        value={residueInput ? residueInput : ""}
                                                        type={"search"}
                                                        style={{
                                                            width: "542px",
                                                            height: "52px",
                                                            "-webkit-appearance": "none"
                                                        }}
                                                    />
                                                </div>
                                            </Grid>
                                        </>
                                    )}

                                    {operation.add_residue && (
                                        <>
                                            <Grid item xs={12} className={classes.noPadding}>
                                                <Typography
                                                    className={
                                                        residueAmountError
                                                            ? classes.optionLabelError
                                                            : classes.optionLabel
                                                    }>
                                                    <b>Residue Amount:</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div className={classes.optionContainer}>
                                                    <TextField
                                                        type={"number"}
                                                        field="residueAmount"
                                                        units="lb/ac"
                                                        value={residueAmount}
                                                        margin="none"
                                                        error={residueAmountError}
                                                        eventHandle={value => this.handleResidueAmountChange(value)}
                                                    />
                                                </div>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </form>
                        )}
                    </Form>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => this.handleCancelClick()}
                        color="primary"
                        className={classNames(classes.cancelBorder, classes.buttonMargin)}>
                        Cancel
                    </Button>
                    <Button
                        disabled={saveDisabled}
                        onClick={() => this.handleSaveClick()}
                        className={classes.buttonMargin}
                        variant="raised"
                        color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

RotationEventDialog.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(RotationEventDialog);
