import React, { Component } from "react";
import AppContainer from "../common/AppContainer";
import { connect } from "react-redux";
import { Form } from "react-form";
import Typography from "../common/TypographyWrapper";
import Button from "../common/ButtonWrapper";
import Paper from "@mui/material/Paper";
import Grid from "../common/GridWrapper";
import Chip from "@mui/material/Chip";
import withStyles from "@mui/styles/withStyles";
import Fade from "@mui/material/Fade";
import { LinearProgress } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ContentCopyIcon from "@mui/icons-material/FileCopyOutlined";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Divider from "@mui/material/Divider";

import HelpDialog from "../common/HelpDialog";
import TextField from "../common/TextField";
import withRouter from "../common/withRouter";

import * as options from "./options";
import TemplateLibraryTable from "./tables/TemplateLibraryTable";
import * as utils from "./CropManagementUtils";

import CropOptionsDialog from "./templatesearch/cropOptionsDialog";
import StateOptionsDialog from "./templatesearch/stateOptionsDialog";
import CMZOptionsDialog from "./templatesearch/cmzOptionsDialog";
import TillageOptionsDialog from "./templatesearch/tillageOptionsDialog";
import DurationOptionsDialog from "./templatesearch/durationOptionsDialog";

import PreviewRotation from "./PreviewRotation";

import * as rotationActions from "./actions";

import { RotationSystem, RotationEvent } from "./models";

const styles = theme => ({
    chips: {
        display: "flex",
        flexWrap: "wrap"
    },
    chip: {
        margin: theme.spacing(1 / 4),
        backgroundColor: "#FFFFFF",
        border: "1px solid #e0e0e0"
    },
    chipDelete: {
        margin: theme.spacing(1 / 4),
        backgroundColor: "#FFFFFF",
        border: "1px solid #e0e0e0",
        "&:hover": {
            backgroundColor: "#ff7d32",
            color: "#fff",
            "& polyline, & rect, & line, & path, & polygon": {
                stroke: "#fff"
            }
        }
    },
    projectButton: {
        backgroundColor: "#fff",
        border: "1px solid #979797",
        color: "#979797",
        boxShadow: "none",
        margin: 0,
        "&:hover": {
            backgroundColor: "#ff7d32",
            color: "#fff",
            "& polyline, & rect, & line, & path, & polygon": {
                stroke: "#fff"
            }
        }
    },
    hideTable: {
        display: "none"
    }
});

class RotationSearch extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchType: "0",
            keyword_string: "",
            loading: true,
            cropError: false,
            cropContains: "0",
            showFTMCropsFirst: true,
            expanded: null,
            cropFilter: "",
            crops: [],
            stateOptions: [],
            cmzOptions: [],
            selectedTillage: "all",
            includeCoverCrop: false,
            selectedDuration: "0",

            filterParameters: {},
            dialogOptions: false,

            selectedCrops: [],
            selectedStates: [],
            selectedCMZs: [],
            durationOptions: {},

            newSearch: false,

            previewSystemId: null,
            copySystemId: null
        };
    }

    verifySelections = () => {
        let searchReady = true;
        if (this.state.searchType === "0") {
            if (this.state.selectedCrops.length === 0) {
                searchReady = false;
            }
        } else {
            if (this.state.keyword_string.length < 3) {
                searchReady = false;
            }
        }
        return searchReady;
    };

    handleSearchTypeChange = e => {
        this.setState({ searchType: e.target.value });
    };

    handleKeywordChange = keyword => {
        this.setState({ keyword: keyword });
        this.verifySelections();
    };

    handleSearchClick = () => {
        function cartesian() {
            var r = [],
                arg = arguments,
                max = arg.length - 1;
            function helper(arr, i) {
                for (var j = 0, l = arg[i].length; j < l; j++) {
                    var a = arr.slice(0); // clone arr
                    a.push(arg[i][j]);
                    if (i === max) {
                        r.push(a);
                    } else helper(a, i + 1);
                }
            }
            helper([], 0);
            return r;
        }

        if (this.state.searchType === "0") {
            //  multiparameter search

            // crop name string
            let crop_names = [];
            this.state.selectedCrops.forEach(selectedCropId => {
                let a = this.state.crops.filter(option => {
                    return option.id.toString() === selectedCropId;
                })[0];
                a["name"]
                    .split(",")[0]
                    .split("-")[0]
                    .split(" ")[0]
                    .trim()
                    .toLowerCase()
                    .split("999")
                    .forEach(word => {
                        if (crop_names.indexOf(word) === -1) {
                            crop_names.push(word);
                        }
                    });
            });

            const cropname_maps = {
                sugarbeet: ["sugarbeet", "sugar beet"]
            };

            var crop_name_lists = [];
            crop_names.forEach(crop_name => {
                if (crop_name in cropname_maps) {
                    crop_name_lists.push(cropname_maps[crop_name]);
                } else {
                    crop_name_lists.push([crop_name]);
                }
            });

            var keyword_lists = cartesian(...crop_name_lists);
            keyword_lists = keyword_lists.map(l => {
                return l.join(" ");
            });

            //  get cmzs from selected selectedStates
            let cmzs = [];
            this.state.selectedCMZs.forEach(cmz => {
                cmzs.push(cmz);
            });
            this.state.selectedStates.forEach(selectedStateId => {
                let stateCMZs = options.stateOptions.filter(stateOption => {
                    return stateOption.value === selectedStateId;
                })[0]["cmzs"];
                stateCMZs.forEach(cmz => {
                    if (cmzs.indexOf(cmz) === -1) {
                        cmzs.push(cmz);
                    }
                });
            });

            if (cmzs.length === 0) {
                cmzs = [""];
            }

            const searchParameters = {
                searchType: this.state.searchType,
                keyword_lists: keyword_lists,
                cmz: cmzs,
                stirMin: options.tillageOptions.filter(tillage => {
                    return tillage.value === this.state.selectedTillage;
                })[0].stirMin,
                stirMax: options.tillageOptions.filter(tillage => {
                    return tillage.value === this.state.selectedTillage;
                })[0].stirMax
            };

            let filterParameters = {
                selectedDuration: this.state.selectedDuration,
                includeCoverCrop: this.state.includeCoverCrop,
                selectedCrops: this.state.selectedCrops,
                cropContains: this.state.cropContains
            };

            this.props.rotationMgmtSearch({
                search: searchParameters,
                filter: filterParameters,
                crlmodCrops: this.state.crops
            });

            this.setState({
                searchParameters: searchParameters,
                filterParameters: filterParameters,
                newSearch: true
            });
        } else {
            //  keyword search

            //  check for management ids
            let management_ids = [];
            this.state.keyword_string.split(" ").forEach(s => {
                const parsed = parseInt(s);
                if (!isNaN(parsed) && s.length === 6) {
                    management_ids.push(parsed);
                }
            });

            const searchParameters = {
                management_ids: management_ids.length > 0 ? management_ids : null,
                keyword_string: this.state.keyword_string,
                searchType: this.state.searchType
            };

            this.props.rotationMgmtSearch({
                search: searchParameters,
                filter: null,
                crlmodCrops: null
            });

            this.setState({
                newSearch: true
            });
        }
    };

    handleCropsChipDelete = data => () => {
        let selectedCrops = this.state.selectedCrops.filter(crop => {
            return crop !== data;
        });
        this.setState({
            selectedCrops: selectedCrops
        });
    };

    handleCropContainsChipDelete = () => {
        this.setState({ cropContains: "0" });
    };

    handleStatesChipDelete = data => () => {
        let selectedStates = this.state.selectedStates.filter(state => {
            return state !== data;
        });
        this.setState({
            selectedStates: selectedStates
        });
    };

    handleCMZChipDelete = data => () => {
        let selectedCMZs = this.state.selectedCMZs.filter(cmz => {
            return cmz !== data;
        });
        this.setState({
            selectedCMZs: selectedCMZs
        });
    };

    handleTillageChipDelete = data => () => {
        this.setState({ selectedTillage: "all" });
    };

    loadCropAndOperationData() {
        let that = this;
        Promise.all([utils.CropsRequest, utils.OperationsRequest]).then(function (values) {
            let operations = values[1]["result"][0]["value"]["operations"],
                crops = values[0];

            crops.forEach(crop => {
                crop["show"] = true;
            });

            that.setState({
                operations: operations,
                crops: crops,
                loading: false,
                cropError: false
            });
        });
    }

    handleOpenDialog = type => {
        var dialogOptions = {
            crops: {
                title: "Select Crops (required)",
                description:
                    "Select the crops that must be in the rotation.  Only templates containing all the selected crops will be returned.",
                caption: "Crops caption",
                options: this.state.crops
            },
            states: {
                title: "Select States",
                options: this.state.stateOptions
            },
            cmzs: {
                options: this.state.cmzOptions
            },
            tillage: {
                options: this.state.tillageOptions
            },
            duration: {
                options: this.state.durationOptions
            }
        };

        this.setState({
            openDialog: type,
            dialogOptions: dialogOptions[type]
        });
    };

    handleCropDialogCancelClick = () => {
        this.setState({
            openDialog: ""
        });
    };

    handleCropDialogSaveClick = obj => {
        this.setState({
            selectedCrops: obj.selectedCrops,
            showFTMCropsFirst: obj.showFTMCropsFirst,
            cropContains: obj.cropContains,
            openDialog: ""
        });
    };

    handleShowFTMCropsFirstClick = () => {
        this.setState({
            showFTMCropsFirst: !this.state.showFTMCropsFirst
        });
    };

    handleCropContainsChange = value => {
        this.setState({
            cropContains: value
        });
    };

    handleStateDialogCancelClick = () => {
        this.setState({
            openDialog: ""
        });
    };

    handleStateDialogSaveClick = selectedStates => {
        this.setState({
            selectedStates: selectedStates,
            openDialog: ""
        });
    };

    handleCMZDialogCancelClick = () => {
        this.setState({
            openDialog: ""
        });
    };

    handleCMZDialogSaveClick = selectedCMZs => {
        this.setState({
            selectedCMZs: selectedCMZs,
            openDialog: ""
        });
    };

    handleTillageDialogCancelClick = () => {
        this.setState({
            openDialog: ""
        });
    };

    handleTillageDialogSaveClick = selectedTillage => {
        this.setState({
            selectedTillage: selectedTillage,
            openDialog: ""
        });
    };

    handleDurationDialogCancelClick = () => {
        this.setState({
            openDialog: ""
        });
    };

    handleDurationDialogSaveClick = selectedDuration => {
        this.setState({
            selectedDuration: selectedDuration,
            openDialog: ""
        });
    };

    handleManagementPreviewClick = managementId => {
        let managements = this.props.mgmtState.results;

        var rotationSystem = null;
        managements.forEach(function (management) {
            if (management.id === managementId) {
                rotationSystem = management;
            }
        });

        this.setState({
            previewSystemId: rotationSystem
        });
    };

    handleCopySystemClick = systemId => {
        this.setState({
            copySystemId: systemId
        });
    };

    confirmCopy = () => {
        this.copySystem(this.state.copySystemId);
    };

    cancelCopy = () => {
        this.setState({ copySystemId: null });
    };

    copySystem = id => {
        var rotationSystem = this.props.mgmtState.results.filter(system => system.id === id)[0];

        var rotationSystemObj = {
            name: "NRCS Copy",
            template: rotationSystem.id,
            template_name: rotationSystem.name
        };

        let events = rotationSystem.events.map(event => {
            return {
                date: event.date,
                operation: event.operation,
                crop: event.crop,
                residue: event.residue
            };
        });

        const parameters = {
            ormRotationSystemCreate: this.props.ormRotationSystemCreate,
            ormRotationEventCreate: this.props.ormRotationEventCreate,
            ormRotationSystemUpdate: this.props.ormRotationSystemUpdate,
            rotationSystem: rotationSystemObj,
            events: events,
            projectIds: this.props.match.params.projectId ? [this.props.match.params.projectId] : null,
            operations: this.state.operations,
            crops: this.state.crops
        };

        const systemId = utils.createRotationSystemAndEvents(parameters);

        if (this.props.match.params.cropyearId) {
            this.props.history.push("/rotation/" + systemId + "/cropyear/" + this.props.match.params.cropyearId);
        } else if (this.props.match.params.projectId) {
            this.props.history.push("/rotation/" + systemId + "/project/" + this.props.match.params.projectId);
        } else {
            this.props.history.push("/rotation/" + systemId);
        }
    };

    handlePreviewCancelClick() {
        this.setState({ previewSystemId: null });
    }

    handleReturnClick = () => {
        if (this.props.match.params.cropyearId) {
            this.props.history.push("/cropyear/" + this.props.match.params.cropyearId);
        } else if (this.props.match.params.projectId) {
            this.props.history.push("/projectadmin/" + this.props.match.params.projectId);
        } else {
            this.props.history.push("/rotation/library");
        }
    };

    getReturnButtonText = () => {
        if (this.props.match.params.cropyearId) {
            return "Return to Editing Crop Year";
        } else if (this.props.match.params.projectId) {
            return "Return to Project";
        } else {
            return "Return to Library";
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        this.loadCropAndOperationData();

        let stateOptions = options.stateOptions.map(stateOption => {
            stateOption["show"] = true;
            return stateOption;
        });

        let cmzList = [];
        options.stateOptions.forEach(stateOption => {
            stateOption.cmzs.forEach(cmz => {
                if (cmzList.indexOf(cmz) === -1) {
                    cmzList.push(cmz);
                }
            });
        });
        cmzList.sort((a, b) => a - b);

        let cmzOptions = cmzList.map(cmz => {
            return { id: cmz, name: cmz, show: true };
        });

        let durationOptions = [];
        var i;
        for (i = 1; i <= 20; i++) {
            if (i === 1) {
                durationOptions.push({ id: i, value: i.toString(), label: i + " year" });
            } else {
                durationOptions.push({ id: i, value: i.toString(), label: i + " years" });
            }
        }

        durationOptions.unshift({ id: 0, value: "0", label: "Any duration (default)" });

        this.setState({
            stateOptions: stateOptions,
            cmzOptions: cmzOptions,
            tillageOptions: options.tillageOptions,
            durationOptions: durationOptions
        });
    }

    render() {
        const { classes } = this.props;
        const { operations, loading, previewSystemId, copySystemId } = this.state;

        const searchResults = this.props.mgmtState.results === undefined ? [] : this.props.mgmtState.results;

        const columnData = [
            { id: "name", numeric: false, disablePadding: true, label: "Name", allowSort: true },
            { id: "duration", numeric: true, disablePadding: true, center: true, label: "Duration", allowSort: true },
            { id: "cmz", numeric: true, disablePadding: true, center: true, label: "CMZ", allowSort: true },
            { id: "path", numeric: false, disablePadding: true, label: "Path", allowSort: true }
            // {id: "type", numeric: false, disablePadding: false, label: "Type", allowSort: true},
            // { id: "view", numeric: false, disablePadding: false, label: "View", isAction: true },
            // { id: "save", numeric: false, disablePadding: false, label: "Save", isAction: true }
        ];

        const searchReady = this.verifySelections();

        const helpDialogText = copySystemId
            ? "Are you sure you want to copy rotation system: " +
              searchResults.filter(s => s.id === copySystemId)[0].name +
              "."
            : "";

        return (
            <AppContainer authenticated color="crop" title="Crop Rotation Library">
                <HelpDialog
                    open={!!copySystemId}
                    onClose={() => this.setState({ copySystemId: null })}
                    question="Copy Crop Rotation Template"
                    text={helpDialogText}
                    confirmAction={this.confirmCopy}
                    cancelAction={this.cancelCopy}
                />

                {!!previewSystemId && (
                    <PreviewRotation
                        open={!!previewSystemId}
                        date={utils.GetTodaysDate()}
                        rotationSystem={this.state.previewSystemId}
                        operations={operations}
                        onSaveManagementClick={this.handleCopySystemClick}
                        onPreviewCancelClick={() => this.handlePreviewCancelClick()}
                        showCopy={true}
                    />
                )}

                {this.state.openDialog === "crops" && (
                    <CropOptionsDialog
                        open={true}
                        cropOptions={this.state.crops}
                        alreadySelectedCrops={this.state.selectedCrops}
                        onCancelClick={() => this.handleCropDialogCancelClick()}
                        onSaveClick={a => this.handleCropDialogSaveClick(a)}
                        cropContainsOptions={options.cropContainsOptions}
                        cropContains={this.state.cropContains}
                        onCropContainsChange={a => this.handleCropContainsChange(a)}
                        showFTMCropsFirst={this.state.showFTMCropsFirst}
                        onShowFTMCropsFirstClick={() => this.handleShowFTMCropsFirstClick()}
                    />
                )}

                {this.state.openDialog === "states" && (
                    <StateOptionsDialog
                        open={true}
                        stateOptions={this.state.stateOptions}
                        alreadySelectedStates={this.state.selectedStates}
                        onCancelClick={() => this.handleStateDialogCancelClick()}
                        onSaveClick={a => this.handleStateDialogSaveClick(a)}
                    />
                )}

                {this.state.openDialog === "cmzs" && (
                    <CMZOptionsDialog
                        open={true}
                        cmzOptions={this.state.cmzOptions}
                        alreadySelectedCMZs={this.state.selectedCMZs}
                        onCancelClick={() => this.handleCMZDialogCancelClick()}
                        onSaveClick={a => this.handleCMZDialogSaveClick(a)}
                    />
                )}

                {this.state.openDialog === "tillage" && (
                    <TillageOptionsDialog
                        open={true}
                        tillageOptions={this.state.tillageOptions}
                        alreadySelectedTillage={this.state.selectedTillage}
                        onCancelClick={() => this.handleTillageDialogCancelClick()}
                        onSaveClick={a => this.handleTillageDialogSaveClick(a)}
                    />
                )}

                {this.state.openDialog === "duration" && (
                    <DurationOptionsDialog
                        open={true}
                        durationOptions={this.state.durationOptions}
                        alreadySelectedDuration={this.state.selectedDuration}
                        onCancelClick={() => this.handleDurationDialogCancelClick()}
                        onSaveClick={a => this.handleDurationDialogSaveClick(a)}
                    />
                )}

                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="display3" gutterBottom>
                            Search for a Template
                        </Typography>

                        {/* <Typography gutterBottom>
                            Search for rotation templates using the options below. You must select the exact crops in
                            the rotation and the rotation duration. Use the state, CMZ, and tillage options to narrow
                            the search results.
                        </Typography> */}

                        <RadioGroup
                            value={this.state.searchType}
                            onChange={this.handleSearchTypeChange}
                            style={{ display: "flex", flexDirection: "row" }}>
                            {[
                                { id: "0", label: "Multiparameter Search" },
                                { id: "1", label: "Keyword Search" }
                            ].map(option => (
                                <FormControlLabel
                                    value={option.id}
                                    control={<Radio color="primary" disableRipple={true} />}
                                    label={option.label}
                                    labelPlacement="start"
                                />
                            ))}
                        </RadioGroup>

                        <Divider fullWidth />

                        {!loading && (
                            <Form dontValidateOnMount="true" validateOnSubmit="true" onSubmit={this.handleSearchClick}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Grid container spacing={8} alignItems="flex-end">
                                            {this.state.searchType === "0" && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography gutterBottom>
                                                            Search for rotation templates using the options below. You
                                                            must select the exact crops in the rotation. Use the state,
                                                            CMZ, and tillage options to narrow the search results.
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Button
                                                            variant="raised"
                                                            fullWidth
                                                            onClick={() => this.handleOpenDialog("crops")}
                                                            className={classes.projectButton}>
                                                            Select Crops &nbsp;
                                                            <span style={{ color: "red" }}>(required)</span>
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Button
                                                            variant="raised"
                                                            fullWidth
                                                            onClick={() => this.handleOpenDialog("states")}
                                                            className={classes.projectButton}>
                                                            Select States
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Button
                                                            variant="raised"
                                                            fullWidth
                                                            onClick={() => this.handleOpenDialog("cmzs")}
                                                            className={classes.projectButton}>
                                                            Select CMZs
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Button
                                                            variant="raised"
                                                            fullWidth
                                                            onClick={() => this.handleOpenDialog("tillage")}
                                                            className={classes.projectButton}>
                                                            Select Tillage
                                                        </Button>
                                                    </Grid>

                                                    <Grid item xs={12}>
                                                        <Paper style={{ padding: "8px" }}>
                                                            <Typography variant="display2" gutterBottom color="primary">
                                                                Your Search Criteria:
                                                            </Typography>
                                                            <div className={classes.chips}>
                                                                {this.state.selectedCrops.map(a => (
                                                                    <Chip
                                                                        key={
                                                                            this.state.crops.filter(crop => {
                                                                                return crop.id === a;
                                                                            })[0]["id"]
                                                                        }
                                                                        label={
                                                                            this.state.crops.filter(crop => {
                                                                                return crop.id === a;
                                                                            })[0]["name"]
                                                                        }
                                                                        className={classes.chipDelete}
                                                                        onDelete={this.handleCropsChipDelete(a)}
                                                                    />
                                                                ))}

                                                                {this.state.cropContains === "0" && (
                                                                    <Chip
                                                                        key={
                                                                            options.cropContainsOptions.filter(
                                                                                cropContains => {
                                                                                    return (
                                                                                        cropContains.id ===
                                                                                        this.state.cropContains
                                                                                    );
                                                                                }
                                                                            )[0]["id"]
                                                                        }
                                                                        label={
                                                                            options.cropContainsOptions.filter(
                                                                                cropContains => {
                                                                                    return (
                                                                                        cropContains.id ===
                                                                                        this.state.cropContains
                                                                                    );
                                                                                }
                                                                            )[0]["label"]
                                                                        }
                                                                        className={classes.chip}
                                                                    />
                                                                )}

                                                                {this.state.cropContains === "1" && (
                                                                    <Chip
                                                                        key={
                                                                            options.cropContainsOptions.filter(
                                                                                cropContains => {
                                                                                    return (
                                                                                        cropContains.id ===
                                                                                        this.state.cropContains
                                                                                    );
                                                                                }
                                                                            )[0]["id"]
                                                                        }
                                                                        label={
                                                                            options.cropContainsOptions.filter(
                                                                                cropContains => {
                                                                                    return (
                                                                                        cropContains.id ===
                                                                                        this.state.cropContains
                                                                                    );
                                                                                }
                                                                            )[0]["label"]
                                                                        }
                                                                        className={classes.chipDelete}
                                                                        onDelete={this.handleCropContainsChipDelete}
                                                                    />
                                                                )}

                                                                {this.state.selectedStates.map(a => (
                                                                    <Chip
                                                                        key={
                                                                            this.state.stateOptions.filter(state => {
                                                                                return state.value === a;
                                                                            })[0]["value"]
                                                                        }
                                                                        label={
                                                                            this.state.stateOptions.filter(state => {
                                                                                return state.value === a;
                                                                            })[0]["label"]
                                                                        }
                                                                        className={classes.chipDelete}
                                                                        onDelete={this.handleStatesChipDelete(a)}
                                                                    />
                                                                ))}

                                                                {this.state.selectedCMZs.map(a => (
                                                                    <Chip
                                                                        key={
                                                                            this.state.cmzOptions.filter(cmz => {
                                                                                return cmz.id === a;
                                                                            })[0]["id"]
                                                                        }
                                                                        label={
                                                                            "CMZ " +
                                                                            this.state.cmzOptions.filter(cmz => {
                                                                                return cmz.id === a;
                                                                            })[0]["name"]
                                                                        }
                                                                        className={classes.chipDelete}
                                                                        onDelete={this.handleCMZChipDelete(a)}
                                                                    />
                                                                ))}

                                                                {this.state.selectedTillage === "all" && (
                                                                    <Chip
                                                                        key={this.state.selectedTillage}
                                                                        label={
                                                                            options.tillageOptions.filter(option => {
                                                                                return (
                                                                                    option.value ===
                                                                                    this.state.selectedTillage
                                                                                );
                                                                            })[0]["label"]
                                                                        }
                                                                        className={classes.chip}
                                                                    />
                                                                )}

                                                                {this.state.selectedTillage !== "all" && (
                                                                    <Chip
                                                                        key={this.state.selectedTillage}
                                                                        label={
                                                                            options.tillageOptions.filter(option => {
                                                                                return (
                                                                                    option.value ===
                                                                                    this.state.selectedTillage
                                                                                );
                                                                            })[0]["label"]
                                                                        }
                                                                        className={classes.chipDelete}
                                                                        onDelete={this.handleTillageChipDelete()}
                                                                    />
                                                                )}
                                                            </div>
                                                        </Paper>
                                                    </Grid>
                                                </>
                                            )}

                                            {this.state.searchType === "1" && (
                                                <>
                                                    <Grid item xs={12}>
                                                        <Typography gutterBottom>
                                                            Search for rotation templates using keywords.
                                                        </Typography>
                                                    </Grid>

                                                    <Grid item xs={12} md={8} lg={6}>
                                                        <TextField
                                                            field="keywords"
                                                            label="Keywords"
                                                            eventHandle={e =>
                                                                this.setState({ keyword_string: e.trim() })
                                                            }
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                </>
                                            )}

                                            <Grid item xs={12} />

                                            <Grid item xs={12} md={4} lg={3}>
                                                <Button
                                                    disabled={!searchReady}
                                                    color="primary"
                                                    variant="raised"
                                                    fullWidth
                                                    style={{ marginBottom: 8 }}
                                                    onClick={this.handleSearchClick}>
                                                    Search
                                                </Button>
                                            </Grid>

                                            <Grid item xs={12} md={4} lg={3}>
                                                <Button
                                                    color="primary"
                                                    variant="raised"
                                                    fullWidth
                                                    style={{ marginBottom: 8 }}
                                                    onClick={this.handleReturnClick}>
                                                    {this.getReturnButtonText()}
                                                </Button>
                                            </Grid>

                                            {this.props.mgmtState.results && (
                                                <Grid item xs={12} style={{ marginBottom: -32, marginTop: -24 }} />
                                            )}
                                        </Grid>
                                    </form>
                                )}
                            </Form>
                        )}

                        {this.state.cropError === false && this.state.crops.length === 0 && (
                            <Grid item xs={12}>
                                <div>
                                    <Typography>Loading Crops...</Typography>
                                    <Fade
                                        in={this.state.crops.length === 0}
                                        style={{ transitionDelay: this.props.mgmtState.pending ? "800ms" : "0ms" }}
                                        unmountOnExit>
                                        <LinearProgress variant="query" color="primary" />
                                    </Fade>
                                </div>
                            </Grid>
                        )}

                        {this.state.cropError === true && (
                            <Grid item xs={12}>
                                <div>
                                    <Typography>Could not load crops.</Typography>
                                </div>
                            </Grid>
                        )}
                    </Grid>

                    {this.state.newSearch === true && (
                        <React.Fragment>
                            <Grid item xs={12} className={this.props.mgmtState.results ? "" : classes.hideTable}>
                                <Typography variant="display4">Search Results</Typography>
                            </Grid>

                            <Grid item xs={12} className={this.props.mgmtState.results ? "" : classes.hideTable}>
                                <Typography>
                                    View (<RemoveRedEyeIcon color="primary" style={{ "vertical-align": "middle" }} />)
                                    the details of the crop rotation template or copy (
                                    <ContentCopyIcon color="primary" style={{ "vertical-align": "middle" }} />) the crop
                                    rotation template to your library. You can modify the crop rotation template once
                                    copied.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} className={this.props.mgmtState.results ? "" : classes.hideTable}>
                                <TemplateLibraryTable
                                    orderBy={"name"}
                                    columnData={columnData}
                                    data={searchResults}
                                    noRecordsMessage={"No search results."}
                                    onView={this.handleManagementPreviewClick}
                                    onCopy={this.handleCopySystemClick}
                                    rowsPerPage={25}
                                    useEmptyRows={false}
                                />
                            </Grid>

                            {this.props.mgmtState.pending && (
                                <Grid item xs={12}>
                                    <div>
                                        <Typography>Searching...</Typography>
                                        <Fade
                                            in={this.props.mgmtState.pending}
                                            style={{ transitionDelay: this.props.mgmtState.pending ? "800ms" : "0ms" }}
                                            unmountOnExit>
                                            <LinearProgress variant="query" color="primary" />
                                        </Fade>
                                    </div>
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                </Grid>
            </AppContainer>
        );
    }
}

RotationSearch = connect(
    state => ({
        mgmtState: (state.rotation && state.rotation.mgmt) || {}
    }),
    {
        ...rotationActions,
        ...RotationSystem.actions,
        ...RotationEvent.actions
    }
)(RotationSearch);

export default withStyles(styles)(withRouter(RotationSearch));
