import React, { Component } from "react";
import withStyles from "@mui/styles/withStyles";
import { Form } from "react-form";
import { connect } from "react-redux";

import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import Typography from "../common/TypographyWrapper";
import Divider from "@mui/material/Divider";

import AnnouncementIcon from "@mui/icons-material/Announcement";

import AppContainer from "../common/AppContainer";
import Checkbox from "../common/Checkbox";
import Label from "../common/Label";

import { CropYear, CROP_YEAR_COMPLETED_FOR_USER_DATA_EXPORT, FieldActivity } from "./fieldactivities/models";
import { Project } from "../project/models";

import { getValue } from "../../api/utils";
import { Fertilizer } from "./fieldactivities/activities/models";
import { Field } from "./models";
import { Metric } from "../../api/models";
import { dbFetch } from "../../api/fetch";
import ExportDataTable from "./ExportDataTable";

import { apiFetch } from "../../api/fetch";

// CSS in JS styles
const styles = theme => ({
    benchmarkToggle: {
        marginTop: "12px"
    },
    indicatorIcon: {
        width: "50px",
        height: "50px"
    },
    indicatorTitle: {
        marginLeft: "8px",
        marginTop: "12px"
    },
    borderButton: {
        border: "1px solid",
        marginTop: 16
    },
    linkColor: {
        color: theme.palette.primary.main
    },
    serverError: {
        display: "flex",
        backgroundColor: "#efe8e5",
        padding: "1em",
        marginBottom: "1em"
    },
    centerAlign: {
        textAlign: "center !important"
    }
});

const allProjects = Project.selectAll();

const allCropYears = CropYear.selectAll(cropYear => ({
    crop_name: cropYear.crop.name,
    farm_name: cropYear.field.farm.name,
    field_name: cropYear.field._fields.name,
    field_id: cropYear.field._fields.id,
    fieldObject: cropYear.field._fields,
    activities: cropYear.activities.all().toModelArray(),
    ...cropYear.ref,
    data_entry_complete: CROP_YEAR_COMPLETED_FOR_USER_DATA_EXPORT(cropYear)
}));

class ExportData extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            run_cropyear_sync: true,
            run_project_sync: true,
            run_api_version_sync: true,
            metrics_api_version: null,
            selected_cropyears: [],
            filters: {
                farmFilter: null,
                fieldFilter: null,
                yearFilter: null,
                cropFilter: null,
                dataStatusFilter: null,
                metricStatusFilter: null,
                metricVersionFilter: null,
                projectFilter: null
            },
            all_cropyears_selected: false,
            request_submitted: false,
            submitted_cropyear_ids: []
        };
    }
    bSwitch = {};

    setActiveYear(prevProps) {
        const {
            all_cropyears,
            all_projects,
            ormCropYearLoadDetail,
            ormFieldActivityLoadDetail,
            ormFertilizerLoadDetail,
            ormProjectLoadDetail
        } = this.props;

        const { run_cropyear_sync, run_project_sync, run_api_version_sync } = this.state;

        if (!all_cropyears) {
            return;
        }

        this.setState({
            run_cropyear_sync: false,
            run_project_sync: false,
            run_api_version_sync: false
        });

        if (run_cropyear_sync) {
            all_cropyears.forEach(cropYear => {
                if (!getValue(cropYear, "synced")) {
                    ormCropYearLoadDetail(cropYear.id);
                    if (cropYear.activities) {
                        cropYear.activities.forEach(function (activity) {
                            ormFieldActivityLoadDetail(activity.id);

                            activity.fertilizers.toModelArray().forEach(function (fertilizer) {
                                ormFertilizerLoadDetail(fertilizer.id);
                            });
                        });
                    }
                }
            });
        }

        if (run_project_sync) {
            all_projects.forEach(project => {
                ormProjectLoadDetail(project.id);
            });
        }

        if (run_api_version_sync) {
            apiFetch("/v4/calc/Version")
                .then(response => response.json())
                .then(responseJson => {
                    const version_alias = responseJson.alias;
                    const regex = /^\d+\.\d/;
                    const match = regex.exec(version_alias);
                    if (match) {
                        const metrics_api_version = match[0];
                        this.setState({ metrics_api_version });
                    }
                })
                .catch(error => {
                    console.log(error);
                    return ["error"];
                });
        }
    }

    getTableCropyears = () => {
        //  get all the cropyears
        var cropyears = this.getCropyears();

        //  filter the cropyears
        var filtered_cropyears = this.getFilteredCropyears(cropyears);

        //  update selected prop of filtered cropyears
        filtered_cropyears = this.updateSelectedCropyears(filtered_cropyears);

        return filtered_cropyears;
    };

    getCropyears = () => {
        const { all_cropyears } = this.props;

        if (all_cropyears.length === 0) return [];
        var cropyears = all_cropyears.map(c => {
            return {
                cropyear_id: c.id,
                farm: c.farm_name,
                field: c.field_name,
                year: c.year,
                crop: c.crop_name,
                data_status: c.data_entry_complete === false ? "Incomplete" : c.is_final ? "Final" : "Provisional",
                metric_status: c.metrics_version ? "Calculated" : "Not Calculated",
                metric_version: c.metrics_version || "",
                metric_version_match: c.metrics_version === this.state.metrics_api_version ? true : false,
                selected: false,
                project: c.project && c.project.project_id ? c.project.project_name : "",
                data_entry_complete: c.data_entry_complete
            };
        });

        return cropyears;
    };

    getFilteredCropyears = cropyears => {
        if (cropyears.length === 0) {
            return [];
        }

        const {
            farmFilter,
            fieldFilter,
            yearFilter,
            cropFilter,
            dataStatusFilter,
            metricStatusFilter,
            metricVersionFilter,
            projectFilter
        } = this.state.filters;
        if (farmFilter !== null)
            cropyears =
                farmFilter === "None"
                    ? cropyears.filter(c => c.farm === "")
                    : cropyears.filter(c => c.farm === farmFilter);
        if (fieldFilter !== null)
            cropyears =
                fieldFilter === "None"
                    ? cropyears.filter(c => c.field === "")
                    : cropyears.filter(c => c.field === fieldFilter);
        if (yearFilter !== null)
            cropyears =
                yearFilter === "None"
                    ? cropyears.filter(c => c.year === "")
                    : cropyears.filter(c => c.year === yearFilter);
        if (cropFilter !== null)
            cropyears =
                cropFilter === "None"
                    ? cropyears.filter(c => c.crop === "")
                    : cropyears.filter(c => c.crop === cropFilter);
        if (dataStatusFilter !== null)
            cropyears =
                dataStatusFilter === "None"
                    ? cropyears.filter(c => c.data_status === "")
                    : cropyears.filter(c => c.data_status === dataStatusFilter);
        if (metricStatusFilter !== null)
            cropyears =
                metricStatusFilter === "None"
                    ? cropyears.filter(c => c.metric_status === "")
                    : cropyears.filter(c => c.metric_status === metricStatusFilter);
        if (metricVersionFilter !== null)
            cropyears =
                metricVersionFilter === "None"
                    ? cropyears.filter(c => c.metric_version === "")
                    : cropyears.filter(c => c.metric_version === metricVersionFilter);
        if (projectFilter !== null)
            cropyears =
                projectFilter === "None"
                    ? cropyears.filter(c => c.project === "")
                    : cropyears.filter(c => c.project === projectFilter);

        return cropyears;
    };

    updateSelectedCropyears = filtered_cropyears => {
        const { all_cropyears_selected, selected_cropyears } = this.state;

        if (all_cropyears_selected) {
            filtered_cropyears.forEach(c => {
                c.selected = true;
            });
        } else {
            filtered_cropyears.forEach(c => {
                if (selected_cropyears.indexOf(c.cropyear_id) > -1) {
                    c.selected = true;
                }
            });
        }

        return filtered_cropyears;
    };

    handleSetFilter = (filter, value) => {
        var { filters } = this.state;
        filters[filter] = value;
        this.setState({ filters });
    };

    handleSelectAllCropyears = () => {
        const all_cropyears_selected = !this.state.all_cropyears_selected;

        if (all_cropyears_selected) {
            const cropyears = this.getCropyears(),
                filtered_cropyears = this.getFilteredCropyears(cropyears);
            const selected_cropyears = filtered_cropyears.map(c => c.cropyear_id);

            this.setState({
                all_cropyears_selected: !this.state.all_cropyears_selected,
                selected_cropyears
            });
        } else {
            this.setState({
                all_cropyears_selected: !this.state.all_cropyears_selected,
                selected_cropyears: []
            });
        }
    };

    handleSelectCropyear = cropyear_id => {
        var { selected_cropyears, all_cropyears_selected } = this.state;

        //  if select all is already selected, need to get all cropyears and filtered cropyears
        var cropyears = null;
        var filtered_cropyears = null;
        if (all_cropyears_selected) {
            cropyears = this.getCropyears();
            filtered_cropyears = this.getFilteredCropyears(cropyears);

            //  get all the cropyear ids from filtered_cropyears and remove cropyear_id
            var cropyear_ids = filtered_cropyears.map(c => c.cropyear_id).filter(id => id !== cropyear_id);
            this.setState({ selected_cropyears: cropyear_ids, all_cropyears_selected: false });
        } else {
            if (selected_cropyears.indexOf(cropyear_id) > -1) {
                selected_cropyears = selected_cropyears.filter(c => c !== cropyear_id);
            } else {
                selected_cropyears.push(cropyear_id);
            }
            this.setState({ selected_cropyears });
        }
    };

    checkAllSynced = (all_cropyears, all_projects) => {
        var synced = true;

        all_cropyears.forEach(cropyear => {
            if (cropyear["synced"] === undefined || cropyear["synced"] === false) {
                synced = false;
            }
        });

        all_projects.forEach(project => {
            if (project["synced"] === undefined || project["synced"] === false) {
                synced = false;
            }
        });

        return synced;
    };

    makeOptions = (cropyears, field) => {
        var values = [];
        var options = [];
        var add_none = false;

        if (field === "project") {
            cropyears.forEach(c => {
                var value = c[field];
                if (value === "") {
                    add_none = true;
                } else {
                    const project = this.props.all_projects.filter(p => p.name === c[field])[0];
                    if (values.indexOf(project.name) === -1) {
                        values.push(project.name);
                    }
                }
            });
        } else {
            cropyears.forEach(c => {
                var value = c[field];
                if (value === "") {
                    add_none = true;
                } else {
                    if (values.indexOf(value) === -1) {
                        values.push(value);
                    }
                }
            });
        }

        //  sort the options
        if (field === "year") {
            values = values.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? -1 : 1));
        } else {
            values = values.sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1));
        }

        //  create the options
        options = values.map(v => ({ label: v, value: v, key: v }));

        //  add clear filter option
        if (add_none) options.unshift({ label: "None", value: "None", key: "None" });

        //  add clear filter option
        options.unshift({ label: <b>Clear Filter</b>, value: null, key: null });

        return options;
    };

    getShowRecalculateQuestion = cropyears => {
        if (cropyears.filter(c => c.selected).length === 0) {
            return false;
        } else {
            var cropyears_to_reprocess = this.getCropyearsToRecalculate(cropyears);

            if (cropyears_to_reprocess.length > 0) {
                return true;
            }
        }
        return false;
    };

    getDisableSubmitButton = selected_cropyears => {
        if (this.state.submitted_cropyear_ids.length === 0) {
            return false;
        }

        const selected_cropyears_string = selected_cropyears
            .map(c => c.cropyear_id)
            .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
            .join(" ");
        const submitted_croyears_string = this.state.submitted_cropyear_ids
            .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
            .join(" ");
        if (selected_cropyears_string === submitted_croyears_string) {
            return true;
        }
        return false;
    };

    getCropyearsToRecalculate = cropyears => {
        //  filter the cropyears that qualify for reprocessing
        //  1.  filter for cropyears that are selected and data entry is complete
        //  2.  filter for cropyears that are Provisional
        //  3.  filter for cropyears that are Final and the metric version does match
        var cropyears_to_reprocess = cropyears
            .filter(c => c.selected)
            .filter(c => c.metric_version_match === false)
            .filter(c => c.data_entry_complete);
        cropyears_to_reprocess = [
            ...cropyears_to_reprocess.filter(c => c.data_status === "Provisional"),
            ...cropyears_to_reprocess
                .filter(c => c.data_status === "Final")
                .filter(c => c.metric_version_match === false)
        ];

        return cropyears_to_reprocess;
    };

    submitRequest = (selected_cropyears, options) => {
        var { history, authLogout } = this.props;
        var { recalculate, include_xlsx, include_pdf, include_shp, include_nrcs } = options;

        const report_cropyear_ids = selected_cropyears.map(c => c.cropyear_id);

        const recalcuate_cropyear_ids = recalculate
            ? this.getCropyearsToRecalculate(selected_cropyears).map(c => c.cropyear_id)
            : [];

        const data = {
            report_ids: report_cropyear_ids,
            recalculate_ids: recalcuate_cropyear_ids,
            include_xlsx,
            include_pdf,
            include_shp,
            include_nrcs
        };

        dbFetch("/reports/" + this.props.authState.user.id + "/user", {
            method: "POST",
            body: JSON.stringify(data)
        }).then(e => {
            if (e.status === 401) {
                history.push("/");
                authLogout();
            } else {
                e.json().then(data => {
                    console.log(data);
                });
            }
        });
        const submitted_cropyear_ids = selected_cropyears.map(c => c.cropyear_id);
        this.setState({ request_submitted: true, submitted_cropyear_ids });
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.setActiveYear({});
    }

    render() {
        const { classes, all_cropyears, all_projects } = this.props;
        const { all_cropyears_selected, request_submitted } = this.state;

        const synced = this.checkAllSynced(all_cropyears, all_projects);

        const cropyears = synced ? this.getCropyears() : [];
        const table_cropyears = synced ? this.getTableCropyears() : [];
        const selected_cropyears = table_cropyears.filter(c => c.selected);

        const farmOptions = this.makeOptions(cropyears, "farm");
        const fieldOptions = this.makeOptions(cropyears, "field");
        const yearOptions = this.makeOptions(cropyears, "year");
        const cropOptions = this.makeOptions(cropyears, "crop");
        const dataStatusOptions = this.makeOptions(cropyears, "data_status");
        const metricStatusOptions = this.makeOptions(cropyears, "metric_status");
        const metricVersionOptions = this.makeOptions(cropyears, "metric_version");
        const projectOptions = this.makeOptions(cropyears, "project");

        const showRecalculateQuestion = this.getShowRecalculateQuestion(table_cropyears);
        const disableSubmitButton = this.getDisableSubmitButton(selected_cropyears);

        return (
            <AppContainer authenticated synced={!synced} color="field" title="Export Data">
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="title" gutterBottom>
                            Data Export
                        </Typography>
                        <Divider style={{ marginBottom: "12px" }} />
                        <Typography gutterBottom>
                            Use the data export function to download your input data and Fieldprint Results in a single
                            spreadsheet. This can be useful to store, transfer or evaluate your data across all fields.
                            Filter the report using the filters below. Once you request the report, the Platform will
                            send an email with a link to download the file.
                        </Typography>
                        <Typography gutterBottom>
                            The current Metric Version is {this.state.metrics_api_version || "[loading...]"}.
                        </Typography>
                        <Typography>
                            <strong>
                                Partnering with Field to Market and USDA National Resource Conservation Service (NRCS)
                                in Conservation Planning
                            </strong>
                        </Typography>
                        <Typography gutterBottom>
                            Your downloaded data can used by conservation planners as input to tools like NRCS
                            Conservation Application Ranking Tool (CART), for faster evaluation and assessment. Check
                            all data export options including the option to download your boundary files and the
                            Fieldprint Platform to CART Data Crosswalk. Your data will come in a compressed file format
                            (.zip) and can be emailed or uploaded to{" "}
                            <a
                                className={classes.linkColor}
                                href="https://farmers.gov"
                                target="_blank"
                                rel="noopener noreferrer">
                                Farmers.gov
                            </a>
                            . Learn more by downloading the{" "}
                            <a
                                className={classes.linkColor}
                                href="https://calculator.fieldtomarket.org/media/Field-to-Market-NRCS-Interoperability-Project.pdf"
                                target="_blank"
                                rel="noopener noreferrer">
                                Field to Market NRCS Interoperability Project Handout
                            </a>
                            .
                        </Typography>
                    </Grid>

                    {synced && (
                        <Grid item xs={12}>
                            <ExportDataTable
                                cropyears={table_cropyears}
                                onSetFilter={(a, b) => this.handleSetFilter(a, b)}
                                onSelectAllCropyears={() => this.handleSelectAllCropyears()}
                                onSelectCropyear={cropyear_id => this.handleSelectCropyear(cropyear_id)}
                                hasFilters={false}
                                farmOptions={farmOptions}
                                fieldOptions={fieldOptions}
                                yearOptions={yearOptions}
                                cropOptions={cropOptions}
                                dataStatusOptions={dataStatusOptions}
                                metricStatusOptions={metricStatusOptions}
                                metricVersionOptions={metricVersionOptions}
                                // metricVersionOptions={this.makeOptions([
                                //     { name: "Current Version", id: "current" },
                                //     { name: "Old Version", id: "old" },
                                //     { name: "Not Calculated", id: "none" }
                                // ])}
                                projectOptions={projectOptions}
                                allSelected={all_cropyears_selected}
                                hasCropyears={cropyears.length > 0}
                                hasFilteredCropyears={cropyears.length > 0 && table_cropyears.length > 0}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <Form
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            defaultValues={{ include_xlsx: true, recalculate: true }}
                            onSubmit={values => this.submitRequest(selected_cropyears, values)}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    {showRecalculateQuestion && (
                                        <>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ float: "left" }}>
                                                    <AnnouncementIcon
                                                        color="primary"
                                                        style={{ marginBottom: "13px", marginRight: "20px" }}
                                                    />
                                                </div>
                                                <div style={{ float: "left" }}>
                                                    <Typography color="primary">
                                                        Not all data requested for export was calculated on the most
                                                        recent metrics engine in the Platform. This can happen when your
                                                        crop years were calculated prior to a new Platform update.
                                                        Uncheck the box below if you prefer not to have the results
                                                        refreshed.
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ float: "left", width: "44px" }} />
                                                <div style={{ float: "left" }}>
                                                    <Checkbox
                                                        field="recalculate"
                                                        label="Recalculate results on the most recent version of the Platform metrics engine."
                                                        fullWidth
                                                        align={classes.centerAlign}
                                                        color="secondary"
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <Typography variant="title" gutterBottom>
                                        Select Data Export Options
                                    </Typography>
                                    <Checkbox
                                        field="include_xlsx"
                                        label={
                                            <>
                                                <Format>XSLX</Format>
                                                Comprehensive Data Output File
                                            </>
                                        }
                                        fullWidth
                                        color="secondary"
                                    />
                                    <Checkbox
                                        field="include_pdf"
                                        label={
                                            <>
                                                <Format>PDF</Format>
                                                Fieldprint Analysis Reports
                                            </>
                                        }
                                        fullWidth
                                        color="secondary"
                                    />
                                    <Checkbox
                                        field="include_shp"
                                        label={
                                            <>
                                                <Format>SHP</Format>
                                                Field Boundaries
                                            </>
                                        }
                                        fullWidth
                                        color="secondary"
                                    />
                                    <Checkbox
                                        field="include_nrcs"
                                        label={
                                            <>
                                                <Format>XSLX</Format>
                                                CART Data Crosswalk for Fieldprint Platform Data
                                            </>
                                        }
                                        fullWidth
                                        color="secondary"
                                    />
                                    {selected_cropyears.length > 0 && (
                                        <Button
                                            type="submit"
                                            disabled={disableSubmitButton}
                                            color="primary"
                                            variant="raised">
                                            Request Report
                                        </Button>
                                    )}
                                </form>
                            )}
                        </Form>
                    </Grid>

                    {request_submitted && (
                        <Grid item xs={12}>
                            <Typography>
                                Your Project report is being submitted to the queue! When ready, you will be sent an
                                email with a link to download the report in a compressed format (.zip). This can take up
                                to an hour, depending on the number of crop years included. Please check your spam
                                folder if you do not receive the email.
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </AppContainer>
        );
    }
}

ExportData = connect(
    (state, ownProps) => ({
        all_cropyears: allCropYears(state, ownProps),
        all_projects: allProjects(state, ownProps),
        syncState: state.sync,
        authState: state.auth
    }),
    {
        ...Metric.actions,
        ...CropYear.actions,
        ...FieldActivity.actions,
        ...Fertilizer.actions,
        ...Field.actions,
        ...Project.actions
    }
)(ExportData);

export default withStyles(styles)(ExportData);

function Format({ children }) {
    return <Label color="darkgreen">{children}</Label>;
}
