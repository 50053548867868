import orm, { ReferenceData, Model } from "../../common/orm";
import { fk } from "redux-orm";
import { setValue, getValue } from "../../../api/utils";

export class Crops extends ReferenceData {
    static get modelName() {
        return "Crops";
    }
}
orm.register(Crops);

export class DryBeansVarieties extends ReferenceData {
    static get modelName() {
        return "DryBeansVarieties";
    }
}
orm.register(DryBeansVarieties);

export class CropYear extends Model {
    static get fields() {
        return {
            field: fk("Field", "cropyears"),
            crop: fk("Crops", "cropyears"),
            bean_variety: fk("DryBeansVarieties", "cropyears"),
            project_instance: fk("Project", "cropyears"),
            rotation: fk("RotationSystem", "cropyears")
        };
    }
    static get modelName() {
        return "CropYear";
    }
    static generateCode() {
        return null;
    }
    static generateId(payload) {
        if (!payload.field || !payload.year) {
            throw new Error("Field and year required");
        }
        return `${payload.field}-${payload.year}`;
    }
    static toRequest(data) {
        var req = super.toRequest(data);
        if (req.hasOwnProperty("project_instance_id")) {
            setValue(req, "project.project_id", req.project_instance_id);
            delete req.project_instance_id;
        }
        if (req.hasOwnProperty("rotation_id")) {
            setValue(req, "soils.rotation_id", req.rotation_id);
            delete req.rotation_id;
        }

        return req;
    }

    static fromResponse(data) {
        const project_id = getValue(data, "project.project_id"),
            rotation_id = getValue(data, "soils.rotation_id");

        data.project_instance_id = project_id;
        data.rotation_id = rotation_id;
        return super.fromResponse(data);
    }
}
orm.register(CropYear);

// Determine if every step in the crop year has been saved and completed
export function CROP_YEAR_COMPLETED(cropYear, index, check_started) {
    const completed = getValue(cropYear, "extrainfo.completed");
    const is_final = getValue(cropYear, "is_final");

    // To accomodate migrated data from v2.5 use is_final flag to determine if complete
    if (is_final) return true;

    // Check if a specific step is completed
    if (index || index === 0) {
        // For the review step check if its complete by looking at the is_final flag instead of completed
        if (index === 3) {
            // Return true if not started for special cases when we don't want to flag it for first time uses
            if (
                check_started &&
                (!completed || completed[0] === undefined || completed[1] === undefined || completed[2] === undefined)
            ) {
                return true;
            }

            const is_final = getValue(cropYear, "is_final");
            if (is_final === true) {
                return true;
            }
            return false;
        }

        // Return true if not started for special cases when we don't want to flag it for first time uses
        if (check_started && (!completed || completed[index] === undefined)) {
            return true;
        }

        if (index === 3) {
            if (completed && completed[index] && completed[index] === true) {
                //&& survey_m === 0) {
                return true;
            }
        } else {
            if (completed && completed[index] && completed[index] === true) {
                return true;
            }
        }
        return false;
    }

    // Check if every step is complete
    if (
        completed &&
        completed[0] &&
        completed[0] === true &&
        completed[1] &&
        completed[1] === true &&
        completed[2] &&
        completed[2] === true //&&
        //survey_m === 0
    ) {
        return true;
    }
    return false;
}

// Determine if every step in the crop year has been saved and completed
export function CROP_YEAR_COMPLETED_FOR_USER_DATA_EXPORT(cropYear) {
    const completed = getValue(cropYear, "extrainfo.completed");

    // Check if every step is complete
    if (
        completed &&
        completed[0] &&
        completed[0] === true &&
        completed[1] &&
        completed[1] === true &&
        completed[2] &&
        completed[2] === true
    ) {
        return true;
    }
    return false;
}

export const ACTIVITY_TYPES = {
    planting: "Planting",
    harvest: "Harvesting",
    irrigation: "Irrigation",
    application: "Commercial Fertilizer and Protectant Trip",
    manure: "Fertilizer Trip"
};

export const ALLOW_MULTIPLE = {
    application: true,
    manure: true,
    harvest: true
};

export class FieldActivity extends Model {
    static get fields() {
        return {
            cropyear: fk("CropYear", "activities")
        };
    }
    static get modelName() {
        return "FieldActivity";
    }
    static get djangoModelName() {
        return "Activity";
    }
    static get pluralName() {
        return "fieldactivities";
    }
    static generateCode(payload, state) {
        const session = orm.session(state.orm);
        if (!payload.cropyear || !payload.type) {
            this.fail("Crop year and activity type required!");
        }
        let nextCode = payload.type;
        let suffix = 0;
        if (ALLOW_MULTIPLE[payload.type]) {
            while (
                nextCode === payload.type ||
                session.FieldActivity.filter({
                    cropyear: payload.cropyear,
                    code: nextCode
                }).count() > 0
            ) {
                suffix++;
                nextCode = `${payload.type}${suffix}`;
            }
        } else {
            if (
                session.FieldActivity.filter({
                    cropyear: payload.cropyear,
                    code: nextCode
                }).count() > 0
            ) {
                this.fail(`Only one ${ACTIVITY_TYPES[payload.type]} allowed!`);
            }
        }
        return nextCode;
    }
}
orm.register(FieldActivity);

export class NBalanceReferenceData extends ReferenceData {
    static get source() {
        throw new Error("Static data");
        // return `/v4/calc/NBalance/ReferenceData/${this.modelName}`;
    }
}

export class TileDrainage extends ReferenceData {
    static get modelName() {
        return "TileDrainage";
    }
}
orm.register(TileDrainage);

export class ConservationPractices extends ReferenceData {
    static get modelName() {
        return "ConservationPractices";
    }
}
orm.register(ConservationPractices);

export class IntegratedPestManagement extends ReferenceData {
    static get modelName() {
        return "IntegratedPestManagement";
    }
}
orm.register(IntegratedPestManagement);

export class WildlifeHabitat extends ReferenceData {
    static get modelName() {
        return "WildlifeHabitat";
    }
}
orm.register(WildlifeHabitat);

export class TillageClasses extends ReferenceData {
    static get modelName() {
        return "TillageClasses";
    }
}
orm.register(TillageClasses);

export class LandConversionTypes extends ReferenceData {
    static get modelName() {
        return "LandConversionTypes";
    }
}
orm.register(LandConversionTypes);

export class Nutrient4RS extends ReferenceData {
    static get modelName() {
        return "Nutrient4RS";
    }
}
orm.register(Nutrient4RS);

export class PredominantRotationPractices extends ReferenceData {
    static get modelName() {
        return "PredominantRotationPractices";
    }
}
orm.register(PredominantRotationPractices);

export class ResidueCovers extends ReferenceData {
    static get modelName() {
        return "ResidueCovers";
    }
}
orm.register(ResidueCovers);

export class RiceWaterRegime extends ReferenceData {
    static get modelName() {
        return "RiceWaterRegime";
    }
}
orm.register(RiceWaterRegime);

export class PreviousCrops extends ReferenceData {
    static get modelName() {
        return "PreviousCrops";
    }
}
orm.register(PreviousCrops);

export class CoverCropSpecies extends ReferenceData {
    static get modelName() {
        return "CoverCropSpecies";
    }
}
orm.register(CoverCropSpecies);

export class CoverCropEstablishTimings extends ReferenceData {
    static get modelName() {
        return "CoverCropEstablishTimings";
    }
}
orm.register(CoverCropEstablishTimings);

export class CoverCropTypes extends NBalanceReferenceData {
    static get modelName() {
        return "CoverCropTypes";
    }
}
orm.register(CoverCropTypes);

export class CoverCropLegumeTypes extends NBalanceReferenceData {
    static get modelName() {
        return "CoverCropLegumeTypes";
    }
}
orm.register(CoverCropLegumeTypes);

export class CoverCropGrowth extends NBalanceReferenceData {
    static get modelName() {
        return "CoverCropGrowth";
    }
}
orm.register(CoverCropGrowth);

export class ManagementTechniques extends ReferenceData {
    static get modelName() {
        return "ManagementTechniques";
    }
}
orm.register(ManagementTechniques);

export class PMultiYearApp extends ReferenceData {
    static get modelName() {
        return "PMultiYearApp";
    }
}
orm.register(PMultiYearApp);

export class PSoilTestLevel extends ReferenceData {
    static get modelName() {
        return "PSoilTestLevel";
    }
}
orm.register(PSoilTestLevel);
