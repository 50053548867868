import React, { Component } from "react";
import Grid from "../../common/GridWrapper";
import Typography from "../../common/TypographyWrapper";
import withStyles from "@mui/styles/withStyles";

const styles = theme => ({
    card: {
        border: "12px solid #ffffff"
    },
    cardActions: {
        position: "relative",
        backgroundColor: "#efefef",
        "& div": {
            position: "absolute",
            top: 0,
            right: 0
        },
        "& svg": {
            color: "black"
        }
    },
    cardPadding: {
        padding: "10px 15px",
        backgroundColor: "#efefef",
        "& p": {
            color: "black"
        }
    },
    alignCenter: {
        textAlign: "center"
    },
    whiteTextThick: {
        color: "#ffffff",
        fontWeight: "bold"
    },
    whiteText: {
        color: "#fcf8f8"
    },
    practice: {
        display: "inline-flex",
        "& div": {
            maxWidth: "%33.33333",
            marginRight: 10
        }
    }
});

class Cards extends Component {
    GetColor = (data, id) => {
        const { startIndex } = this.props;
        const colors = [
            "#8591ee",
            "#ffb93c",
            "#a85cd5",
            "#5a50af",
            "#3aaaaa",
            "#ff4fa0",
            "#e43e24",
            "#afd200",
            "#328282",
            "#ff7d32"
        ];

        const index = data.findIndex(x => x.id === id);
        if (index > -1) {
            return colors[index + startIndex];
        } else {
            return "#efefef";
        }
    };

    CoeffiecientLabel = c => {
        if (c === -999) {
            return "No Data";
        } else {
            return (
                <>
                    {Math.round((c + Number.EPSILON) * 100) / 100} CO<sub>2</sub>e / ac / yr
                </>
            );
        }
    };

    render() {
        const { classes, data } = this.props;

        return (
            <Grid container spacing={12} alignItems="center">
                {data
                    .sort((a, b) => a - b)
                    .map((x, index) => (
                        <Grid key={index} item xs={12}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={1}
                                    className={classes.cardPadding}
                                    style={{ borderLeft: `.6rem solid ${this.GetColor(data, x.id)}` }}>
                                    <Typography className={classes.whiteTextThick}>Year</Typography>
                                    <Typography className={classes.whiteText}>{x.year}</Typography>
                                </Grid>
                                <Grid item xs={11} className={classes.cardPadding} style={{ minHeight: 180 }}>
                                    <Typography className={classes.whiteTextThick}>
                                        Conservation Practice Class
                                    </Typography>
                                    <Typography className={classes.whiteText}>{x.practice_class}</Typography>
                                    <Typography className={classes.whiteTextThick}>
                                        Conservation Practice Standard (CPS)
                                    </Typography>
                                    <Typography className={classes.whiteText}>{x.cps_name}</Typography>
                                    <Typography className={classes.whiteTextThick}>
                                        Conservation Practice Implementation
                                    </Typography>
                                    <Typography className={classes.whiteText}>{x.planner_implementation}</Typography>
                                    <Typography className={classes.whiteTextThick}>
                                        Practice Emission Reduction Coefficients
                                    </Typography>
                                    <Typography className={classes.whiteText}>
                                        Soil Carbon: {this.CoeffiecientLabel(x.carbon)}
                                    </Typography>
                                    <Typography className={classes.whiteText}>
                                        N<sub>2</sub>O: {this.CoeffiecientLabel(x.nitrous)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
            </Grid>
        );
    }
}

export default withStyles(styles)(Cards);
