import React, { Component } from "react";
import { Form } from "react-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../common/withRouter";

import withStyles from "@mui/styles/withStyles";

import Button from "../common/ButtonWrapper";
import Grid from "../common/GridWrapper";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "../common/TypographyWrapper";

import ProjectTeam from "./components/ProjectTeam";
import AppContainer from "../common/AppContainer";
import TextField from "../common/TextField";
import Checkbox from "../common/Checkbox";
import { Project, ProjectUser } from "./models";
import { ProjectRotationSystem } from "../rotation/models";

// CSS in JS styles
const styles = theme => ({
    centerAlign: {
        textAlign: "center"
    },
    center: {
        marginLeft: "auto",
        marginRight: "auto"
    },
    marginTop: {
        marginTop: "31px"
    },
    optin: {
        backgroundColor: "#eeeeee",
        border: "1px solid grey",
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2)
    },
    linkColor: {
        color: "#808080"
    },
    floatRight: {
        float: "right"
    },
    inline: {
        display: "inline"
    },
    progress: {
        color: "#4CAF50",
        position: "absolute",
        marginLeft: 30,
        marginTop: -30
    },
    error: {
        color: "red"
    },
    crumbColor: {
        color: theme.palette.primary.main
    }
});

class ProjectOpt extends Component {
    state = {
        loading: false,
        message: null,
        project: null
    };

    validateCode = values => {
        const pOpt = this;
        this.props.checkCode(values, function (state) {
            if (state.pending) {
                pOpt.setState({ loading: true, message: null, project: null });
            } else {
                if (state.success && state.result.length > 0) {
                    pOpt.setState({ loading: false, message: null, project: state.result[0] });
                } else {
                    pOpt.setState({
                        loading: false,
                        message: "Project not found or you are already a member of that project",
                        project: null
                    });
                }
            }
        });
    };

    keyValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        return {
            code: isRequired(values.code)
        };
    };

    optIn = () => {
        const { ormProjectUserCreate, ormProjectCreateLocalOnly, authState, ormProjectRotationSystemReload } =
            this.props;
        const { project } = this.state;
        ormProjectUserCreate({
            project: project.id,
            user: authState.user.id
        });
        ormProjectCreateLocalOnly(project);
        this.props.history.push("/project");
        setTimeout(ormProjectRotationSystemReload, 1000);
    };

    checkValidator = values => {
        const isRequired = val => {
            return !val ? "Required" : null;
        };
        return {
            confirm: isRequired(values.confirm)
        };
    };

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const {
            classes
            //project,
        } = this.props;
        const { project, loading, message } = this.state;

        return (
            <AppContainer
                authenticated
                color="project"
                title="Projects"
                pageTitle="Project Opt-In"
                crumbs={
                    <div>
                        <Link className={classes.linkColor} to={"/project"}>
                            Project Dashboard
                        </Link>
                        &nbsp;&nbsp;&nbsp;&gt;&nbsp;&nbsp;&nbsp; <span className={classes.crumbColor}>Opt-In</span>
                    </div>
                }>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography>
                            Your ability to associate fields with a Project is controlled through a Project ID and
                            Project Code. This information is provided by the Project Administrator. If you have not
                            recieved the ID or Code, or have other questions, please contact{" "}
                            <a className={classes.linkColor} href="mailto:projects@fieldtomarket.org">
                                projects@fieldtomarket.org
                            </a>
                            . If you have a ID and code, enter it below. This will display the Project Team and ask you
                            to confirm Opting In to the Project.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Form
                            dontValidateOnMount="true"
                            validateOnSubmit="true"
                            onSubmit={this.validateCode}
                            validateError={this.keyValidator}>
                            {formApi => (
                                <form onSubmit={formApi.submitForm}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={6} md={4} lg={3} xl={2}>
                                            <TextField field="code" label="Project Key" margin="normal" fullWidth />
                                        </Grid>
                                        <Grid item xs={6} md={4} lg={3} xl={2}>
                                            <Button
                                                variant="raised"
                                                type="submit"
                                                color="primary"
                                                disabled={loading}
                                                className={classes.marginTop}>
                                                Next
                                            </Button>
                                            &nbsp;&nbsp;&nbsp;
                                            <Button
                                                color="primary"
                                                component={Link}
                                                to="/project"
                                                className={classes.marginTop}>
                                                Cancel
                                            </Button>
                                            {loading && (
                                                <div>
                                                    <CircularProgress size={24} className={classes.progress} />
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            {message && <Typography className={classes.error}>{message}</Typography>}
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        </Form>
                    </Grid>
                    {project && (
                        <Grid item xs={12}>
                            <Button variant="raised" color="secondary">
                                {project.name}
                            </Button>
                        </Grid>
                    )}
                    {project && (
                        <Grid item xs={12}>
                            <Typography variant="display3">Project Team</Typography>
                        </Grid>
                    )}
                    {project && (
                        <Grid item xs={12}>
                            <ProjectTeam project={project} />
                        </Grid>
                    )}
                    {project && (
                        <Grid item xs={12}>
                            <Typography variant="display3">Opt-In to Project</Typography>
                        </Grid>
                    )}
                    {project && (
                        <Grid item xs={9} className={classes.center}>
                            <Form
                                dontValidateOnMount="true"
                                validateOnSubmit="true"
                                onSubmit={this.optIn}
                                validateError={this.checkValidator}>
                                {formApi => (
                                    <form onSubmit={formApi.submitForm}>
                                        <Typography gutterBottom className={classes.optin}>
                                            We want users to fully understand when Platform options impact data privacy.
                                            As noted in the&nbsp;
                                            <a
                                                className={classes.linkColor}
                                                rel="noopener noreferrer"
                                                href="https://fieldtomarket.org/privacy-policy/"
                                                target="_blank">
                                                Data and Privacy Policy
                                            </a>
                                            , associating fields with a Project means your Fieldprint Data and
                                            Fieldprint Results can be seen and analyzed by the Project Lead / Primary
                                            Contact, as well as Project Partners, Administrators, and Specialists. Your
                                            data will be used to generate aggregated and anonymized reports for
                                            organizational reporting and/or Project Claims and Verification. Field
                                            associations can be removed, or you can fully Opt-Out of a Project at any
                                            time. However, once data has been aggregated and anonymized, it cannot be
                                            deleted.
                                            <div className={classes.centerAlign}>
                                                <Checkbox field="confirm" label="" align={classes.inline} />
                                                <br />
                                                We ask that you check the box to fully acknowledge that your are opting
                                                in to these associations.
                                            </div>
                                        </Typography>
                                        <Button color="primary" component={Link} to="/project">
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="raised"
                                            color="primary"
                                            type="submit"
                                            className={classes.floatRight}>
                                            I agree to Opt-In
                                        </Button>
                                    </form>
                                )}
                            </Form>
                        </Grid>
                    )}
                </Grid>
            </AppContainer>
        );
    }
}

ProjectOpt = connect(
    state => ({
        authState: state.auth
    }),
    {
        ...Project.actions,
        ...ProjectUser.actions,
        ...ProjectRotationSystem.actions
    }
)(ProjectOpt);

export default withStyles(styles)(withRouter(ProjectOpt));
