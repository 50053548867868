import { swRegistered, swInstalled, swUpdate, swError, swDisabled } from "./components/common/actions";

// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export default function register(dispatch) {
    if (!isLocalhost && "serviceWorker" in navigator) {
        const ready = () => {
            const swUrl = `${window.location.origin}/service-worker.js`;

            if (isLocalhost) {
                // This is running on localhost. Lets check if a service worker still exists or not.
                checkValidServiceWorker(swUrl, dispatch);
            } else {
                // Is not local host. Just register service worker
                registerValidSW(swUrl, dispatch);
            }
            checkForUpdates();
        };

        if (navigator.serviceWorker.controller) {
            // Already installed, register event ASAP to catch update
            ready();
        } else {
            // First load - let app init before spinning up worker
            window.addEventListener("load", ready);
        }
    } else {
        let reason;
        if ("serviceWorker" in navigator) {
            reason = "dev build";
        } else {
            reason = "not supported";
        }
        dispatch(swDisabled(reason));
    }
}

function registerValidSW(swUrl, dispatch) {
    navigator.serviceWorker
        .register(swUrl)
        .then(registration => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                installingWorker.onstatechange = () => {
                    // FPP customization: dispatch redux event
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            dispatch(swUpdate());
                        } else {
                            dispatch(swInstalled());
                        }
                    }
                };
            };
            dispatch(swRegistered());
        })
        .catch(error => {
            dispatch(swError(error));
        });
}

function checkValidServiceWorker(swUrl, dispatch) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl)
        .then(response => {
            // Ensure service worker exists, and that we really are getting a JS file.
            if (response.status === 404 || response.headers.get("content-type").indexOf("javascript") === -1) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then(registration => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, dispatch);
            }
        })
        .catch(() => {
            console.log("No internet connection found. App is running in offline mode.");
        });
}

export function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister();
        });
    }
}

function checkForUpdates() {
    // Check every 10 minutes to see if there is a version update.
    setInterval(
        () => {
            navigator.serviceWorker.ready.then(reg => {
                reg.update();
            });
        },
        10 * 60 * 1000
    );
}
