import React, { Component } from "react";

import withStyles from "@mui/styles/withStyles";
import { Table, TableBody, TableRow, TableFooter, TablePagination } from "@mui/material";
import CustomTableCell from "../../common/TableCell";
import EnhancedTableHead from "../../common/EnhancedTableHead";
import TablePaginationActionsWrapped from "../../common/Paginator";

const styles = theme => ({
    centerAlign: {
        textAlign: "center"
    },
    body1: {
        fontWeight: 400,
        fontSize: 14,
        color: "#666666"
    }
});

const columnData = [
    { id: "organization_label", numeric: false, label: "Organization", allowSort: true, width: "25%" },
    { id: "organization_role_label", numeric: false, label: "Organization Role", allowSort: true, width: "25%" },
    { id: "contact_name", numeric: false, label: "Contact", allowSort: true, width: "25%" },
    { id: "contact_role_label", numeric: false, label: "Contact Role", allowSort: true, width: "25%" }
];

function buildParticipantTable(project) {
    // Basically we need the project_organization.organization_role_label matched with project_participant organization
    // And also add any organizations from project_organization that do not have a participant
    const organizations = {};
    for (const po of project.project_organization) {
        organizations[po.organization_id] = { ...po, has_contact: false };
    }
    return project.contacts
        .map(contact => {
            const po = organizations[contact.organization_id];
            if (po) {
                po.has_contact = true;
            }
            return { ...contact, organization_role_label: po ? po.organization_role_label : "-" };
        })
        .concat(
            Object.values(organizations)
                .filter(po => !po.has_contact)
                .map(po => ({
                    organization_label: po.organization_label,
                    organization_role_label: po.organization_role_label,
                    contact_name: "-",
                    contact_role_label: "-"
                }))
        )
        .sort((a, b) => (a["organization_label"] < b["organization_label"] ? -1 : 1));
}

class ProjectTeam extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "asc",
            orderBy: "organization_label",
            page: 0,
            rowsPerPage: 10
        };
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.participants.sort((a, b) => (b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1))
            : this.props.participants.sort((a, b) => (a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1));

        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    render() {
        const { project, classes } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;
        const participants = buildParticipantTable(project);
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, participants.length - page * rowsPerPage);

        return (
            <Table>
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    columnData={columnData}
                />
                <TableBody>
                    {participants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(team => (
                        <TableRow hover key={team.id}>
                            <CustomTableCell>{team.organization_label}</CustomTableCell>
                            <CustomTableCell>{team.organization_role_label}</CustomTableCell>
                            <CustomTableCell>{team.contact_name}</CustomTableCell>
                            <CustomTableCell>{team.contact_role_label}</CustomTableCell>
                        </TableRow>
                    ))}
                    {emptyRows > 0 && participants.length > 10 && (
                        <TableRow style={{ height: 48 * emptyRows }}>
                            <CustomTableCell colSpan={4} />
                        </TableRow>
                    )}
                    {participants.length < 1 && (
                        <TableRow>
                            <CustomTableCell colSpan={4} className={classes.centerAlign}>
                                No Team Members Found
                            </CustomTableCell>
                        </TableRow>
                    )}
                </TableBody>
                {participants.length > 10 && (
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={4}
                                count={participants.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                classes={{
                                    caption: classes.body1
                                }}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActionsWrapped}
                            />
                        </TableRow>
                    </TableFooter>
                )}
            </Table>
        );
    }
}

export default withStyles(styles)(ProjectTeam);
