import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Button from "../common/ButtonWrapper";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableRow } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import withStyles from "@mui/styles/withStyles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LockIcon from "@mui/icons-material/Lock";

import AddCropYear from "./AddCropYear";
import { CROP_YEAR_COMPLETED } from "./fieldactivities/models";
import CustomTableCell from "../common/TableCell";
import EnhancedTableHead from "../common/EnhancedTableHead";
import WarningDialog from "../common/WarningDialog";
import { getValue } from "../../api/utils";

import { createSelector } from "../common/orm";

const getFertilizerCounts = createSelector(
    (state, ownProps) => ownProps.cropYears,
    (session, cropYears) => {
        var fertilizers = {};
        cropYears.forEach(cropYear => {
            var cy_fertilizers = [];
            cropYear.activitiesLoad.forEach(a => {
                if (a.type === "application") {
                    const trip_fertilizers = session.Fertilizer.filter({ activity: a.id }).toRefArray();
                    cy_fertilizers = [...cy_fertilizers, ...trip_fertilizers];
                }
            });
            fertilizers[cropYear.id] = cy_fertilizers.length;
        });

        return fertilizers;
    }
);

const styles = theme => ({
    root: {
        width: "100%",
        overflowX: "auto"
    },
    table: {
        minWidth: 500
    },
    centerAlign: {
        textAlign: "center"
    },
    deleteWidth: {
        minWidth: "34px",
        width: "34px"
    },
    progressBar: {
        backgroundColor: "grey",
        borderRadius: 13,
        marginLeft: 35
    },
    progressDiv: {
        backgroundColor: theme.palette.primary.main,
        height: 20,
        borderRadius: 10
    },
    floatLeft: {
        float: "left"
    },
    gutterBottom: {
        marginBottom: ".6em"
    },
    analyticsTooltip: {
        // Tooltips don't work on disabled buttons without div, style div as button
        display: "inline-block",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
});

const columnData = [
    { id: "actions", numeric: false, label: "Options", allowSort: false },
    { id: "year", numeric: false, label: "Year", allowSort: true },
    { id: "crop", numeric: false, label: "Crop", allowSort: true },
    { id: "project", numeric: false, label: "In Project?", allowSort: false },
    {
        id: "updatedate",
        numeric: false,
        label: (
            <div>
                Last
                <br />
                Update
            </div>
        ),
        allowSort: true
    },
    { id: "completed", numeric: false, label: "Data Entry Progress", allowSort: false },
    { id: "is_final", numeric: false, label: "Data Status?", allowSort: false }
];

class CropYearTable extends Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            order: "desc",
            orderBy: "year",
            newDialogOpen: false,
            deleteDialogOpen: false,
            deleteDialogText: null,
            deleteDialogConfirmAction: null
        };
        this.returnDialog = this.returnDialog.bind(this);
    }

    returnDialog() {
        this.setState({ newDialogOpen: false });
    }

    deleteCrop = (crop, name) => {
        this.setState({
            deleteDialogOpen: true,
            deleteDialogConfirmAction: crop,
            deleteDialogText: "Are you sure you wish to permanently delete " + name + "?"
        });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }

        order === "desc"
            ? this.props.cropYears.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
            : this.props.cropYears.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({ order, orderBy });
    };

    formatDate(dateString) {
        if (!dateString) {
            return null;
        }
        const d = new Date(dateString);
        return d.toLocaleDateString();
    }

    render() {
        const {
            classes,
            cropYears,
            fieldId,
            fieldSize,
            ormCropYearCreate,
            ormFieldActivityCreate,
            ormCropYearDelete,
            farm_name,
            farm_id
        } = this.props;

        const { order, orderBy, newDialogOpen, deleteDialogOpen, deleteDialogText, deleteDialogConfirmAction } =
            this.state;

        return (
            <Paper className={classes.root}>
                <Button
                    onClick={() => this.setState({ newDialogOpen: true })}
                    fullWidth
                    className={classes.gutterBottom}
                    variant="raised"
                    color="primary">
                    <AddCircleOutlineIcon />
                    &nbsp;&nbsp;&nbsp;Add New Crop Year
                </Button>
                <Table className={classes.table}>
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={this.handleRequestSort}
                        columnData={columnData}
                    />
                    <TableBody>
                        {cropYears.map(n => {
                            var percentComplete = 0;
                            n["fertilizerCount"] = this.props.fertilizerCounts[n.id];

                            const cropYearCompleted = CROP_YEAR_COMPLETED(n);

                            // Still need to calculate the (approximate if undefined) percent complete to show
                            const m = getValue(n, "extrainfo.missing");
                            const r = getValue(n, "extrainfo.required");
                            if (r) {
                                var missing =
                                    (m[0] || m[0] === 0 ? m[0] : 5) +
                                    (m[1] || m[1] === 0 ? m[1] : 10) +
                                    (m[2] || m[2] === 0 ? m[2] : 3);
                                var required =
                                    (r[0] || r[0] === 0 ? r[0] : 5) +
                                    (r[1] || r[1] === 0 ? r[1] : 10) +
                                    (r[2] || r[2] === 0 ? r[2] : 3);
                                percentComplete = (((required - missing) / required) * 100).toFixed(0);
                            }
                            return (
                                <TableRow hover key={n.id}>
                                    <CustomTableCell>
                                        <Tooltip title="Edit">
                                            <Button
                                                component={Link}
                                                to={"/cropyear/" + n.id}
                                                className={classes.deleteWidth}>
                                                {n.provisional ? (
                                                    <LockIcon color="primary" />
                                                ) : (
                                                    <EditIcon color="primary" />
                                                )}
                                            </Button>
                                        </Tooltip>
                                        <Tooltip
                                            title={
                                                cropYearCompleted
                                                    ? n.metrics_version
                                                        ? "Analytics - Computed"
                                                        : "Analytics - Not Yet Computed"
                                                    : " Analytics (Complete Data Entry to View)"
                                            }>
                                            <div className={classes.analyticsTooltip}>
                                                <Button
                                                    disabled={!cropYearCompleted}
                                                    component={Link}
                                                    to={"/cropyear/" + n.id + "/analysis"}
                                                    className={classes.deleteWidth}>
                                                    {cropYearCompleted && n.metrics_version && (
                                                        <EqualizerIcon style={{ color: "green" }} />
                                                    )}
                                                    {cropYearCompleted && !n.metrics_version && (
                                                        <EqualizerIcon color="primary" />
                                                    )}
                                                    {!cropYearCompleted && <EqualizerIcon />}
                                                </Button>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <div className={classes.analyticsTooltip}>
                                                <Button
                                                    disabled={n.is_final}
                                                    onClick={() => this.deleteCrop(n.id, n.year + " " + n.crop_name)}
                                                    className={classes.deleteWidth}>
                                                    {!n.is_final && <DeleteIcon color="primary" />}
                                                    {n.is_final && <DeleteIcon />}
                                                </Button>
                                            </div>
                                        </Tooltip>
                                    </CustomTableCell>
                                    <CustomTableCell>{n.year}</CustomTableCell>
                                    <CustomTableCell>{n.crop_name}</CustomTableCell>
                                    <CustomTableCell>{n.project ? "Yes" : "No"}</CustomTableCell>
                                    <CustomTableCell>{this.formatDate(n.modify_date)}</CustomTableCell>
                                    <CustomTableCell>
                                        <span className={classes.floatLeft}>{percentComplete}%</span>
                                        <div className={classes.progressBar}>
                                            <div
                                                style={{ width: percentComplete + "%" }}
                                                className={classes.progressDiv}
                                            />
                                        </div>
                                    </CustomTableCell>
                                    <CustomTableCell>{n.is_final ? "Final" : "Provisional"}</CustomTableCell>
                                </TableRow>
                            );
                        })}
                        {cropYears.length < 1 && (
                            <TableRow>
                                <CustomTableCell colSpan={9} className={classes.centerAlign}>
                                    No Crop Years Entered
                                </CustomTableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <AddCropYear
                    fieldId={fieldId}
                    fieldSize={fieldSize}
                    cropYearsField={cropYears}
                    ormCropYearCreate={ormCropYearCreate}
                    ormFieldActivityCreate={ormFieldActivityCreate}
                    returnDialog={this.returnDialog}
                    farm_id={farm_id}
                    farm_name={farm_name}
                    open={newDialogOpen}
                />
                <WarningDialog
                    confirmAction={() => {
                        ormCropYearDelete(deleteDialogConfirmAction);
                        this.setState({ deleteDialogOpen: false });
                    }}
                    cancelAction={() => this.setState({ deleteDialogOpen: false })}
                    open={deleteDialogOpen}
                    title="Delete Crop Year"
                    text={deleteDialogText}
                />
            </Paper>
        );
    }
}

CropYearTable = connect((state, ownProps) => ({
    fertilizerCounts: getFertilizerCounts(state, ownProps)
}))(CropYearTable);

export default withStyles(styles)(CropYearTable);
